import { Injectable } from '@angular/core';
import { EditManagementService } from '@app/services/edit-management.service';
import { HereService } from '@app/services/here.service';
import { RoutesService, assembleRoute } from '@app/services/routes.service';
import { NavInstruction, Stop } from '@app/shared/models/api_interfaces';
import { BestStop, EnrichedRouteDetail, RouteEditOptions } from '@app/shared/models/app_interfaces';
import { bestStop2RecordedStop } from '@app/shared/utils/helpers';
import { LatLng, latLng } from 'leaflet';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewRouteSourceType as NewRouteSourceOptions, RecordedRoute, RecordedStop, TargetPosition } from './rc_interfaces';
import { updateTotalDist, updateTotalTime } from '@app/services/edit.service';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class RouteCreationService {
  routeCreationModeEnabled$ = new BehaviorSubject<boolean>(false);

  newRouteSource$: BehaviorSubject<NewRouteSourceOptions | undefined> = new BehaviorSubject(undefined);

  constructor(
    private hereService: HereService,
    private editManagementService: EditManagementService,
    private routesService: RoutesService
  ) {}

  public enable() {
    this.routeCreationModeEnabled$.next(true);
  }

  public disable() {
    if (this.routesService.stashedRoute) {
      this.routesService.resetDisplayedRoute();
    }

    this.routeCreationModeEnabled$.next(false);
  }

  public getAccountId(): number {
    return this.routesService.accountId$.getValue();
  }

  public createInitialStopData(
    origin: LatLng = latLng(47.61295, -122.29951),
    destination: LatLng = latLng(47.62687, -122.30716)
  ): Observable<Stop[]> {
    return this.hereService.getHereRoute(`${origin.lat},${origin.lng}`, `${destination.lat},${destination.lng}`).pipe(
      map(resp => [
        {
          name: origin['name'] ? origin['name'] : 'Starting Point',
          lat: origin.lat,
          lon: origin.lng,

          traces: [
            {
              source_name: 'Web Created',
              source_type: 'Web Created',
              waypoints: [
                {
                  lat: origin.lat,
                  lon: origin.lng,
                },
              ],
            },
          ],
        },
        {
          name: destination['name'] ? destination['name'] : 'Destination',
          lat: destination.lat,
          lon: destination.lng,
          notes: { best_source_name: 'Web Created', best_source_type: 'Web Created' },
          traces: [
            {
              waypoints: resp.waypoints,
              nav_instructions: resp.nav_instructions,
              source_name: 'Web Created',
              source_type: 'Web Created',
              travel_time: resp.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
              travel_dist: resp.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
            },
          ],
        },
      ])
    );
  }

  public setNewRouteSource(source: NewRouteSourceOptions | undefined) {
    this.newRouteSource$.next(source);
  }

  public saveRoute(): Observable<any> {
    const accountId = this.routesService.accountId$.getValue();
    switch (this.newRouteSource$.value) {
      case 'clone':
        const sourceRouteId = _.get(this.editManagementService.editableRoute, 'id');
        return this.routesService.cloneRoute(sourceRouteId);
      case 'empty':
        const postableRoute: RecordedRoute = NewRoute.from(
          {
            webCreated: this.editManagementService.editableRoute as EnrichedRouteDetail,
          },
          accountId
        ).asRecorded();
        return this.routesService.postRoute(postableRoute);
      case 'split':
        const newRouteFromSplit: RecordedRoute = NewRoute.from(
          {
            splitRoutes: this.editManagementService.editableRoute as EnrichedRouteDetail,
          },
          accountId
        ).asRecorded();
        return this.routesService.postRoute(newRouteFromSplit);
    }
  }

  public updateRouteFromInput(targetPosition: TargetPosition) {
    const routeEditOptions: RouteEditOptions = {
      newPosition: latLng(targetPosition.position.lat, targetPosition.position.lng),
      positionIndex: targetPosition.target === 'origin' ? 0 : this.editManagementService.editableRoute.best.length - 1,
      markerType: targetPosition.target === 'origin' ? 'start' : 'destination',
    };

    return this.editManagementService.initiateEditFlow('updateMarkerPosition', routeEditOptions);
  }
}

export class NewRoute {
  accountId: number;
  best_source_name: string;
  best_source_type: string;

  best: any;

  driverFirstName: string;

  driverGtcId: number;

  driverLastName: string;

  lastupdatedTs: string;

  name: string;

  nStops: number;

  recordedTs: string;

  rejectReason?: string;

  sorted: any;

  status: 'approved' | 'rejected' | 'pending';

  stopData: Stop[];

  stop_data: RecordedStop[];

  totalDist: number;

  totalTime: number;

  constructor(options: { account_id?: number; best?: any; name?: string; stopData?: Stop[] }) {
    this.accountId = options.account_id;
    this.best_source_name = 'Web Created';
    this.best_source_type = 'Web Created';
    this.best = options?.best ? options.best : undefined;
    this.name = options?.name ? options.name : undefined;
    this.lastupdatedTs = Date.now().toString();
    this.recordedTs = Date.now().toString();
    this.stopData = options?.stopData ? options.stopData : undefined;
  }

  static from(
    options: {
      existingRoute?: EnrichedRouteDetail;
      scratch?: { accountId: number; stopData: Stop[]; name?: string };
      webCreated?: EnrichedRouteDetail;
      splitRoutes?: EnrichedRouteDetail;
    },
    account_id?: number
  ): NewRoute {
    // because we will be invoking this method once we already have a displayedRoute$ set, we can pass that route
    // object in, instead of having to go out and make a new request to the OnRoute API
    if (options.existingRoute) {
      return new NewRoute({
        account_id,
        best: options.existingRoute.best,
        name: `${options.existingRoute.name.trim()} - cloned`,
        stopData: options.existingRoute.stopData,
      });
    }
    if (options.scratch) {
      // We need to get the initial structure of a route from the origin and destination only.
      return new NewRoute({
        name: options.scratch?.name ? options.scratch.name : 'Newly Created Route',
        account_id,
        stopData: options.scratch.stopData,
      });
    }
    if (options.webCreated) {
      return new NewRoute({
        account_id,
        best: options.webCreated.best,
        name: `${options.webCreated.name.trim()}`,
        stopData: options.webCreated.stopData,
      });
    }
    if (options.splitRoutes) {
      return new NewRoute({
        account_id,
        best: options.splitRoutes.best,
        name: `${options.splitRoutes.name.trim()}`,
        stopData: options.splitRoutes.stopData,
      });
    }
  }

  /**
   *
   * @returns an object capable of being POST'd to the /routes/ endpoint
   */
  asRecorded(): RecordedRoute {
    const rr = {
      account_id: this.accountId,
      name: this.name.trim(),
      description: 'Web Created',
      driver: {},
      stop_data: this.best.map((s: BestStop, i) => {
        if (i !== 0) {
          return bestStop2RecordedStop(s);
        }
      }),
    };
    rr.stop_data.splice(0, 1, {
      name: 'Starting Point',
      traces: [
        {
          source_name: 'Web Created',
          source_type: 'Web Created',
          waypoints: [
            {
              lat: this.stopData[0].lat,
              lon: this.stopData[0].lon,
            },
          ],
        },
      ],
      lat: 0,
      lon: 0,
    });
    rr.stop_data.forEach((stop, i) => {
      stop['lat'] = this.stopData[i].lat;
      stop['lon'] = this.stopData[i].lon;
    });
    return rr;
  }

  asDisplayed(): EnrichedRouteDetail {
    const route = assembleRoute(this) as EnrichedRouteDetail;
    if (!route.totalDist) {
      updateTotalDist(route);
    }
    if (!route.totalTime) {
      updateTotalTime(route);
    }
    return route;
  }
}
