import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditManagementService } from '@app/services/edit-management.service';
import { take } from 'rxjs/operators';
import { RouteCreationService } from '../../route-creation.service';
import { ImportRoutesUploadDialogComponent } from '@app/features/route-import/components/import-routes-upload-dialog/import-routes-upload-dialog.component';
import { FeatureFlagService } from '@app/services/feature-flag.service';
import _ from 'lodash-es';

@Component({
  selector: 'app-route-creation-dialog',
  templateUrl: './route-creation-dialog.html',
  styleUrls: ['./route-creation-dialog.style.scss'],
})
export class RouteCreationDialogComponent {
  requestInFlight: boolean;
  constructor(
    public editManagementService: EditManagementService,
    public routeCreationService: RouteCreationService,
    public dialogRef: MatDialogRef<RouteCreationDialogComponent>,
    public matDialog: MatDialog,
    public featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  reOpen() {
    this.dialogRef.afterClosed().subscribe(() =>
      this.matDialog.open(RouteCreationDialogComponent, {
        backdropClass: 'backdrop-blur',
        data: { display: 'creation-options' },
      })
    );
    this.dialogRef.close();
  }

  saveRoute() {
    this.data.display = 'processing';
    this.requestInFlight = true;
    this.routeCreationService
      .saveRoute()
      .pipe(take(1))
      .subscribe(() => (this.requestInFlight = false));
  }

  openImportRoutesDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {
      top: '',
      bottom: '50',
      left: '',
      right: '25',
    };
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = '400px';

    this.matDialog.open(ImportRoutesUploadDialogComponent, dialogConfig);
  }
}
// This https://www.concretepage.com/angular-2/angular-2-4-named-router-outlet-popup-example#navigate was very helpful in figuring out
// how to handle navigating to separate named outlets. Turns out you need to close a named outlet unless youre continuing to use it for other
// components
