<div [ngSwitch]="data.routeAction">
  <div *ngSwitchCase="'approve'">
    <mat-dialog-content>
      <h3>Approve this recording?</h3>
      <p>Approving this recording will make the route available to drivers in their navigation app.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="closeDialogModal()"
        data-cy="route-edit-dialog-cancel-button">
        Cancel
      </button>
      <button
        mat-raised-button
        class="dialog-blue-button"
        (click)="initiateRouteEdit('approve')"
        cdkFocusInitial
        data-cy="route-edit-dialog-approve-button">
        Approve
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'reject'">
    <mat-dialog-content id="reject-route-dialog">
      <h3>Reject this recording?</h3>
      <p>
        Once rejected, a driver will need to re-record the route. It will not be available to other drivers in their navigation
        app.
      </p>
      <textarea
        id="reject_route_note_input"
        [(ngModel)]="rejectRouteNotes"
        placeholder="Why are you rejecting this recording?"></textarea>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="closeDialogModal()"
        data-cy="route-edit-dialog-cancel-button">
        Cancel
      </button>
      <button
        mat-raised-button
        class="dialog-red-button"
        (click)="initiateRouteEdit('reject')"
        cdkFocusInitial
        data-cy="route-edit-dialog-reject-button">
        Reject
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'exit'">
    <mat-dialog-content>
      <h3>Are you sure?</h3>
      <p>If you have unsaved changes, and once they are discarded, this cannot be undone.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="initiateRouteEdit('discard')"
        data-cy="route-edit-dialog-discard-button">
        Discard
      </button>
      <button
        mat-raised-button
        class="dialog-blue-button"
        (click)="initiateRouteEdit('save')"
        cdkFocusInitial
        data-cy="route-edit-dialog-save-button">
        Save
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'save'">
    <mat-dialog-content>
      <h3>Save edits</h3>
      <p>Edits you've made to this route will be saved.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="closeDialogModal()"
        data-cy="route-edit-dialog-cancel-button">
        Cancel
      </button>
      <button
        mat-raised-button
        class="dialog-blue-button"
        (click)="initiateRouteEdit('save')"
        cdkFocusInitial
        data-cy="route-edit-dialog-save-button">
        Save
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'delete'">
    <mat-dialog-content>
      <h3>Delete Stop</h3>
      <p *ngIf="!unableToDelete">This stop will be deleted and the route re-calculated.</p>
      <div *ngIf="unableToDelete">Sorry, you're unable to delete a route's only stop.</div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="closeDialogModal()"
        data-cy="route-edit-dialog-cancel-button">
        Cancel
      </button>
      <button
        mat-raised-button
        *ngIf="!unableToDelete"
        class="dialog-red-button"
        (click)="initiateRouteEdit('delete')"
        cdkFocusInitial
        data-cy="route-edit-dialog-delete-button">
        Delete
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'deleteRoutes'">
    <mat-dialog-content>
      <h3>Are you sure you want to delete these route(s)?</h3>
      <p>These routes will be not be retrievable once they are deleted.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" *ngIf="!processingEdit">
      <button
        mat-raised-button
        class="dialog-white-button"
        (click)="closeDialogModal()"
        data-cy="route-edit-dialog-cancel-button">
        Cancel
      </button>
      <button
        mat-raised-button
        class="dialog-red-button"
        (click)="initiateRouteEdit('deleteRoutes')"
        cdkFocusInitial
        data-cy="route-edit-dialog-delete-routes-button">
        Delete
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div *ngIf="processingEdit" id="edit-dialog-spinner-container">
  <mat-spinner color="accent"></mat-spinner>
</div>
