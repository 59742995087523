import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimNavInstructions',
})
export class TrimNavInstructionsPipe implements PipeTransform {
  // This pipe accepts a string, typically representing a multi-sentence text of driving directions.
  // These directions typically include a sentence telling how far to travel. For the specific template syntax
  // use of this pipe, we don't want that 'Go [...]' sentence included (it's extracted and used elsewhere), so
  // so if it's present, we remove it and return the trimmed string.

  transform(navInstruction: string): string {
    return navInstruction.replace(/. Go.*m./g, '.');
  }
}
