import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Basic Layout
import { BasicLayoutComponent } from './basic/basic-layout.component';
import { BasicContentComponent } from './basic/components/content/content.component';
import { BasicHeaderComponent } from './basic/components/header/header.component';
import { BasicFooterComponent } from './basic/components/footer/footer.component';

// Quad Layout
import { QuadService } from './quad/services/quad.service';
import { QuadLayoutComponent } from './quad/quad-layout.component';
import { QuadContentComponent } from './quad/components/content/content.component';
import { DrawerComponent } from './quad/components/drawer/drawer.component';
import { DrawerToggleComponent } from './quad/components/toggle/toggle.component';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    BasicContentComponent,
    BasicHeaderComponent,
    BasicFooterComponent,
    QuadLayoutComponent,
    QuadContentComponent,
    DrawerComponent,
    DrawerToggleComponent,
  ],
  imports: [CommonModule],
  providers: [QuadService],
  exports: [
    BasicLayoutComponent,
    BasicContentComponent,
    BasicHeaderComponent,
    BasicFooterComponent,
    QuadLayoutComponent,
    QuadContentComponent,
    DrawerComponent,
    DrawerToggleComponent,
  ],
})
export class LayoutModule {}
