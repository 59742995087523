import { Component, OnInit } from '@angular/core';
import { DebugService } from '@app/services/debug.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent implements OnInit {
  accountCode: string;
  debugMode: string;

  constructor(private debugService: DebugService) {}

  ngOnInit() {
    // get account code from session storage
    this.accountCode = sessionStorage.getItem('accountCode');

    // get debug mode from session storage
    this.debugMode = sessionStorage.getItem('debugMode');
  }
}
