import { Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnRouteAuthService } from '@app/services/onroute-auth.service';

@Injectable()
export class OnRouteApiInterceptor implements HttpInterceptor {
  constructor(private onRouteAuth: OnRouteAuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /**
     * This interceptor renames any Auth0-attached 'Bearer xxx.yyy.zzz` tokens to `ZONAR xxx.yyy.zzz`, so
     * OnRoute API can differentiate between web & mobile client requests.
     *
     * It also will replace any ZONAR client token with an OnRoute API-retrieved local token, if one is available
     */

    if (request.url.indexOf('onroute-api') > 0 && request.headers.get('Authorization')) {
      const token: string = request.headers.get('Authorization').split('Bearer ')[1];
      let onRouteApiAuthHeader: HttpHeaders;
      if (this.onRouteAuth.localToken) {
        onRouteApiAuthHeader = request.headers.set('Authorization', this.onRouteAuth.localToken);
      } else {
        onRouteApiAuthHeader = request.headers.set('Authorization', `ZONAR ${token}`);
      }
      const onRouteApiRequest: HttpRequest<unknown> = request.clone({
        headers: onRouteApiAuthHeader,
      });
      return next.handle(onRouteApiRequest);
    }

    return next.handle(request);
  }
}
