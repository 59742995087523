import { RecordedStop } from '@app/features/route-creation/rc_interfaces';
import { Waypoint, Stop, Trace } from '@app/shared/models/api_interfaces';
import { BestStop } from '../models/app_interfaces';
import _ from 'lodash-es';

export function waypoint2LatLng(wp: Waypoint) {
  delete Object.assign(wp, { lng: wp['lon'] })['lon'];
  return wp;
}

export function getBestTrace(stop: Stop): Trace {
  const bsn = stop.notes.best_source_name;
  return stop.traces.find(trace => trace.source_name === bsn);
}

export function getDistance(lat1, lon1, lat2, lon2): number {
  /**
   * Returns the distance between two lat/lng points.
   * Adapted from the Haversine formula, which determines the distance between two points on a sphere.
   * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
   * This isn't *perfect* for this application, since it returns distance as the crow flys (and we may want to take driving
   * distance/route into consideration), but it's a good start
   */
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

/**
 * generate groups of 4 random characters
 *
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
export function getUniqueId(parts: number): string {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    // eslint-disable-next-line no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

/**
 *
 * @param stop a Stop type like that from post-processing a route from OnRoute API
 * @returns a RecordedStop type like which is POSTed to OnRoute API
 */
export function bestStop2RecordedStop(stop: BestStop): RecordedStop {
  const s: RecordedStop = {
    name: stop.name,
    lat: stop.lat,
    lon: stop.lon,
    traces: [
      {
        source_name: 'Web Created',
        source_type: 'Web Created',
        waypoints: stop.waypoints,
      },
    ],
  };
  s.traces[0].waypoints.forEach((wp, index, waypointsArray) => {
    waypointsArray[index] = {
      lat: wp.lat,
      lon: wp.lon,
    };
  });

  return s;
}

/**
 * This is a decorator used to avoid any times we may attempt to define existing custom elements
 * It was necessitated by unit testing, but seems to be a decent way to add some robustness to our app
 *
 * @param 'customElements.define'
 * @returns void || false
 */
export function safeCustomElementDecorator(fn) {
  // eslint-disable-next-line func-names
  return function (...args) {
    try {
      return fn.apply(this, args);
    } catch (error) {
      if (error instanceof DOMException && error.message.includes('has already been used with this registry')) {
        return false;
      }
      throw error;
    }
  };
}

export function arrayHasTheSameOrder(array1: string | any[], array2: string | any[]) {
  if (array1.length !== array2.length) {
    return false; // Different lengths means they can't have the same order.
  }

  for (let i = 0; i < array1.length; i++) {
    if (!_.isEqual(array1[i].lat, array2[i].lat)) {
      return false; // The order or property values are different.
    }
  }

  return true; // The arrays have the same order.
}

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}
