import { Component, Host, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { QuadDrawerState } from '../../quad-layout.types';
import { QuadService } from '../../services/quad.service';

// Component's root css class
const defaultCssClass = 'quad-drawer';

/**
 * Drawer
 *
 * Container component that holds page drawer.
 * Sets up a CSS Grid to layout components. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `[${defaultCssClass}]`,
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit, OnDestroy {
  /**
   * CSS classes to attach to the host component.
   * It accepts multiple classes separated by space. */
  @Input() cssClass = '';

  /**
   * Binds the default and passed css classes */
  @HostBinding('class') classNames: string = defaultCssClass;

  private drawerState: QuadDrawerState = QuadDrawerState.Open;

  private drawerStateSub?: Subscription;

  constructor(private state: QuadService) {
    this.handleDrawerStateChange = this.handleDrawerStateChange.bind(this);
    this.setCssClass = this.setCssClass.bind(this);
  }

  ngOnInit(): void {
    // bind css classes to host component
    this.setCssClass();

    this.drawerStateSub = this.state.drawerState$.subscribe(this.handleDrawerStateChange);
  }

  ngOnDestroy(): void {
    this.drawerStateSub?.unsubscribe();
  }

  setCssClass() {
    this.classNames = `${defaultCssClass} ${this.drawerState}${this.cssClass ? ` ${this.cssClass}` : ''}`;
  }

  handleDrawerStateChange(drawerState: QuadDrawerState) {
    this.drawerState = drawerState;

    this.setCssClass();
  }
}
