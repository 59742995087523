import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractNavInstructionDistance',
})
export class ExtractNavInstructionDistancePipe implements PipeTransform {
  // This pipe accepts a string, typically representing a multi-sentence text of driving directions.
  // If there's a meter distance included in the directions, convert it to miles and return it as a string,
  // for use in template expressions.

  transform(navInstruction: string): string {
    const meterMatch = navInstruction.match(/(\d*) m./);
    const kmMatch = navInstruction.match(/([\d.\d]*) km./);

    if (kmMatch) {
      const distance = this.toMiles(parseInt(kmMatch[1], 10) * 1000).toFixed(2);
      return `${distance} miles`;
    }

    if (meterMatch) {
      const distance = this.toMiles(parseInt(meterMatch[1], 10)).toFixed(2);
      return `${distance} miles`;
    }
  }

  toMiles(meters: number): number {
    return meters * 0.000621371192;
  }
}
