export const environment = {
  name: 'dev',
  authEnv: 'development',
  production: false,
  appUrl: 'https://onroute.dev.zonarsystems.net/',
  auth: {
    clientID: 'yO2UmWZom57FVoVJbmAoOWjrruHDdzYR',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: '302a8af4-45b0-4135-a553-79b841dbcddc',
    defaultZonarRole: 'f5ccff5f-658f-41a2-bf55-5f68c26f9a60',
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: '',
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
  onRouteApi: {
    baseUrl: 'https://onroute-api.dev.zonarsystems.net/api',
  },
  hereApi: {
    calcRouteUrl: 'https://fleet.api.here.com/2/calculateroute.json',
    v8RoutesUrl: 'https://router.hereapi.com/v8/routes',
    geocodeUrl: 'https://geocode.search.hereapi.com/v1/geocode',
    revGeocodeUrl: 'https://revgeocode.search.hereapi.com/v1/revgeocode',
    autoCompleteUrl: 'https://autocomplete.search.hereapi.com/v1/autocomplete',
    apiKey: 'I3tUybcRpcAJvWIeBW2tX-uuoYjQeafA6tGbxtzh4KM',
    app_id: 'y6V9HTyWt0fjQNE4rjg1',
    app_code: '5NnwbLdL-vHSypcnbOGFVA',
  },
  i18n: {
    supportedLanguages: ['en', 'en-US'],
    defaultLanguage: 'en-US',
  },
  devCycle: {
    serverKey: 'dvc_client_db194e1e_11e8_47a2_b405_ab42c90c235d_73195a6',
  },
};
