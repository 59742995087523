import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RoutesComponent } from './components/routes/routes.component';
import { RouteDetailsComponent } from './components/route-details/route-details.component';
import { RoutesOverviewComponent } from './components/routes-overview/routes-overview.component';
import { RouteNavigationModule } from '@app/features/route-navigation/route-navigation.module';
import { RouteMapModule } from '@app/features/route-map/route-map.module';
import { RouteEditingModule } from '@app/features/route-editing/route-editing.module';
import { RoutePreviewComponent } from './components/route-preview/route-preview.component';
import { ImportRoutesModule } from '../route-import/import-routes.module';

@NgModule({
  declarations: [RoutesComponent, RouteDetailsComponent, RoutesOverviewComponent, RoutePreviewComponent],
  imports: [CommonModule, SharedModule, RouteNavigationModule, RouteMapModule, RouteEditingModule, ImportRoutesModule],
  exports: [RoutesComponent, RouteDetailsComponent, RoutesOverviewComponent, RoutePreviewComponent],
})
export class RouteDisplayModule {}
