<div class="route-edit-toolbar">
    <div id="route-edit-toolbar-leftside">
        <button class="route-edit-button"
                id="undo-button"
                (click)="editManagementService.undoEdit()"
                data-dd-action-name="click on Undo Button"
                [ngStyle]="{'color':editManagementService?.undosAvailable ? '#49555E': '#adb3b7' }">
            <mat-icon>undo</mat-icon>
        </button>
        <button class="route-edit-button"
                id="redo-button"
                (click)="editManagementService.redoEdit()"
                data-dd-action-name="click on Redo Button"
                [ngStyle]="{'color':editManagementService?.redosAvailable ? '#49555E': '#adb3b7' }">
            <mat-icon>redo</mat-icon>
        </button>
        <div id="add-route-feature-button-container">
            <button class="route-edit-button"
                    id="add-route-feature-button"
                    (click)="toggleFeatureMenu()"
                    data-dd-action-name="click on Add Route Feature Button"
                    [ngStyle]="{'background-color': editManagementService?.features.isFeatureMenuVisible ? '#e2e4e6': 'inherit' }">
                Add Stop or Waypoint
                <mat-icon class="route-edit-toggle"
                          *ngIf="!editManagementService?.features.isFeatureMenuVisible">expand_more
                </mat-icon>
                <mat-icon class="route-edit-toggle"
                          *ngIf="editManagementService?.features.isFeatureMenuVisible">expand_less
                </mat-icon>
            </button>
            <div *ngIf="editManagementService?.features?.isFeatureMenuVisible"
                 id="expanded-add-features-container"
                 (click)="enableGuidanceMenu(); closeFeatureMenu()">
                <div id="add-stop-container">
                    <button (click)="editManagementService?.features.enable('stop')"
                            data-dd-action-name="click on Add Stop">
                        Add stop</button>
                </div>
                <div id="add-uturn-container">
                    <button (click)="editManagementService?.features.enable('uturn')"
                            data-dd-action-name="click on Add Uturn">
                        Add u-turn</button>
                </div>
                <div id="add-waypoint-container">
                    <button (click)="editManagementService?.features?.enable('waypoint')"
                            data-dd-action-name="click on Add Waypoint">
                        Add waypoint</button>
                </div>
            </div>
        </div>

    </div>
    <div id="route-edit-toolbar-rightside">
        <p id="remove-changes-button"
           (click)="removeEditChanges()"
           data-dd-action-name="click on Remove All Changes"
           [ngStyle]="{'color':editManagementService?.editsMade ? '#0075b4' : '#adb3b7' }">Remove all changes</p>
    </div>
</div>