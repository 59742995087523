import { Component, Inject, OnInit } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HereService } from '@app/services/here.service';
import { ImportRoutesService } from '@app/services/import-routes.service';
import { CSVImportError } from '@app/shared/models/app_interfaces';
import { Papa } from 'ngx-papaparse';
import { catchError, filter, firstValueFrom, from, map, mergeMap, of, take, tap, toArray } from 'rxjs';
import { ImportRoutesProgressDialogComponent } from '../import-routes-progress-dialog/import-routes-progress-dialog.component';
import _ from 'lodash-es';
import { ImportRouteResponse } from '@app/shared/models/api_interfaces';

@Component({
  selector: 'app-csv-dialog',
  templateUrl: './import-routes-upload-dialog.component.html',
  styleUrls: ['./import-routes-upload-dialog.component.scss'],
})
export class ImportRoutesUploadDialogComponent implements OnInit {
  // private routes = [];

  isFileHover: boolean = false;

  constructor(
    private hereService: HereService,
    private importRoutesService: ImportRoutesService,
    private papa: Papa,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<ImportRoutesUploadDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.importRoutesService.importStatus = undefined;
    this.dialogRef.afterClosed().subscribe(data => {
      // this allows the user to close the upload dialog without triggering the notification dialog
      if (this.importRoutesService.importStatus !== undefined) {
        this.openImportProgressDialog(data);
      }
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    // clear any previous errors
    this.importRoutesService.importErrors = [];
    this.importRoutesService.importStatus = 'pending';
    this.importRoutesService.importCount$.next(0);
    this.importRoutesService.uploadingComplete = false;

    const processedRoutes = []; // Initialize an array to store final routes
    const fileName = files[0].relativePath;
    const batchTimestamp = Date.now();
    const batchIdentifier = `${fileName}_${batchTimestamp}`;
    // ngx-file-drop multiple is broken: https://github.com/georgipeltekov/ngx-file-drop/issues/157
    // ...and currently we're only supporting single file upload
    if (files.length > 1) {
      this.importRoutesService.importErrors.push(
        new CSVImportError(undefined, 'critical', 'Single File Required', undefined, undefined)
      );
      this.importRoutesService.importStatus = 'failure';
      this.dialogRef.close({ fileName });
      return;
    }
    const uploadedFile = files[0];
    // Is it a file?
    if (!uploadedFile.fileEntry.isFile) {
      this.importRoutesService.importErrors.push(
        new CSVImportError(fileName, 'critical', 'CSV File Required ', undefined, undefined)
      );
      this.importRoutesService.importStatus = 'failure';
      this.dialogRef.close({ fileName });
      return;
    }

    const fileEntry = uploadedFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      // Is it a file we want?
      if (!file.name.endsWith('.csv')) {
        this.importRoutesService.importErrors.push(
          new CSVImportError(fileName, 'critical', 'CSV Format Required', undefined, undefined)
        );
        this.importRoutesService.importStatus = 'failure';
        this.dialogRef.close({ fileName });
        return;
      }
      // Is it *really* a file we want?
      if (file.size == 0) {
        this.importRoutesService.importErrors.push(
          new CSVImportError(fileName, 'critical', 'File cannot be empty', undefined, undefined)
        );
        this.importRoutesService.importStatus = 'failure';
        this.dialogRef.close({ fileName });
        return;
      }
      // Parse the file
      this.papa.parse(file, {
        delimiter: ',',
        complete: async (results: { data: string[][] }) => {
          // Create a mapping from column names to indices
          // ...because strict column order is not required
          const columnMapping: { [key: string]: number } = {};
          results.data[0].forEach((columnName, index) => {
            columnMapping[columnName.trim().toLowerCase()] = index;
          });

          const expectedColumns = ['route', 'latitude', 'longitude', 'stop address', 'is stop', 'stop name', 'sequence'];

          // Check if all the expected column names exist
          for (const columnName of expectedColumns) {
            if (!(columnName in columnMapping)) {
              this.importRoutesService.importErrors.push(
                new CSVImportError(undefined, 'critical', 'Missing: Column', undefined, `${_.capitalize(columnName)}`)
              );
            }
          }
          // Don't bother continuing
          if (this.importRoutesService.importErrors.filter((e: CSVImportError) => e.type == 'critical').length > 0) {
            this.importRoutesService.importStatus = 'failure';
            this.dialogRef.close({ fileName });
            return;
          }
          // Check if the required columns always have data
          const requiredColumns = ['route', 'is stop', 'stop name', 'sequence'];
          this.validateSomeRequiredFields(results, columnMapping, requiredColumns);

          const routes = {};

          // Iterate over the rows after the header row
          for (let i = 1; i < results.data.length; i++) {
            // Skip empty rows
            if (results.data[i].join('').trim() === '') continue;

            const rowRouteName = results.data[i][columnMapping['route']];
            const row: any[] = results.data[i];

            if (!routes[rowRouteName]) {
              // If the route doesn't exist in the routes object, create a new array for it
              routes[rowRouteName] = [];
            }
            // we're going to add a final entry to each row's array that will hold its original index
            // from within the csv file. This will help provide more specific error reporting
            row.push(i + 1);
            // Push row data to the corresponding route array
            routes[rowRouteName].push(row);
          }

          // Process each route
          for (const routeName in routes) {
            const currentRoute = { name: routeName, stop_data: [] };
            let waypoints = [];

            // Sort the stops within the route by sequence
            routes[routeName].sort((a, b) => {
              const sequenceA = parseInt(a[columnMapping['sequence']]);
              const sequenceB = parseInt(b[columnMapping['sequence']]);
              return sequenceA - sequenceB;
            });

            // The last sequence row of a route must always be a stop (not a waypoint)
            if (routes[routeName].at(-1)[columnMapping['is stop']].toLowerCase() != ('true' || 'yes')) {
              this.importRoutesService.importErrors.push(
                new CSVImportError(
                  routeName,
                  'warn',
                  `Last sequence must be a stop`,
                  routes[routeName].at(-1).at(-1),
                  'Is Stop'
                )
              );
            }

            // Handle Sequence Errors
            const routeSequences = routes[routeName].map(r => parseInt(r[columnMapping['sequence']]));

            // Missing Sequence
            const missingSequences = _.difference(_.range(1, routeSequences.length - 1), routeSequences);
            if (missingSequences.length > 0) {
              this.importRoutesService.importErrors.push(
                new CSVImportError(routeName, 'warn', `Missing: #${[...missingSequences]}`, undefined, 'Sequence')
              );
            }
            // Duplicate Sequences
            const duplicateSequences = routeSequences.filter((item, index) => routeSequences.indexOf(item) !== index);

            if (duplicateSequences.length > 0) {
              const dupeRowIndices = routes[routeName]
                .filter(row => duplicateSequences.includes(parseInt(row[columnMapping['sequence']])))
                .map(dupeRow => dupeRow.at(-1));
              this.importRoutesService.importErrors.push(
                new CSVImportError(routeName, 'warn', 'Duplicate Values', dupeRowIndices.join(', '), 'Sequence')
              );
            }

            // Handle instances of ambiguous or missing position
            for (let i = 0; i < routes[routeName].length; i++) {
              const row = routes[routeName][i];

              const latitude = parseFloat(row[columnMapping['latitude']]);
              const longitude = parseFloat(row[columnMapping['longitude']]);

              let lat: number;
              let lon: number;
              const addressPresent = row[columnMapping['stop address']].length > 0;

              if (isNaN(latitude) || isNaN(longitude)) {
                if (!addressPresent) {
                  this.importRoutesService.importErrors.push(
                    new CSVImportError(
                      routeName,
                      'warn',
                      `Stop Position Required`,
                      row.at(-1),
                      'Stop Address or Latitude/Longitude'
                    )
                  );
                  continue; // no reason to continue down towards a more specific validation
                }
                const address = row[columnMapping['stop address']];

                const geoCoordinates = await this.getGeoCoordinates(address);

                if (geoCoordinates == undefined) {
                  this.importRoutesService.importErrors.push(
                    new CSVImportError(routeName, 'warn', `Valid Address Required`, row.at(-1), 'Stop Address')
                  );
                  continue; // at this point all of our validation steps are complete, so we can break out of this loop (before the route continues to be processed)
                }
                lat = geoCoordinates.lat;
                lon = geoCoordinates.lng;
              } else {
                lat = latitude;
                lon = longitude;
              }

              const isStop = row[columnMapping['is stop']];

              if ((isStop !== undefined && isStop.toLowerCase() === 'true') || i === 0) {
                // Create a stop object
                const stop = {
                  name: i === 0 ? 'Starting Point' : row[columnMapping['stop name']],
                  lat,
                  lon,
                  traces: [{ waypoints }],
                };

                // Add stop to the route
                currentRoute.stop_data.push(stop);

                // Clear waypoints for the next stop
                waypoints = [];
              } else {
                // Add waypoint to waypoints array
                waypoints.push({ lat, lon });
              }
            }

            // Add the last waypoints to the last stop if any
            if (waypoints.length > 0 && currentRoute.stop_data.length > 0) {
              const lastStopIndex = currentRoute.stop_data.length - 1;
              currentRoute.stop_data[lastStopIndex].traces = waypoints;
            }

            processedRoutes.push(currentRoute);
          }

          const badRoutes = this.importRoutesService.importErrors.map(err => _.get(err, 'routeName'));
          const goodRoutes = processedRoutes.filter(route => !badRoutes.includes(route.name));

          this.importRoutesService.lastImportJobCount = processedRoutes.length;

          this.importRoutesService.importStatus = 'success';
          if (badRoutes.length > 0) {
            this.importRoutesService.importStatus = 'partial';
          }
          if (goodRoutes.length == 0) {
            this.importRoutesService.importStatus = 'failure';
          }

          // Import the successfully validated good routes
          if (goodRoutes.length > 0) {
            this.importRoutes(goodRoutes)
              .pipe(take(1))
              .subscribe((ids: number[]) => {
                if (ids.length == 0) {
                  // All routes failed to upload
                  this.importRoutesService.importStatus = 'failure';
                  return;
                }
                if (ids.length != goodRoutes.length) {
                  this.importRoutesService.importStatus = 'partial';
                }

                this.importRoutesService.importedBatches[batchIdentifier] = ids;
                this.importRoutesService.routeIDsWaitingForProcessingResult =
                  this.importRoutesService.routeIDsWaitingForProcessingResult.concat(ids);
                this.importRoutesService.openWebsocket();
                this.importRoutesService.monitorRoutes(ids);
                return;
              });
          }
          this.dialogRef.close({ fileName, batchIdentifier });
        },
      });
    });
  }

  validateSomeRequiredFields(
    results: { data: string[][] },
    columnMapping: { [key: string]: number },
    requiredColumns: string[]
  ) {
    for (let i = 1; i < results.data.length - 1; i++) {
      for (const columnName of requiredColumns) {
        const routeName =
          results.data[i][columnMapping['route']].length > 0 ? results.data[i][columnMapping['route']] : undefined;
        if (!results.data[i][columnMapping[columnName]]) {
          this.importRoutesService.importErrors.push(
            new CSVImportError(routeName, 'warn', `Missing`, i + 1, _.capitalize(columnName))
          );
        }
        if (columnName.toLowerCase() == 'is stop') {
          const acceptableIsStopValues = ['true', 'false', 'yes', 'no'];
          if (!acceptableIsStopValues.includes(results.data[i][columnMapping['is stop']].toLowerCase())) {
            this.importRoutesService.importErrors.push(
              new CSVImportError(routeName, 'warn', `Invalid Value`, i + 1, 'Is Stop')
            );
          }
        }
      }
    }
  }

  private importRoutes(routes: any[]) {
    // Set the number of requests you want to fly concurrently
    // Maybe make this variable based on environ?

    const concurrency = 100;

    return from(routes).pipe(
      mergeMap(
        route =>
          this.importRoutesService.importRoute(route).pipe(
            catchError(errordRouteName => {
              this.importRoutesService.importErrors.push(
                new CSVImportError(errordRouteName, 'warn', `Unable to Upload`, undefined, undefined)
              );
              return of(errordRouteName);
            }),
            map((resp: ImportRouteResponse) => _.get(resp, 'onroute.id')),
            tap(() => this.importRoutesService.importCount$.next(this.importRoutesService.importCount$.value + 1))
          ),
        concurrency
      ),
      filter(Boolean),
      toArray(),
      tap(() => (this.importRoutesService.uploadingComplete = true))
    );
  }

  private async getGeoCoordinates(address: string) {
    try {
      // Call the HereService to get geo-coordinates
      const geoCoordinates = await firstValueFrom(this.hereService.getHereGeocode(address)); // Assuming you want to convert the Observable to a Promise
      return geoCoordinates.lat == 0 || geoCoordinates.lng == 0 ? undefined : geoCoordinates;
    } catch (error) {
      console.error('Error fetching geo-coordinates from HereService:', error);
      // Handle error scenarios
      return undefined;
    }
  }

  fileOver(file) {
    this.isFileHover = true;
  }

  fileLeave(file) {
    this.isFileHover = false;
  }

  openImportProgressDialog(data: object) {
    // Open this when the CSV import drop/upload happens
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {
      top: '',
      bottom: '50',
      left: '',
      right: '25',
    };
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = '400px';
    dialogConfig.data = data;
    dialogConfig.panelClass = 'import-routes-progress-dialog-dialog';

    this.matDialog.open(ImportRoutesProgressDialogComponent, dialogConfig);
  }
}
