import { Component, HostBinding } from '@angular/core';

import { QuadService } from './services/quad.service';

// Component's root css class
const defaultCssClass = 'quad-layout';

/**
 * Content
 *
 * Container component that holds page content.
 * Sets up a CSS Grid to layout components. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[quad-layout]',
  templateUrl: './quad-layout.component.html',
  styleUrls: ['./quad-layout.component.scss'],
})
export class QuadLayoutComponent {
  /**
   * Binds the default and passed css classes */
  @HostBinding('class.quad-layout') className: string = defaultCssClass;

  constructor(private state: QuadService) {}
}
