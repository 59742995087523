import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutesService } from '@app/services/routes.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RouteCreationDialogComponent } from '../../../route-creation/components/route-creation-dialog/route-creation-dialog.component';
import { UtilitiesService } from '@app/services/utilities.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-routes-overview',
  templateUrl: './routes-overview.component.html',
  styleUrls: ['./routes-overview.component.scss'],
})
export class RoutesOverviewComponent implements OnInit, OnDestroy {
  public pendingRoutesCount$: Observable<number>;
  public routesLoading: boolean;
  private unsubscribe$: Subject<any> = new Subject();

  constructor(public matDialog: MatDialog, public routesService: RoutesService, public utilitiesService: UtilitiesService) {}

  ngOnInit(): void {
    this.pendingRoutesCount$ = this.routesService.getPendingRoutesCount().pipe(shareReplay());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public viewPendingRoutes() {
    this.routesService.statusFilter$.next('pending');
  }

  openRouteCreationDialog(): void {
    this.matDialog.open(RouteCreationDialogComponent, {
      backdropClass: 'backdrop-blur',
      data: { display: 'creation-options' },
    });
  }
}
