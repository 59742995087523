import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouteMapComponent } from './route-map.component';
import { NavInstructionMarkerComponent } from './components/nav-instruction-marker/nav-instruction-marker.component';
import { StopMarkerPopupComponent } from './components/stop-marker-popup/stop-marker-popup.component';
import { WaypointMarkerPopupComponent } from './components/waypoint-marker-popup/waypoint-marker-popup.component';
import { RouteFeatureToolbarComponent } from './components/route-feature-toolbar/route-feature-toolbar.component';

@NgModule({
  declarations: [
    RouteMapComponent,
    NavInstructionMarkerComponent,
    StopMarkerPopupComponent,
    WaypointMarkerPopupComponent,
    RouteFeatureToolbarComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [RouteMapComponent],
})
export class RouteMapModule {}
