<main basic-layout *ngIf="viewHeader; else loading">
  <header basic-header>
    <zui-header logoTitle="OnRoute" useEmailAsName="true" [hasShowChangeCompany]="false"> </zui-header>
  </header>
  <article basic-content *ngIf="viewContent; else loading">
    <app-subheader></app-subheader>

    <router-outlet name="section-quad-container"></router-outlet>
    <section quad-layout [ngClass]="drawerStateClass" *ngIf="!routeSplittingMode">
      <section quad-content [cssGridArea]="'q1'" class="mat-elevation-z4">
        <app-routes-overview></app-routes-overview>
      </section>
      <section quad-content [cssGridArea]="'q2'" class="mat-elevation-z4">
        <router-outlet name="section-quad-q2"></router-outlet>
      </section>
      <aside quad-drawer class="mat-elevation-z4">
        <app-routes></app-routes>
      </aside>
      <section quad-content [cssGridArea]="'q3'" class="mat-elevation-z4">
        <app-route-preview></app-route-preview>
      </section>
    </section>
  </article>
  <footer basic-footer>
    <zui-footer></zui-footer>
  </footer>
</main>

<ng-template #loading>
  <div class="mat-spinner-container">
    <mat-spinner color="accent"></mat-spinner>
  </div>
</ng-template>
