/** eslint-disable max-len */

import { Injectable } from '@angular/core';
import { RoutesService } from './routes.service';
import { Stop, Trace } from '@app/shared/models/api_interfaces';
import {
  latLng,
  LatLngBounds,
  latLngBounds,
  Polyline,
  MarkerOptions,
  Marker,
  polyline,
  PolylineOptions,
  PopupOptions,
  FeatureGroup,
  featureGroup,
  LatLng,
  LatLngExpression,
} from 'leaflet';
import { map, pluck, filter, tap, distinctUntilKeyChanged, switchMap } from 'rxjs/operators';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { MarkerPopupService } from './marker-popup.service';
import {
  BestStop,
  DebugMarker,
  DebugTrace,
  EditedWaypoint,
  EnrichedRouteDetail,
  NumberedStopMarkerIcon,
  StopMarker,
  WaypointMarker,
} from '@app/shared/models/app_interfaces';
import { Map } from 'leaflet';
import { DebugService } from './debug.service';
import { getBestTrace, getDistance } from '@app/shared/utils/helpers';
import { SelectionManagementService } from './selection-management.service';
import _ from 'lodash-es';
import { destinationMarkerOptions, startMarkerOptions, stopMarkerOptions } from '@app/features/route-map/route-map.component';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  mapReference: Map;
  stopMarkerReferences: Marker[];
  stopSegmentPolylineReferences: Polyline[];
  primaryRouteFeatureGroup: FeatureGroup;
  secondaryRoutesFeatureGroup: FeatureGroup;
  mapFitBounds$ = new BehaviorSubject({});

  constructor(
    private routesService: RoutesService,
    private markerPopupService: MarkerPopupService,
    private debugService: DebugService,
    private selectionManagementService: SelectionManagementService
  ) {
    this.routesService.displayedRoute$
      .pipe(
        filter(route => route !== undefined),
        // This operator is being put in due to a user request that the map bounds only update on new route selection
        distinctUntilKeyChanged('stopData'),
        filter(route => Array.isArray(route['stopData'])),
        pluck('best'),
        map((stops: BestStop[]) => this._getMapBoundsFromBestSourceStops(stops))
      )
      .subscribe(bounds => this.setMapFitBounds(bounds));
  }

  public setMapFitBounds(bounds: LatLngBounds) {
    this.mapFitBounds$.next(bounds);
  }

  public getAltSourceNames$(): Observable<string[]> {
    // These are the names of the different GPS-originating sources. Typically it will be the tablet and the TCU
    return this.routesService.displayedRoute$.pipe(
      filter(route => route !== undefined && route.hasOwnProperty('sorted')),
      map(route => route['sorted'].map(_ => _[0]))
    );
  }

  public createMarkers$(
    source: 'stops' | 'waypoint' | 'debug-waypoints' | 'addedWaypoint',
    options: {
      markerOptions: {
        origin?: MarkerOptions;
        stop?: MarkerOptions;
        destination?: MarkerOptions;
        debug?: MarkerOptions;
        waypoint?: MarkerOptions;
      };
      popupOptions?: PopupOptions;
      matchedWaypoints?: { waypointEdits: EditedWaypoint[] };
    },
    sourceName: string = ''
  ): Observable<StopMarker[] | WaypointMarker[]> {
    if (source === 'stops') {
      // Return an observable array of map markers for async drawing (on new route load)
      return this.routesService.displayedRoute$.pipe(
        switchMap(route =>
          iif(
            () => route === undefined,
            of(undefined),
            of(route).pipe(
              pluck('stopData'),
              map((stops: Stop[]) => {
                const stopMarkerPopupEl = this.markerPopupService.getStopMarkerPopupElement();
                return stops.map((stop: Stop, stopIndex, { length }) => {
                  const mapMarkerLatLon: [number, number] = [stop.lat, stop.lon];
                  const stopMarkerIcon = new NumberedStopMarkerIcon(
                    options.markerOptions.stop.icon.options.className,
                    stopIndex
                  );

                  let mapMarkerOptions: MarkerOptions;

                  if (stopIndex === 0) {
                    mapMarkerOptions = options.markerOptions.origin;
                    mapMarkerOptions.alt = `Origin Marker`;
                  } else if (stopIndex + 1 === length) {
                    mapMarkerOptions = options.markerOptions.destination;
                    mapMarkerOptions.alt = `Destination Marker`;
                  } else {
                    mapMarkerOptions = {
                      icon: stopMarkerIcon,
                    };
                  }

                  const stopMarker: StopMarker = new Marker(mapMarkerLatLon, mapMarkerOptions);

                  // bind the angular element to the marker popup content
                  stopMarker.bindPopup(stopMarkerPopupEl, options.popupOptions);
                  stopMarker.stopIndex = stopIndex;
                  return stopMarker;
                });
              }),
              tap((stopMarkers: StopMarker[]) => {
                // Keep references to the stop markers so other components can use them
                this.stopMarkerReferences = stopMarkers;
              })
            )
          )
        )
      );
    }
    if (source === 'addedWaypoint') {
      // We want to retain the waypoint positions that have been manually added or edited by a user. Sometimes the
      // HERE response won't contain the actual waypoint, but one close to the grid, so we need to find and create a marker for those
      return this.routesService.displayedRoute$.pipe(
        pluck('stopData'),
        map((stops: Stop[]) => {
          const closestMatchedWaypoints = [];

          options.matchedWaypoints.waypointEdits.forEach(targetWp => {
            const segmentWaypoints = getBestTrace(stops[targetWp.associatedSegmentIndex]).waypoints;
            const closestMatch = segmentWaypoints.reduce((prev, curr) =>
              getDistance(targetWp.wpPosition.lat, targetWp.wpPosition.lng, prev.lat, prev.lon) <
              getDistance(targetWp.wpPosition.lat, targetWp.wpPosition.lng, curr.lat, curr.lon)
                ? prev
                : curr
            );
            const wp: {
              lat: number;
              lon: number;
              stopIndex?: number;
              originalWaypointIndex?: number;
              stopName?: string;
              uniqueId?: string;
            } = (({ lat, lon }) => ({
              lat,
              lon,
            }))(closestMatch);
            wp.stopIndex = targetWp.associatedSegmentIndex;
            wp.stopName = stops[targetWp.associatedSegmentIndex - 1].name;
            wp.uniqueId = targetWp.uniqueId;
            closestMatchedWaypoints.push(wp);
          });

          return closestMatchedWaypoints.map(wp => {
            const waypointOptions: MarkerOptions = options.markerOptions.waypoint;
            waypointOptions.alt = 'Waypoint Marker';
            const waypointMarker = new Marker([wp.lat, wp.lon], waypointOptions) as WaypointMarker;
            const waypointMarkerPopupEl = this.markerPopupService.getWaypointMarkerPopupElement();

            waypointMarker.bindPopup(waypointMarkerPopupEl);

            waypointMarker.uniqueId = wp.uniqueId;
            waypointMarker.stopIndex = wp.stopIndex;
            waypointMarker.on('click', event => {
              this.selectionManagementService.setSelectedWaypointIdentifiers({
                uniqueId: event.target.uniqueId,
                associatedStopIndex: event.target.stopIndex,
              });
            });
            return waypointMarker;
          });
        })
      );
    }

    // For Debugging
    if (source === 'debug-waypoints') {
      return this.routesService.displayedRoute$.pipe(
        filter(route => route !== undefined),
        pluck('sorted'),
        map((traces: DebugTrace[]) => {
          const debugMarkers: DebugMarker[] = [];
          traces.forEach(trace => {
            trace[1].forEach((stop: Trace, i: number) => {
              if (trace[0] === sourceName) {
                if (stop.waypoints.length > 0) {
                  stop.waypoints.forEach(wp => {
                    const mm = new Marker([wp.lat, wp.lon], options.markerOptions.debug).bindPopup(`${wp.lat}, ${wp.lon}`);
                    mm['stopIndex'] = stop.associatedStopIndex; // PUP-2528: do not subtract 1 from index as it causes debug waypoints to mismatch
                    debugMarkers.push(mm);
                  });
                }
              }
            });
          });

          this.debugService.debugReferences[sourceName] = debugMarkers;

          return debugMarkers;
        })
      );
    }
  }

  public createRoutePolylines$(options: PolylineOptions): Observable<Polyline[] | undefined> {
    // Return an observable array of polyline objects for async drawing (on new route load)
    return this.routesService.displayedRoute$.pipe(
      switchMap(route =>
        iif(
          () => route === undefined,
          of(undefined),
          of(route).pipe(
            pluck('best'),
            map((stops: any[]) => {
              // console.log('-polyline stops:', stops);
              const stopsWaypoints = stops
                .filter(stop => stop.waypoints && stop.waypoints.length > 0)
                .map(stop => stop.waypoints);
              // console.log('-polyline Stops Waypoints', stopsWaypoints);
              return stopsWaypoints.map((latLngs, i) => {
                const p = polyline(latLngs, options);
                p['stopSegmentIndex'] = i;
                return p;
              });
            }),
            tap(p => {
              this.stopSegmentPolylineReferences = p;
              this.primaryRouteFeatureGroup = featureGroup(p);
            })
          )
        )
      )
    );
  }

  public toggleStopMarkerPopUp(stopIndex: number) {
    if (this.stopMarkerReferences[stopIndex].isPopupOpen()) {
      this.mapReference.closePopup();
    } else {
      this.stopMarkerReferences[stopIndex].openPopup();
    }
  }

  public removeSecondaryRoutesFromMap() {
    this.secondaryRoutesFeatureGroup.removeFrom(this.mapReference);
    this.setMapFitBounds(this.primaryRouteFeatureGroup.getBounds());
  }

  /**
   *
   * @param routes
   * @returns the internal leaflet id of the added routes feature group
   */
  public addSecondaryRoutesToMap(routes: EnrichedRouteDetail[]) {
    this.secondaryRoutesFeatureGroup = new FeatureGroup();
    routes.forEach(route => {
      const rpOptions: PolylineOptions = {
        opacity: 0.7,
        weight: 6,
        color: '#5B6770',
      };
      const rp = polyline(
        route.best
          .map(s => s.waypoints as unknown as LatLng[])
          .flat()
          .filter(wp => wp != undefined),
        rpOptions
      );
      // If we want individual stop markers for each added route, we can comment back in this code
      route.stopData.forEach((stop, i, { length }) => {
        const ll: LatLngExpression = [stop.lat, stop.lon];
        let options: MarkerOptions;
        if (i === 0) {
          options = { ...startMarkerOptions };
        } else if (i == length - 1) {
          options = { ...destinationMarkerOptions };
        } else {
          const stopMarkerIcon = new NumberedStopMarkerIcon(stopMarkerOptions.icon.options.className, i, '#5B6770', '1');
          options = {
            icon: stopMarkerIcon,
          };
        }
        options.opacity = 0.7;

        const sm: StopMarker = new Marker(ll, options);
        sm.stopIndex = i;

        this.secondaryRoutesFeatureGroup.addLayer(sm);
      });

      this.secondaryRoutesFeatureGroup.addLayer(rp);
    });
    this.secondaryRoutesFeatureGroup.addTo(this.mapReference);
    this.setMapFitBounds(this.secondaryRoutesFeatureGroup.getBounds().extend(this.primaryRouteFeatureGroup.getBounds()));
  }

  private _getMapBoundsFromBestSourceStops(stops: BestStop[]): LatLngBounds {
    // console.log('-bounding map to:', stops);
    return stops.length > 1
      ? latLngBounds(
          stops
            .map(s => s.waypoints)
            .filter(e => e !== undefined)
            .flat()
            .map(wp => latLng(wp.lat, wp.lon))
        )
      : latLngBounds(stops.map(s => latLng(s.lat, s.lon)));
  }
}
