import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RoutesService } from '@app/services/routes.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Route } from '@app/shared/models/api_interfaces';
import { UtilitiesService } from '@app/services/utilities.service';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { EditManagementService } from '@app/services/edit-management.service';
import { MatDialog } from '@angular/material/dialog';
import { RouteEditDialogComponent } from '@app/features/route-editing/route-edit-dialog/route-edit-dialog.component';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss'],
})
export class RoutesComponent implements OnInit, AfterViewInit, OnDestroy {
  filterByIds: number[];
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.routesDataSource) {
      this.routesDataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.routesDataSource) {
      this.routesDataSource.sort = value;
    }
  }

  routesDataSource: MatTableDataSource<Route>;
  displayedRoute: Route;
  displayedColumns: string[];
  inputFilterValue: string;
  selectStatusFilterValue: string;
  routesLoading: boolean;
  protected unsubscribe$: Subject<any> = new Subject();

  constructor(
    public routesService: RoutesService,
    public utilitiesService: UtilitiesService,
    public editManagementService: EditManagementService,
    public dialog: MatDialog
  ) {
    this.utilitiesService.routesLoading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => (this.routesLoading = loading));
  }

  ngOnInit(): void {
    this.routesDataSource = new MatTableDataSource<Route>();

    // Define how the route table data gets filtered
    this.routesDataSource.filterPredicate = this.customFilterPredicate;

    // Each displayed columns array item must match a key of the dataSource array's objects, or MatSort won't work
    this.displayedColumns = ['name'];

    // Initialize the input filter with an empty string
    this.inputFilterValue = '';

    // Subscribe to the recordedRoutes$ observable, so that the datasource table can be repopulated every time new routes are retrieved
    this.routesService.recordedRoutes$.subscribe((routes: Route[]) => {
      // We want to sort the initially displayed route list by 'lastUpdatedTs', then 'recordedTs', then finally 'name'
      routes.sort((a, b) => {
        if (a.lastupdatedTs === b.lastupdatedTs) {
          // recordedTs is only important if lastUpdatedTs is the same
          if (a.recordedTs === b.recordedTs) {
            // name is only important if recordedTs is the same
            return a.name.localeCompare(b.name);
          }
          // sort recordedTs descending
          return b.recordedTs.localeCompare(a.recordedTs);
        }
        // sort updatedTs descending
        return b.lastupdatedTs.localeCompare(a.lastupdatedTs);
      });

      // Set an initial value of selected route, so the route details component populates without the user first needing
      // to make a route selection.
      // We don't want this to happen with every routes list refresh (in order to preserve the user's currently selected route)
      if ((this.routesDataSource.data.length === 0 && routes.length > 0) || this.displayedRoute?.id !== routes[0]?.id) {
        this.displayedRoute = routes[0];
        this.routesService.setDisplayedRouteById(this.displayedRoute['id']);
      }

      // Once sorted, and an initial route detail request in-flight, set value of the DataSource, which will populate the route summary list
      this.routesDataSource.data = routes;
    });

    // Initial request to get all the routes.
    this.routesService.getRoutes();
    // Routes status filter is maintained in routesService
    this.routesService.statusFilter$.subscribe((status: string) => {
      this.selectStatusFilterValue = status;
      this.applyRouteFilter();
    });
    // Routes may be filtered by list of ids as well
    this.routesService.routeFilterByIds$.subscribe((ids: number[]) => {
      this.filterByIds = ids;
      this.applyRouteFilter();
    });
    // On initialization, we want 'all' routes to be displayed
    this.routesService.setStatusFilter('all');
  }

  ngAfterViewInit(): void {
    this.routesService.displayedRoute$
      .pipe(
        map(route => {
          if (route === undefined) {
            this.displayedRoute = undefined;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onSelect(route: Route): void {
    // We don't want to be able to select a new route if we already have the same one selected
    if (route !== this.displayedRoute) {
      this.displayedRoute = route;
      this.routesService.setDisplayedRouteById(this.displayedRoute['id']);
    }
  }

  applyRouteFilter() {
    let idsStr = undefined;
    // This is kind of annoying, but necessary in order to trigger a change to the filter
    if (this.filterByIds != undefined) {
      idsStr = 'filterIdsTrue';
    }
    if (this.selectStatusFilterValue === 'all') {
      this.routesDataSource.filter =
        idsStr != undefined
          ? `${idsStr} ${this.inputFilterValue}`.trim().toLowerCase()
          : this.inputFilterValue.trim().toLowerCase();
    } else {
      this.routesDataSource.filter =
        idsStr != undefined
          ? `${idsStr} ${this.inputFilterValue} status:${this.selectStatusFilterValue}`.trim().toLowerCase()
          : `${this.inputFilterValue} status:${this.selectStatusFilterValue}`.trim().toLowerCase();
    }
    if (this.routesDataSource.paginator) {
      this.routesDataSource.paginator.firstPage();
    }
  }

  customFilterPredicate = (route: Route, filter: string) => {
    // This is basically because the filter needs to have been changed in order to trigger the predicate action.
    // The Angular Material Table Filte Predicate also requires filter:string

    // We'll remove our 'flag' filter to retain any input filters, then do the route id filtering using the actual routeIds array
    filter = filter.replace('filteridstrue', '').trim();
    // We want to be able to filter only on route name, driver, and date/time.
    const routeName: string = route.name;
    const routeDriver: string = JSON.stringify(route.driverFirstName) + JSON.stringify(route.driverLastName);
    const routeDate: string = new Date(route.recordedTs).toString();
    const routeFilterStr: string = `${routeName} ${routeDriver} ${routeDate}`.toLowerCase();
    // If routes of 'all' status are expected to be displayed, then we don't want to filter against the routes' status
    if (this.selectStatusFilterValue === 'all') {
      if (this.filterByIds !== undefined) {
        return routeFilterStr.indexOf(filter) !== -1 && this.filterByIds.includes(route.id);
      }
      return routeFilterStr.indexOf(filter) !== -1;
    } else {
      // but if the Status filter has been set by the user, then we want to respect both that selection, and the user-input filter string
      const routeStatusStr: string = `status:${route.status}`.toLowerCase();
      const routeMultiFilter = filter.split('status:');
      if (this.filterByIds !== undefined) {
        return (
          routeFilterStr.indexOf(routeMultiFilter[0].trim()) !== -1 &&
          routeStatusStr.indexOf(routeMultiFilter[1].trim()) !== -1 &&
          this.filterByIds.includes(route.id)
        );
      }
      return (
        routeFilterStr.indexOf(routeMultiFilter[0].trim()) !== -1 && routeStatusStr.indexOf(routeMultiFilter[1].trim()) !== -1
      );
    }
  };

  enableBulkEdit() {
    if (this.editManagementService.bulkEditMode$.getValue() == false) {
      // this allows for user to repeatedly click button without clearing selections...if they want to
      this.editManagementService.selectedBulkEditRoutes = [];
      this.editManagementService.openBulkEditMode();
    }
  }

  disableBulkEdit() {
    this.editManagementService.closeBulkEditMode();
  }

  onBulkEditSelection(route: Route) {
    if (this.editManagementService.selectedBulkEditRoutes.includes(route)) {
      this.editManagementService.selectedBulkEditRoutes = this.editManagementService.selectedBulkEditRoutes.filter(
        item => item !== route
      );
    } else {
      this.editManagementService.selectedBulkEditRoutes.push(route);
    }
  }

  openDialog() {
    const options = {
      backdropClass: 'backdrop-blur',
      data: { routeAction: 'deleteRoutes' },
      width: '25vw',
    };

    this.dialog.open(RouteEditDialogComponent, options);
    this.dialog.afterAllClosed
      .pipe(
        tap(() => {
          this.disableBulkEdit();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
