import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import _ from 'lodash-es';
import { ImportRoutesErrorsDialogComponent } from '../import-routes-errors-dialog/import-routes-errors-dialog.component';
import { ImportRoutesService } from '@app/services/import-routes.service';
import { RoutesService } from '@app/services/routes.service';

@Component({
  selector: 'app-import-routes-snackbar',
  templateUrl: './import-routes-snackbar.component.html',
  styleUrls: ['./import-routes-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImportRoutesSnackbarComponent implements OnInit {
  fileName: string;
  status: string;
  importedBatchIds: number[];
  message = {
    success: 'Your file was imported successfully.',
    partial: 'Your file was imported successfully with errors.',
    failure: 'Your file was imported unsuccessfully.',
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<ImportRoutesSnackbarComponent>,
    public matDialog: MatDialog,
    public importRoutesService: ImportRoutesService,
    private routesService: RoutesService
  ) {}

  ngOnInit(): void {
    this.fileName = _.get(this.data, 'fileName');
    this.importedBatchIds = _.get(this.importRoutesService.importedBatches, this.data.batchIdentifier);
    this.status = this.importRoutesService.importStatus;
  }
  dismissSnackbar(): void {
    this.snackBarRef.dismiss();
    this.routesService.filterRouteListByIds(undefined);
  }
  viewErrors(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '66%';
    dialogConfig.height = '44%';
    dialogConfig.data = this.fileName;
    dialogConfig.autoFocus = 'first-header';
    this.matDialog.open(ImportRoutesErrorsDialogComponent, dialogConfig);
  }
  viewRoutes(): void {
    this.routesService.filterRouteListByIds(this.importedBatchIds);
  }
}
