import { Injectable } from '@angular/core';
import {
  AddStopOptions,
  AddWaypointOptions,
  EnrichedRouteDetail,
  DeleteStopOptions,
  EditedWaypoint,
  EditType,
  RouteEditOptions,
  UpdateMarkerPositionOptions,
} from '@app/shared/models/app_interfaces';
import { LatLng } from 'leaflet';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { RoutesService } from './routes.service';
import { EditService } from './edit.service';
import { filter, first, map, mergeMap, tap, throttleTime } from 'rxjs/operators';
import { getUniqueId } from '@app/shared/utils/helpers';
import { DataDogService } from './datadog.service';

import { RouteFeatureManager } from '@app/shared/utils/RouteFeatureManager';
import { WaypointManager } from '@app/shared/utils/WaypointManager';
import { MapService } from './map.service';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EditManagementService {
  public editMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public bulkEditMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public componentChangedSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  componentChanged$ = this.componentChangedSource.asObservable();
  // public routeCreationMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public addFeatureMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public editsMade: boolean;
  public redosAvailable: boolean;
  public undosAvailable: boolean;
  public selectedBulkEditRoutes: Array<any>;

  // This is a copy of the currently selected route. When a user enters 'editMode', and make edits,
  // they will be editing this copy. When they're satisfied with their edits, this object will be provided
  // in the body of the PUT request to OnRoute API.
  public editableRoute: EnrichedRouteDetail;

  public waypointEdits$ = new BehaviorSubject<EditedWaypoint[]>([]);

  // Dedicated Object for manipulating all the add feature actions & settings
  public readonly features = new RouteFeatureManager(this.addFeatureMode$);

  private segmentWaypoints: WaypointManager;

  private editsSubject$ = new Subject<boolean>();
  private _undoableEdits = [];
  private _redoableEdits = [];

  constructor(
    private routesService: RoutesService,
    private editService: EditService,
    private dataDogService: DataDogService,
    public mapService: MapService
  ) {
    // Dedicated Object for manipulating all the waypoint edits during a user edit session
    this.segmentWaypoints = new WaypointManager(this.waypointEdits$, this.mapService);
    this.routesService.displayedRoute$.pipe(filter(route => route !== undefined)).subscribe((route: EnrichedRouteDetail) => {
      // We want to discard any stored edits if our selected route changes
      if (route?.stopData !== this.editableRoute?.stopData) {
        this.features.reset();
        this.segmentWaypoints.reset();
        this._discardEdits();
        // We don't want to use a reference to the displayedRoute$ value, we want a new object
        this.editableRoute = _.cloneDeep(route);
      }
    });

    this.editsSubject$
      .pipe(
        throttleTime(1200),
        map(() => this._addEdit(this.editableRoute))
      )
      .subscribe();
  }

  public initiateEditFlow(editType: EditType, routeEditOptions?: RouteEditOptions): Observable<any> {
    switch (editType) {
      case 'save':
        this.dataDogService.addAction('edit', { type: 'saveRouteEdits' });
        return this.editService.saveRouteEdits(this.editableRoute);
      case 'approve':
        this.dataDogService.addAction('edit', { type: 'approveRoute' });
        return this.editService.updateRouteStatus('approved', this.editableRoute);
      case 'reject':
        this.dataDogService.addAction('edit', { type: 'rejectRoute' });
        return this.editService.updateRouteStatus('rejected', this.editableRoute);
      case 'discard':
        this.dataDogService.addAction('edit', { type: 'discardRouteChanges' });
        return this.discardRouteChanges();
      case 'delete':
        // make sure this is a number!
        const positionIndex: number = parseInt(`${routeEditOptions.positionIndex}`, 10);

        this.dataDogService.addAction('edit', { type: 'deleteStop' });
        this.storeState();

        /**
         * Index 0 is the starting position of the route.
         * Currently we're not dealing with that deletion.
         * So this is a quick workaround to deal with the
         * Starting Point marker */
        if (positionIndex === 0 || undefined) {
          return of(true);
        }

        /**
         * We also don't want to allow the user
         * to delete all the stops (currently) */
        if (this.editableRoute.best.length === 2) {
          return of('You cannot delete the only stop.');
        }

        // update the position index of associated waypoint edits
        this.segmentWaypoints.moveWaypointsToSegment(positionIndex + 1, positionIndex);

        // if the deleted stop is the last of the route, we will discard the associated segment waypoints
        let waypointsToBeRemoved = [];
        if (this.editableRoute.best.length === positionIndex + 1) {
          waypointsToBeRemoved = this.segmentWaypoints.removeAllFromSegment(positionIndex);
        }

        // prepare endpoint payload
        const deleteStopOptions: DeleteStopOptions = {
          positionIndex,
          intermediateWaypoints: waypointsToBeRemoved.map(wp => wp.wpPosition),
        };

        return this.editService
          .deleteStop(this.editableRoute, deleteStopOptions)
          .pipe(tap(route => this.routesService.displayedRoute$.next(route)));

      case 'addWaypoint':
        const addWaypointOptions = {} as AddWaypointOptions;

        this.dataDogService.addAction('edit', { type: 'addWaypoint' });
        this.storeState();

        this.segmentWaypoints.lastSegmentModified = routeEditOptions.associatedSegmentIndex;

        // add waypoint
        let segmentWaypoints = [];
        if (routeEditOptions.wpPosition) {
          const newWaypoint = new EditedWaypoint(
            routeEditOptions.associatedSegmentIndex,
            routeEditOptions.wpPosition,
            getUniqueId(2)
          );

          /**
           * Adding a waypoint to a segment
           * returns all waypoints from that segment */
          segmentWaypoints = this.segmentWaypoints.storeWaypoint(newWaypoint);
        }

        // if there are any other waypoints that have been added to this segment, we will include them.
        addWaypointOptions['intermediateWaypoints'] = segmentWaypoints.map(wp => wp.wpPosition);
        addWaypointOptions['avoidUturns'] = !this.features.isUturEnforced;
        addWaypointOptions['associatedSegmentIndex'] = this.features.selectedSegmentIndex$.value + 1;

        return this.editService
          .addNewWaypoint(this.editableRoute, addWaypointOptions)
          .pipe(map(route => this.routesService.displayedRoute$.next(route)));
      case 'addStop':
        const addStopOptions: AddStopOptions = {
          newStopPosition: routeEditOptions.newStopPosition,
          positionIndex: parseInt(`${routeEditOptions.positionIndex}`, 10),
          avoidUturns: !this.features.isUturEnforced,
          finalStop: _.get(routeEditOptions, 'finalStop', false),
        };

        addStopOptions.name = _.get(routeEditOptions, 'name', undefined);

        this.dataDogService.addAction('edit', { type: 'addStop' });
        this.storeState();

        // if we've previously added any intentional waypoints during this edit session, include them in the new stop calc
        if (
          this.segmentWaypoints.waypoints.length &&
          this.segmentWaypoints.waypoints[0].associatedSegmentIndex === parseInt(`${routeEditOptions.positionIndex}`, 10)
        ) {
          addStopOptions['intermediateWaypoints'] = this.segmentWaypoints.waypoints.map(wp => wp.wpPosition);
        }

        return this.editService
          .addNewStop(this.editableRoute, addStopOptions)
          .pipe(tap(route => this.routesService.displayedRoute$.next(route)));

      case 'updateMarkerPosition':
        this.dataDogService.addAction('edit', { type: 'dragMarkerPosition' });
        this.storeState();

        if (routeEditOptions.markerType === 'waypoint') {
          this.segmentWaypoints.lastSegmentModified = parseInt(`${routeEditOptions.positionIndex}`, 10);
          this.segmentWaypoints.updateWaypoint(
            routeEditOptions.associatedSegmentIndex,
            routeEditOptions.waypointId,
            routeEditOptions.newPosition
          );
        }

        const precedingIntermediateWaypoints = this.segmentWaypoints
          .getSegmentWaypoints(parseInt(`${routeEditOptions.positionIndex}`, 10))
          .map(wp => wp.wpPosition);
        const followingIntermediateWaypoints = this.segmentWaypoints
          .getSegmentWaypoints(parseInt(`${routeEditOptions.positionIndex}`, 10) + 1)
          .map(wp => wp.wpPosition);
        const markerPositionOptions: UpdateMarkerPositionOptions = {
          newPosition: routeEditOptions.newPosition,
          positionIndex: parseInt(`${routeEditOptions.positionIndex}`, 10),
          markerType: routeEditOptions.markerType,
          precedingIntermediateWaypoints,
          followingIntermediateWaypoints,
        };

        return this.editService
          .updateMarkerPosition(this.editableRoute, markerPositionOptions)
          .pipe(map(route => this.routesService.displayedRoute$.next(route)));

      case 'moveStopInStops':
        this.dataDogService.addAction('edit', { type: 'moveStopInStops' });
        this.storeState();
        const movedStopPos = new LatLng(
          _.get(this.editableRoute, `best[${routeEditOptions.fromIndex}].lat`),
          _.get(this.editableRoute, `best[${routeEditOptions.fromIndex}].lon`)
        );
        const movedStopName = _.get(this.editableRoute, `best[${routeEditOptions.fromIndex}].name`);

        const finalStop = this.editableRoute.best.length - 1 === routeEditOptions.toIndex;
        return this.initiateEditFlow('delete', { positionIndex: routeEditOptions.fromIndex }).pipe(
          mergeMap(() =>
            this.initiateEditFlow('addStop', {
              newStopPosition: movedStopPos,
              positionIndex: routeEditOptions.toIndex,
              name: movedStopName,
              finalStop,
            })
          )
        );

      case 'deleteRoutes':
        return forkJoin(
          this.selectedBulkEditRoutes.map(r => {
            return this.editService.deleteRoute(_.get(r, 'id'));
          })
        ).pipe(
          tap(() => {
            // remove routes from FE list before refreshing route list (for user experience)
            this.routesService.removeRoutesFromRouteList(this.selectedBulkEditRoutes);
            this.routesService.refreshRoutes();
          })
        );

      default:
        break;
    }
  }

  public storeState() {
    // this is the public method that enqueues the request to add an edit. It basically allows to control
    // throttling vai the private editsSubject$
    this.editsSubject$.next(true);
    // Whenever we store state we want to update the availability flags
    this.setUndoRedoAvailabilityFlags();
  }

  public discardRouteChanges(): Observable<boolean> {
    this.features.reset();
    this._discardEdits();
    this.setUndoRedoAvailabilityFlags();

    // Any changes made to editableRoute are replaced by a new object copied from the non-edited displayedRoute value
    if (this.editableRoute.hasOwnProperty('id')) {
      this.routesService.refreshRoutes(this.editableRoute.id);
    }
    return of(true);
  }

  public deleteWaypoint(uniqueId: string) {
    this.dataDogService.addAction('edit', { type: 'deleteWaypoint' });

    // we need the position index of the waypoint to be removed
    const addWpOptions: AddWaypointOptions = {
      associatedSegmentIndex: this.segmentWaypoints.waypoints.find(wp => wp.uniqueId === uniqueId).associatedSegmentIndex,
    };

    this.segmentWaypoints.removeWaypoint(addWpOptions.associatedSegmentIndex, uniqueId);
    this.editService
      .addNewWaypoint(this.editableRoute, addWpOptions)
      .pipe(
        first(),
        tap(() => this.closeAddFeatureMode())
      )
      .subscribe();
  }

  public openEditMode(): void {
    this.dataDogService.addAction('edit', { type: 'openEditMode' });
    this.editMode$.next(true);
  }

  public openBulkEditMode(): void {
    this.dataDogService.addAction('edit', { type: 'openBulkEditMode' });

    this.bulkEditMode$.next(true);
  }

  public openAddFeatureMode(): void {
    this.addFeatureMode$.next(true);
  }

  public closeEditMode(): void {
    this.editMode$.next(false);
    this._discardEdits();
  }
  public closeBulkEditMode(): void {
    this.dataDogService.addAction('edit', { type: 'closeBulkEditMode' });
    this.bulkEditMode$.next(false);
  }
  public closeAddFeatureMode(): void {
    this.addFeatureMode$.next(false);
    this.features.reset();
  }

  public undoEdit(): void {
    this.dataDogService.addAction('edit', { type: 'undoEdit' });
    if (this._undoableEdits.length > 0) {
      this._redoableEdits.push(_.cloneDeep(this.editableRoute));
      this.editableRoute = this._undoableEdits.pop();
      this.routesService.displayedRoute$.next(this.editableRoute);
    }
    this.setUndoRedoAvailabilityFlags();
  }

  public redoEdit(): void {
    this.dataDogService.addAction('edit', { type: 'redoEdit' });
    if (this._redoableEdits.length > 0) {
      this.storeState();
      this.editableRoute = this._redoableEdits.pop();
      this.routesService.displayedRoute$.next(this.editableRoute);
    }
    this.setUndoRedoAvailabilityFlags();
  }

  public getWaypointEdits$(): BehaviorSubject<any[]> {
    return this.waypointEdits$;
  }

  private _discardEdits() {
    this._undoableEdits = [];
    this._redoableEdits = [];
    this.segmentWaypoints.reset();
  }

  private _addEdit(editedRoute: EnrichedRouteDetail): void {
    this._undoableEdits.push(_.cloneDeep(editedRoute));
  }

  private setUndoRedoAvailabilityFlags() {
    this.undosAvailable = this._undoableEdits.length > 0;
    this.redosAvailable = this._redoableEdits.length > 0;
    this.editsMade = this.undosAvailable || this.redosAvailable;
  }
}
