/*
  Interfaces of objects returned by OnRoute API
*/

export interface Route {
  accountId: number;
  busGtcId: number | null;
  description: string | null;
  driverFirstName: string;
  driverLastName: string;
  driverGtcId: number;
  id: number;
  lastupdatedTs: string | null;
  name: string;
  recordedTs: string | null;
  rejectReason?: string | null;
  status: 'approved' | 'rejected' | 'pending';
}

export interface RouteDetail {
  accountId: number;
  driverFirstName: string;
  driverGtcId: number;
  driverLastName: string;
  id: number;
  lastupdatedTs: string;
  nStops: number;
  name: string;
  recordedTs: string;
  rejectReason?: string;
  status: 'approved' | 'rejected' | 'pending';
  stopData: Stop[];
  totalDist: number;
  totalTime: number;
}

export interface Stop {
  lat: number;
  lon: number;
  name: string;
  notes?: Note;
  traces?: Trace[];
}

export interface Note {
  best_source_name: string;
  best_source_type: string;
  traces?: TraceNote;
}

export interface TraceNote {
  'SM-T397U'?: TraceStats;
  TCU?: TraceStats;
  'Manually Edited Stop': any;
}

export interface TraceStats {
  avg_gap: number;
  max_gap: number;
  median_gap: number;
  min_gap: number;
  stdev_gap: number;
}
export interface Trace {
  name?: string;
  nav_instructions?: NavInstruction[];
  source_name: string;
  source_type: string;
  travel_dist?: number;
  travel_time?: number;
  waypoints: Waypoint[];
  associatedStopIndex?: number;
}

export interface Waypoint {
  lat: number;
  lon: number;
  orig_lat?: number;
  orig_lon?: number;
}

export interface NavInstruction {
  id: string;
  length: number;
  position: {
    lat: number;
    lon: number;
  };
  travelTime: number;
  instruction: string;
}

export interface OnRouteApiTokenResponse {
  email: string;
  emailVerified: string;
  familyName: string;
  givenName: string;
  auth0UserId: string;
  username: string;
  nickname: string;
  picture: string;
  userId: string;
  accounts: string;
  isZonar: boolean;
  localToken: string;
}
export interface OnRouteApiDecodedToken {
  account: string;
  email: string;
  exp: number;
  is_zonar: boolean;
  sub: string;
}
export interface ImportRouteResponse {
  message: string;
  onroute: { id: number; name: string; status: string };
  status: string;
}
