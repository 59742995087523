import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHumanFriendly',
})
export class SecondsToHumanFriendlyPipe implements PipeTransform {
  transform(totalSeconds: number): string {
    const msg = [];
    const hrs = Math.floor(totalSeconds / 3600);
    const secs = Math.floor(totalSeconds % 60);
    let mins = Math.floor((totalSeconds % 3600) / 60);

    if (secs > 29) {
      mins++;
    }

    if (hrs) {
      msg.push(`${hrs} `, hrs > 1 ? 'hours ' : 'hour ');
    }
    if (mins) {
      msg.push(`${mins} `, mins > 1 ? 'minutes ' : 'minute ');
    }
    if (mins < 1) {
      msg.push('Less than a minute');
    }
    return msg.join('');
  }
}
