import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
  escapePressedEvent: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  routesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  routeLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {}
}
