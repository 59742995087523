<div *ngIf="!routesLoading; else loading" class="routes-container">
  <div class="routes-filter-container">
    <mat-form-field appearance="outline" data-dd-action-name="click on Routes by Input Filter">
      <mat-label>Route, date, or driver</mat-label>
      <input matInput (keyup)="applyRouteFilter()" ngDefaultControl [(ngModel)]="inputFilterValue" placeholder="Ex. ium" />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" data-cy="routes-by-status" data-dd-action-name="click on Route by Status Filter">
      <mat-label>View by</mat-label>
      <mat-select
        (selectionChange)="applyRouteFilter()"
        ngDefaultControl
        [(ngModel)]="selectStatusFilterValue"
        data-cy="routes-view-by-status">
        <mat-option value="all" data-dd-action-name="click on View By All">All</mat-option>
        <mat-option value="rejected" data-dd-action-name="click on View By Rejected">Rejected</mat-option>
        <mat-option value="approved" data-dd-action-name="click on View By Approved">Approved</mat-option>
        <mat-option value="pending" data-dd-action-name="click on View By Pending">Pending</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button (click)="enableBulkEdit()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="routes-table-container hide-scrollbar">
    <table class="routes-table" mat-table [dataSource]="routesDataSource" matSort data-cy="routes-table">
      <ng-container matColumnDef="name">
        <th class="routes-header" mat-header-cell *matHeaderCellDef mat-sort-header>Routes</th>
        <td class="route-row" mat-cell *matCellDef="let route">
          <span class="route-row-route-name" data-cy="route-row-route-name">
            <mat-checkbox
              *ngIf="editManagementService.bulkEditMode$ | async"
              (click)="$event.stopPropagation()"
              (change)="onBulkEditSelection(route)"
              value="route"></mat-checkbox>
            {{ route.name | ellipse : 35 }}
          </span>
          <span class="route-row-status-icon" [ngSwitch]="route.status" data-cy="route-row-route-status">
            <mat-icon *ngSwitchCase="'approved'" [ngStyle]="{ color: 'rgb(63,126,47)' }">check_circle</mat-icon>
            <mat-icon *ngSwitchCase="'rejected'" [ngStyle]="{ color: 'rgb(174,55,41)' }">cancel</mat-icon>
            <mat-icon *ngSwitchCase="'pending'" [ngStyle]="{ color: 'rgba(36, 100, 168, 1)' }">pending</mat-icon>
            <mat-icon *ngSwitchDefault [ngStyle]="{ color: 'rgba(36, 100, 168, 1)' }">pending</mat-icon>
          </span>
        </td>
      </ng-container>

      <tr class="routes-header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelect(row)"
        [class.selected]="row.id === displayedRoute?.id"
        data-cy="routes-table-route-row"></tr>
    </table>
  </div>
  <mat-paginator
    class="routes-table-paginator"
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons
    data-cy="routes-paginator">
  </mat-paginator>
  <div *ngIf="editManagementService.bulkEditMode$ | async" id="bulk-edit-container">
    <button mat-flat-button color="white" (click)="disableBulkEdit()">Cancel</button>
    <button
      mat-flat-button
      color="warn"
      (click)="openDialog()"
      [disabled]="editManagementService.selectedBulkEditRoutes.length == 0">
      Delete
    </button>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
