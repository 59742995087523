import { Injectable } from '@angular/core';
import { initializeDevCycle } from '@devcycle/js-client-sdk';
import { environment } from '@environments/environment';
import { User } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  devcycleClient: any;
  hereV8Enabled: boolean;
  importRoutesEnabled: boolean;
  constructor() {}

  async init(user: User) {
    try {
      // Call initialize with the client key and a user object
      // await on the features to be loaded from our servers

      this.devcycleClient = await initializeDevCycle(environment.devCycle.serverKey, {
        user_id: user.email,
      }).onClientInitialized();

      this.useDevCycleVariable();
    } catch (ex) {
      console.log(`Error initializing DevCycle: ${ex}`);
    }
  }

  useDevCycleVariable() {
    if (!this.devcycleClient) return;

    // Fetch variable values using the identifier key coupled with a default value
    // The default value can be of type string, boolean, number, or object
    this.hereV8Enabled = this.devcycleClient.variableValue('onroute-web-here-v-8', false);

    if (this.hereV8Enabled) {
      // Put feature code here, or launch feature from here
      console.log('Variable "onroute-web-here-v-8" is enabled');
    } else {
      // Put feature code here, or launch feature from here
      console.log('Variable "onroute-web-here-v-8" is disabled');
    }

    // Import routes
    this.importRoutesEnabled = this.devcycleClient.variableValue('onroute-web-import-routes', false);
    console.log(`Variable 'onroute-web-import-routes' is ${this.importRoutesEnabled ? 'enabled' : 'disabled'}`);
  }
}
