import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-zonar-user-dialog',
  templateUrl: './zonar-user-dialog.component.html',
  styleUrls: ['./zonar-user-dialog.component.scss'],
})
export class ZonarUserDialogComponent {
  constructor(public dialogRef: MatDialogRef<ZonarUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public closeDialog(): void {
    this.dialogRef.close(this.data);
  }
}
