import { Component, HostBinding, Input, OnInit } from '@angular/core';

// Component's root css class
const defaultCssClass = 'basic-content';

/**
 * Content
 *
 * Container component that holds page content. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `[${defaultCssClass}]`,
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class BasicContentComponent implements OnInit {
  /**
   * CSS classes to attach to the host component.
   * It accepts multiple classes separated by space. */
  @Input() cssClass = '';

  /**
   * Binds the default and passed css classes */
  @HostBinding('class') classNames: string = defaultCssClass;

  constructor() {
    this.setCssClass = this.setCssClass.bind(this);
  }

  ngOnInit(): void {
    // bind css classes to host component
    this.setCssClass();
  }

  setCssClass() {
    this.classNames = `${defaultCssClass}${this.cssClass ? ` ${this.cssClass}` : ''}`;
  }
}
