import { Injectable } from '@angular/core';
import { DivIcon, Marker, Polyline } from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { NgElement, WithProperties } from '@angular/elements';
import { NavInstructionMarkerComponent } from '@app/features/route-map/components/nav-instruction-marker/nav-instruction-marker.component';
import { NavInstruction } from '@app/shared/models/api_interfaces';

@Injectable({
  providedIn: 'root',
})
export class SelectionManagementService {
  selectedNavInstructionIndex: number;
  selectedWaypointIdentifiers$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  selectionPath$: BehaviorSubject<Polyline | undefined> = new BehaviorSubject(undefined);

  navigationMarker$: BehaviorSubject<Marker | undefined> = new BehaviorSubject(undefined);
  selectedStopIndex$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  stopMarkerPopupIndex$: BehaviorSubject<number> = new BehaviorSubject(undefined);

  constructor() {}

  public setSelectedStopIndex(i: number | undefined): void {
    this.selectedStopIndex$.next(i);
  }

  public setMarkerPopupIndex(i: number | undefined): void {
    this.stopMarkerPopupIndex$.next(i);
  }

  public clearSelectedNavInstructionIndex(): void {
    this.selectedNavInstructionIndex = undefined;
  }

  public setSelectedWaypointIdentifiers(wpInfo: { uniqueId: string; associatedStopIndex: number }) {
    this.selectedWaypointIdentifiers$.next(wpInfo);
  }

  public setInstructionMarker(navInstruction: NavInstruction): void {
    const navMarkerEl = this._createNavInstructionElement(navInstruction.instruction);
    const mm = new Marker(
      { lat: navInstruction.position.lat, lng: navInstruction.position.lon },
      {
        icon: new DivIcon({
          html: navMarkerEl,
          className: 'selected-nav-instruction-marker',
          iconSize: null,
          iconAnchor: [30, 60],
        }),
      }
    );
    this.navigationMarker$.next(mm);
  }
  public removeInstructionMarker(): void {
    this.navigationMarker$.next(undefined);
  }

  public toggleSelectedStopSegment(stopIndex: number): void {
    this.selectedStopIndex$.value === stopIndex ? this.setSelectedStopIndex(undefined) : this.setSelectedStopIndex(stopIndex);
  }

  public toggleSelectedNavInstructionSegment(navInstructionIndex: number, navInstruction: NavInstruction) {
    if (this.selectedNavInstructionIndex === navInstructionIndex) {
      this.clearSelectedNavInstructionIndex();
      this.removeInstructionMarker();
    } else {
      this.selectedNavInstructionIndex = navInstructionIndex;
      this.setInstructionMarker(navInstruction);
    }
  }

  private _createNavInstructionElement(instruction: string): NgElement {
    // This custom element grants us additional customization options for our nav instruction div icon marker
    const el: NgElement & WithProperties<NavInstructionMarkerComponent> = document.createElement(
      'nav-instruction-marker-element'
    ) as any;
    el.instruction = instruction;
    return el;
  }
}
