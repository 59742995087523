import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment as env } from '@environments/environment';
import { OnRouteAuthService } from './onroute-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private auth: OnRouteAuthService) {}

  private makeUrl(path: string, secure: boolean = true): string {
    if (secure) {
      return `${env.onRouteApi.baseUrl.replace('https://', 'wss://')}${path}`;
    } else {
      return `${env.onRouteApi.baseUrl.replace('https://', 'ws://')}${path}`;
    }
  }

  createSocket(path: string, secure: boolean = true): WebSocketSubject<unknown> {
    return webSocket(this.makeUrl(path, secure));
  }

  authenticate(socket$: WebSocketSubject<unknown>, token: string = null) {
    socket$.next({
      request_type: 'auth',
      data: {
        Authorization: token != null ? token : this.auth.localToken,
      },
    });
  }
}
