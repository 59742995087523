// Angular
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Pattern Library
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUiNotificationsModule } from '@zonar-ui/notifications';
import { ZonarUiI18nModule, TranslateModule } from '@zonar-ui/i18n';
import { ZonarUIAnalyticsTokens, ZonarUiAnalyticsService } from '@zonar-ui/analytics';

// Third Party
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxPendoModule } from 'ngx-pendo';

// App
import { AppRoutingModule } from '@app/app-routing.module';
import { OnRouteApiInterceptor } from '@app/core/interceptors/onroute-api.interceptor';

// Environmental
import { environment } from '@environments/environment';
import { permissions } from '@environments/shared';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ZonarUICoreModule,
    ZonarUiNotificationsModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          'https://onroute-api.dev.zonarsystems.net/api/*',
          'https://onroute-api.staging.zonarsystems.net/api/*',
          'https://onroute-api.production.zonarsystems.net/api/*',
          'https://api-dev.zonarsystems.net/*',
          'https://api-qa.zonarsystems.net/*',
          'https://api.zonarsystems.net/*',
        ],
      },
    }),
    TranslateModule.forRoot(),
    NgxPendoModule.forRoot({
      pendoApiKey: environment.pendo.key,
      pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
    }),
  ],
  exports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ZonarUICoreModule,
    ZonarUiNotificationsModule,
    ZonarUiI18nModule,
  ],
  providers: [
    // Analytics settings
    ZonarUiAnalyticsService,
    { provide: ZonarUIAnalyticsTokens.ID, useValue: environment.googleTagManager.id },
    { provide: 'appUrl', useValue: environment.appUrl },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'audience', useValue: environment.auth.audience },
    { provide: 'clientID', useValue: environment.auth.clientID },
    { provide: 'domain', useValue: environment.auth.domain },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'defaultPermPrefix', useValue: 'route_manager' },
    { provide: 'logoutReturnToUrl', useValue: environment.appUrl },
    { provide: 'onRouteApiBaseUrl', useValue: environment.onRouteApi.baseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OnRouteApiInterceptor, multi: true },
    { provide: 'supportedLanguages', useValue: environment.i18n.supportedLanguages },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
