import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutesService } from '@app/services/routes.service';
import { EnrichedRouteDetail } from '@app/shared/models/app_interfaces';
import { DebugService } from '@app/services/debug.service';
import { EditManagementService } from '@app/services/edit-management.service';
import { UtilitiesService } from '@app/services/utilities.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-route-details',
  templateUrl: './route-details.component.html',
  styleUrls: ['./route-details.component.scss'],
})
export class RouteDetailsComponent implements OnInit, OnDestroy {
  public route: EnrichedRouteDetail;
  public routeLoading: boolean;
  public editMode: boolean;
  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    public routesService: RoutesService,
    public editManagementService: EditManagementService,
    public debugService: DebugService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.routesService.displayedRoute$
      .pipe(
        filter(r => r !== undefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((route: EnrichedRouteDetail) => (this.route = route));
    this.utilitiesService.routeLoading$.pipe(takeUntil(this.unsubscribe$)).subscribe(loading => (this.routeLoading = loading));
    this.editManagementService.editMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(editMode => (this.editMode = editMode));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
