import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  OnChanges,
} from '@angular/core';
import { RoutesService } from '@app/services/routes.service';
import { RouteDetail, Stop } from '@app/shared/models/api_interfaces';
import { EnrichedRouteDetail, BestStop } from '@app/shared/models/app_interfaces';
import { MapService } from '@app/services/map.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationIconPipe } from '@app/shared/pipes/navigation-icon.pipe';
import { EditManagementService } from '@app/services/edit-management.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectionManagementService } from '@app/services/selection-management.service';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import _ from 'lodash';
import { EditService } from '@app/services/edit.service';

@Component({
  selector: 'app-route-navigation',
  templateUrl: './route-navigation.component.html',
  styleUrls: ['./route-navigation.component.scss'],
  providers: [NavigationIconPipe],
})
export class RouteNavigationComponent implements OnInit, OnDestroy {
  @Output() toggleDrawerEvent = new EventEmitter<MatDrawerToggleResult>();
  @ViewChild('singleStopPanel', { static: false }) firstStopPanel: MatExpansionPanel;

  public stops: BestStop[];
  public route: EnrichedRouteDetail;
  public instructions: string[][];
  public panelOpenState: boolean[];
  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    private routesService: RoutesService,
    public mapService: MapService,
    public editManagementService: EditManagementService,
    public editService: EditService,
    public selectionManagementService: SelectionManagementService
  ) {}

  ngOnInit(): void {
    this.routesService.displayedRoute$
      .pipe(
        filter(
          (route: EnrichedRouteDetail) =>
            route !== undefined || route?.stopData.length > 1 || route?.best[1]?.nav_instructions !== undefined
        ),
        map(route => {
          this.route = route;

          this.stops = route['best'];

          this.instructions = this.extractNavInstructions(route);
          this.panelOpenState = Array(this.stops.length).fill(false);
          // this adds a nice transition for the user, showing them that stops are expandable
          setTimeout(() => {
            this.firstStopPanel?.open();
            this.panelOpenState[1] = true;
          }, 300);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    // adding 1 to the index because our list of stops includes the Starting Position (which should not be movable from this method)
    this.editManagementService
      .initiateEditFlow('moveStopInStops', {
        fromIndex: event.previousIndex + 1,
        toIndex: event.currentIndex + 1,
      })
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public togglePanel(panel: MatExpansionPanel, index: number) {
    // This is used primarily as a way to prevent the entire panel header from triggering panel expansion (which was annoying)
    // We're using this to keep panel expansion events contained to toggle icon clicks
    panel.toggle();
    this.panelOpenState[index] = !this.panelOpenState[index];
  }

  public toggleDrawer(action: MatDrawerToggleResult) {
    this.toggleDrawerEvent.emit(action);
  }

  public handleNavItemClick(stopIndex: number, navInstructionIndex?: number) {
    // We're only going to be doing this if we're not in the process of Adding a Route Feature
    // There are other click actions that are defined in the `highlight-segment.directive`
    if (this.editManagementService.addFeatureMode$.value === false) {
      // Remove any nav instructions that are currently displayed on the map
      this.selectionManagementService.removeInstructionMarker();
      // Reset the nav instruction index if a previous stop had been selected
      if (
        this.selectionManagementService.selectedStopIndex$.value !== stopIndex &&
        this.selectionManagementService.selectedNavInstructionIndex !== undefined
      ) {
        this.selectionManagementService.clearSelectedNavInstructionIndex();
      }

      // Only toggle the stop marker popup if the stop name is clicked, basically
      if (navInstructionIndex === undefined) {
        // If the user didn't click on a navigation instruction, then we should clear that stored value
        this.selectionManagementService.clearSelectedNavInstructionIndex();

        // Set the marker popup index, then toggle it
        this.selectionManagementService.setMarkerPopupIndex(stopIndex);
        this.mapService.toggleStopMarkerPopUp(stopIndex);
        // this keeps the popup content up-to-date between stop-marker-popup component lifecycles
        this.mapService.stopMarkerReferences[stopIndex].getPopup().update();

        // and also only select the segment on stop name click
        this.selectionManagementService.toggleSelectedStopSegment(stopIndex);
      } else {
        // if the nav instruction is clicked, then close the stop marker popup, since the nav instruction marker will be
        // created and added to the map
        this.mapService.mapReference.closePopup();
      }

      // We only want to generate a nav instruction marker if a stop has already been selected from the nav list
      if (this.selectionManagementService.selectedStopIndex$.value === stopIndex && navInstructionIndex !== undefined) {
        this.selectionManagementService.toggleSelectedNavInstructionSegment(
          navInstructionIndex,
          this.stops[stopIndex]['nav_instructions'][navInstructionIndex]
        );
      }
    }
  }

  private extractNavInstructions(route: RouteDetail): string[][] {
    /**
     * This returns an array of arrays, representing the entire set of navigation instructions available to a route.
     * Each inner array contains the sequential entries of a single stop's nav instructions
     */

    // This conditional will be removed with some more API work (some routes are currently being returned without nav_instruction properties)...
    // console.log('-extractNavInstructions', route['best']);
    if (route['best'] && route['best'].length > 1 && route['best'][1]?.waypoints && route['best'][1]?.nav_instructions) {
      return route['best']
        .filter(stop => stop.waypoints && stop.waypoints.length > 0)
        .map(stop => stop.nav_instructions.map(_ => _.instruction));
    }
  }
}
