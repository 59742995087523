<div
  *ngIf="(utilitiesService.routesLoading$ | async) === false; else loading"
  class="routes-overview-container mat-elevation-z4">
  <ng-template [ngIf]="(pendingRoutesCount$ | async) > 0" [ngIfElse]="noPendingRoutes">
    <h2 id="pending-routes-count" data-cy="pending-routes-count">{{ pendingRoutesCount$ | async }}</h2>
    <div id="pending-routes-content">
      <h3>Recorded routes awaiting approval</h3>
      <a data-dd-action-name="click on View Pending Routes" (click)="viewPendingRoutes()" data-cy="kpi-view-pending"
        >View pending routes</a
      ><button mat-raised-button id="create-route-button" class="blue" (click)="openRouteCreationDialog()">
        Create New Route
      </button>
    </div>
  </ng-template>
</div>

<ng-template #noPendingRoutes>
  <div id="no-pending-routes-icon">
    <mat-icon class="no-pending-routes-icon">check_circle</mat-icon>
  </div>
  <div id="no-pending-routes-content">
    <h3>You're all caught up!</h3>
    <p>As new recordings come in, they'll be displayed here.</p>
    <button mat-raised-button id="create-route-button" class="blue" (click)="openRouteCreationDialog()">
      Create New Route
    </button>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
