import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { EditManagementService } from '@app/services/edit-management.service';
import { UtilitiesService } from '@app/services/utilities.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { MapService } from '@app/services/map.service';
import { RouteCreationService } from '@app/features/route-creation/route-creation.service';

@Component({
  selector: 'app-route-preview',
  templateUrl: './route-preview.component.html',
  styleUrls: ['./route-preview.component.scss'],
})
export class RoutePreviewComponent implements OnInit, OnDestroy {
  @ViewChild('navInstructionDrawer', { static: false }) public navInstructionDrawer: MatDrawer;

  public routeLoading: boolean;
  public editMode: boolean;
  public routeCreationMode: boolean;
  public newRouteSource: string;

  private newRouteSourceSub?: Subscription;
  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    public editManagementService: EditManagementService,
    private utilitiesService: UtilitiesService,
    private mapService: MapService,
    public routeCreationService: RouteCreationService
  ) {}

  ngOnInit(): void {
    this.utilitiesService.routeLoading$.pipe(takeUntil(this.unsubscribe$)).subscribe(loading => (this.routeLoading = loading));
    this.editManagementService.editMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(editMode => (this.editMode = editMode));
    this.routeCreationService.routeCreationModeEnabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(routeCreationMode => (this.routeCreationMode = routeCreationMode));

    this.newRouteSourceSub = this.routeCreationService.newRouteSource$.subscribe(
      (newRouteSource: string) => (this.newRouteSource = newRouteSource)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.newRouteSourceSub.unsubscribe();
  }

  toggleDrawer(action: MatDrawerToggleResult) {
    if (this.navInstructionDrawer) {
      this.navInstructionDrawer[action]().then(() => this.mapService.mapReference.invalidateSize());
    }
  }

  get rootClass() {
    let rootClass = '';

    if (this.editMode) {
      rootClass = 'edit ';
    }

    if (this.routeCreationMode) {
      rootClass = `${rootClass}create`;
    }

    return rootClass;
  }
}
