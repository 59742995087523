import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteDetail } from '@app/shared/models/api_interfaces';
import { RoutesService } from '@app/services/routes.service';
import { MatDialog } from '@angular/material/dialog';
import { RouteEditDialogComponent } from '@app/features/route-editing/route-edit-dialog/route-edit-dialog.component';
import { EditManagementService } from '@app/services/edit-management.service';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { DebugService } from '@app/services/debug.service';
import { RouteCreationService } from '@app/features/route-creation/route-creation.service';
import { RouteCreationDialogComponent } from '@app/features/route-creation/components/route-creation-dialog/route-creation-dialog.component';
import { FeatureType } from '@app/shared/utils/RouteFeatureManager';
import { NewRouteSourceType } from '@app/features/route-creation/rc_interfaces';

@Component({
  selector: 'app-route-edit-menu',
  templateUrl: './route-edit-menu.component.html',
  styleUrls: ['./route-edit-menu.component.scss'],
})
export class RouteEditMenuComponent implements OnInit, OnDestroy {
  public route: RouteDetail;
  public editMode: boolean;
  public routeCreationMode: boolean;
  public newRouteSource: NewRouteSourceType = undefined;
  public routeEditingFeaturesVisible: boolean;
  public editMenuStyles: Record<string, string> = {};
  public editMenuClasses: Record<string, boolean> = {};
  public bulkEditMode: boolean;

  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    public editManagementService: EditManagementService,
    private routesService: RoutesService,
    public dialog: MatDialog,
    public debugService: DebugService,
    public routeCreationService: RouteCreationService
  ) {}

  setRouteEditingFeaturesVisible() {
    let visible = false;
    if (this.editMode) {
      visible = true;
    }
    if (this.routeCreationMode && this.newRouteSource == 'empty') {
      visible = true;
    }
    this.routeEditingFeaturesVisible = visible;
  }

  setEditMenuClasses() {
    this.editMenuClasses = {
      'mat-elevation-z6': this.newRouteSource != 'split',
      'mat-elevation-z2': this.newRouteSource === 'split',
    };
  }

  setEditMenuStyles() {
    this.editMenuStyles = {
      display: 'flex',
      padding: '0 0.5rem',
      'justify-content': this.newRouteSource === 'split' || this.newRouteSource === 'clone' ? 'flex-end' : 'space-between',
    };
  }

  get isRouteValid() {
    const isRouteNameEmpty = this.editManagementService.editableRoute.name.trim() === '';
    let isValid = true;

    if (isRouteNameEmpty) {
      isValid = false;
    }

    // any other route validations may be added here
    if (this.routesService.displayedRoute$.getValue() === undefined) {
      isValid = false;
    }

    return isValid;
  }

  ngOnInit(): void {
    this.setEditMenuStyles();
    this.setEditMenuClasses();
    this.routesService.displayedRoute$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((route: RouteDetail) => (this.route = route));
    this.editManagementService.editMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(editMode => {
      this.editMode = editMode;
      this.setRouteEditingFeaturesVisible();
    });
    this.routeCreationService.routeCreationModeEnabled$.pipe(takeUntil(this.unsubscribe$)).subscribe(routeCreationMode => {
      this.routeCreationMode = routeCreationMode;
      this.setRouteEditingFeaturesVisible();
    });
    this.routeCreationService.newRouteSource$.pipe(takeUntil(this.unsubscribe$)).subscribe(newRouteSource => {
      this.newRouteSource = newRouteSource;
      this.setRouteEditingFeaturesVisible();
      this.setEditMenuStyles();
      this.setEditMenuClasses();
    });
    this.editManagementService.bulkEditMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(bem => {
      this.bulkEditMode = bem;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  requestAction(action: string) {
    switch (action) {
      case 'edit':
        this.editManagementService.openEditMode();
        break;
      case 'undo':
        this.editManagementService.undoEdit();
        break;
      case 'redo':
        this.editManagementService.redoEdit();
        break;
      case 'stop':
        this.editManagementService?.features.enable(FeatureType.Stop);
        this.editManagementService?.features.enableGuidanceMenu();
        break;
      case 'uturn':
        this.editManagementService?.features.enable(FeatureType.Uturn);
        this.editManagementService?.features.enableGuidanceMenu();
        break;
      case 'waypoint':
        this.editManagementService?.features.enable(FeatureType.Waypoint);
        this.editManagementService?.features.enableGuidanceMenu();
        break;
      default:
        this.openDialog(action);
        this.editManagementService?.features.disableGuidanceMenu();
    }
  }

  public openDialog(action: string): void {
    const dialogOptions = {
      backdropClass: 'backdrop-blur',
      data: { routeAction: action },
    };

    this.routeCreationMode ? (dialogOptions.data['display'] = 'actions') : (dialogOptions['width'] = '34vw');
    this.routeCreationMode
      ? this.dialog.open(RouteCreationDialogComponent, dialogOptions)
      : this.dialog.open(RouteEditDialogComponent, dialogOptions);
  }

  removeEditChanges() {
    this.editManagementService.discardRouteChanges();
  }
}
