import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subject, Observable, iif, switchMap, of, debounceTime, scheduled, asyncScheduler } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { RouteEditDialogComponent } from '@app/features/route-editing/route-edit-dialog/route-edit-dialog.component';
import { EditManagementService } from '@app/services/edit-management.service';
import { SelectionManagementService } from '@app/services/selection-management.service';
import { MapService } from '@app/services/map.service';
import { HereService } from '@app/services/here.service';
import { HereGeoCodePosition } from '@app/shared/models/here_interfaces';
import _ from 'lodash-es';
import { MarkerType, RouteEditOptions } from '@app/shared/models/app_interfaces';
import { LatLng } from 'leaflet';

@Component({
  selector: 'app-stop-marker-popup',
  templateUrl: './stop-marker-popup.component.html',
  styleUrls: ['./stop-marker-popup.component.scss'],
})
export class StopMarkerPopupComponent implements OnInit, OnDestroy {
  // Pretty aggressive with the BehaviorSubjects, because we need async interpolation
  public stopIndex: number;
  public stopName = new BehaviorSubject('');
  public stopAddress: string;
  public metersToNextStop = new BehaviorSubject(0);
  public secondsToNextStop = new BehaviorSubject(0);
  public startingPosition: boolean;
  public finalStop: boolean;
  public totalRouteSeconds = new BehaviorSubject(0);
  public totalRouteMeters = new BehaviorSubject(0);
  private unsubscribe$ = new Subject();

  constructor(
    public editManagementService: EditManagementService,
    private selectionManagementService: SelectionManagementService,
    private mapService: MapService,
    public dialog: MatDialog,
    private hereService: HereService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // We rely on the stop index heavily in this component, so we subscribe and follow the
    this.selectionManagementService.stopMarkerPopupIndex$
      .pipe(
        filter(i => i !== undefined),
        map((index: number) => {
          this.stopIndex = index;

          this.stopName.next(this.editManagementService.editableRoute.best[index]?.name);
          this.stopAddress = 'searching...';
          this.startingPosition = index === 0 ? true : false;
          this.finalStop = index === this.editManagementService.editableRoute.best.length - 1 ? true : false;

          // stop[0] is the route's starting position, and doesn't have travel_dist/time properties
          if (this.startingPosition) {
            this.metersToNextStop.next(this.editManagementService.editableRoute.best[index + 1].travel_dist);
            this.secondsToNextStop.next(this.editManagementService.editableRoute.best[index + 1].travel_time);
          } else {
            this.metersToNextStop.next(this.editManagementService.editableRoute.best[index].travel_dist);
            this.secondsToNextStop.next(this.editManagementService.editableRoute.best[index].travel_time);
          }

          // We only need these values for the final stop popup
          if (this.finalStop) {
            this.totalRouteSeconds.next(
              this.editManagementService.editableRoute.best
                .map(x => x.travel_time)
                .filter(Boolean)
                .reduce((a, b) => a + b, 0)
            );
            this.totalRouteMeters.next(
              this.editManagementService.editableRoute.best
                .map(x => x.travel_dist)
                .filter(Boolean)
                .reduce((a, b) => a + b, 0)
            );
          }
          return index;
        }),
        switchMap(stopIndex => {
          const position = {
            lat: _.get(this.editManagementService.editableRoute.best[stopIndex], ['lat'], 0),
            lng: _.get(this.editManagementService.editableRoute.best[stopIndex], ['lon'], 0),
          };
          return this.hereService.getHereRevGeocode(position);
        }),
        map(address => {
          this.stopAddress = address;
          this.cdRef.detectChanges();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public openDialog(action: string): void {
    this.dialog.open(RouteEditDialogComponent, {
      backdropClass: 'backdrop-blur',
      width: '34vw',
      data: { routeAction: action, positionIndex: this.stopIndex },
    });
  }

  public saveUpdatedPosition() {
    of(this.stopAddress)
      .pipe(
        debounceTime(300),
        filter(this.hereService.validateAddress),
        switchMap(address => this.hereService.getHereGeocode(address)),
        switchMap(position => {
          let markerType: MarkerType = 'stop';
          if (this.startingPosition) {
            markerType = 'start';
          }
          if (this.finalStop) {
            markerType = 'destination';
          }
          const routeEditOptions: RouteEditOptions = {
            newPosition: new LatLng(position.lat, position.lng),
            positionIndex: this.stopIndex,
            markerType,
          };
          return this.editManagementService.initiateEditFlow('updateMarkerPosition', routeEditOptions);
        }),
        take(1)
      )
      .subscribe();
  }

  public closeSelf(): void {
    // Current UX design wants the stop marker popup's save button to just close the popup, but *not* save the route changes
    // this will now be used to update the marker position if the address input has changed
    this.mapService.mapReference.closePopup();
  }
}
