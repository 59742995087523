import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { RouteDetailsComponent } from './features/route-display/components/route-details/route-details.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: RouteDetailsComponent,
    outlet: 'section-quad-q2',
  },
  {
    path: 'create-route',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/route-creation/route-creation.module').then(m => m.RouteCreationModule),
    outlet: 'section-quad-q2',
  },
  {
    path: 'create-route/from-split',
    canActivate: [AuthGuard],
    outlet: 'section-quad-container',
    loadChildren: () => import('./features/route-splitting/route-splitting.module').then(m => m.RouteSplittingModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
