import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, NgZone, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import {
  control,
  tileLayer,
  latLng,
  divIcon,
  Icon,
  FitBoundsOptions,
  MapOptions,
  MarkerOptions,
  PolylineOptions,
  PopupOptions,
  Map,
  LatLng,
  Polyline,
  Marker,
  TileLayerOptions,
  layerGroup,
  LeafletMouseEvent,
  DragEndEvent,
  DivIcon,
  LayerGroup,
  LeafletEvent,
  LayerEvent,
} from 'leaflet';
import { iif, of, Observable, Subject } from 'rxjs';
import { map, tap, first, concatMap, filter, switchMap, takeUntil, take } from 'rxjs/operators';
import { environment as env } from '@environments/environment';
import { MapService } from '@app/services/map.service';
import { DebugService } from '@app/services/debug.service';
import { DebugMarker, RouteEditOptions, StopMarker, WaypointMarker } from '@app/shared/models/app_interfaces';
import { UtilitiesService } from '@app/services/utilities.service';
import { EditManagementService } from '@app/services/edit-management.service';
import { SelectionManagementService } from '@app/services/selection-management.service';

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.scss'],
})
export class RouteMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() 'routeLoading': boolean;
  // To be able to access the Leaflet Directive rendered template DOM elements
  @ViewChild(LeafletDirective) leafletDirective: LeafletDirective;

  // Observable values subscribed to in template for dynamic changing
  stopMarkers$: Observable<Marker[]>;
  routePath$: Observable<Polyline[]>;
  selectionPath$: Observable<Polyline | void>;
  waypointMarkers$: Observable<LayerGroup>;
  unsubscribe: Subject<any>;

  // Debugging
  altSourceNames$: Observable<string[]>;

  // Leaflet Configuration
  zoomThreshold = 15; // Zoom Level at which style stuff should happen (marker changes, etc)

  // New HERE Raster Tile API v3 URLs
  hereStreetUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?size=256&apiKey=${env.hereApi.apiKey}`;
  hereAerialUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=satellite.day&size=256&apiKey=${env.hereApi.apiKey}`;
  hereTerrainUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=terrain.day&size=256&apiKey=${env.hereApi.apiKey}`;

  tileLayerBaseOptions: TileLayerOptions = {
    opacity: 1,
    subdomains: ['1', '2', '3', '4'],
    attribution:
      '&copy; 1987 - 2021 <a href="https://legal.here.com/us-en/terms" target="_blank">HERE</a>. All rights reserved.',
    detectRetina: true,
    minZoom: 2,
    maxZoom: 20,
    tileSize: 256,
  };

  tileLayers = {
    STREET: tileLayer(this.hereStreetUrl, this.tileLayerBaseOptions),
    AERIAL: tileLayer(this.hereAerialUrl, this.tileLayerBaseOptions),
    TERRAIN: tileLayer(this.hereTerrainUrl, this.tileLayerBaseOptions),
  };

  mapOptions: MapOptions = {
    layers: [this.tileLayers.STREET],
    zoom: 10,
    center: latLng(47.604054, -122.334963),
  };

  layersControl = {
    baseLayers: {
      Street: this.tileLayers.STREET,
      Aerial: this.tileLayers.AERIAL,
      Terrain: this.tileLayers.TERRAIN,
    },
    overlays: {},
  };

  markerMap = {
    'stop-marker': 'stop',
    'destination-marker': 'destination',
    'start-marker': 'start',
    'waypoint-marker': 'waypoint',
  };

  waypointMarkerOptions: MarkerOptions = {
    icon: new DivIcon({
      iconSize: [9, 9],
      className: `waypoint-marker`,
    }),
    draggable: true,
  };

  stopMarkerPopupOptions: PopupOptions = {
    offset: [0, -30],
    closeButton: false,
    maxWidth: 900,
    className: 'stop-marker-popup',
    autoPanPadding: [0, 30],
  };

  routePolylineOptions: PolylineOptions = {
    opacity: 1,
    color: 'rgb(86,163,230)',
    weight: 6,
    className: 'route-polyline',
  };

  fitBoundsOptions: FitBoundsOptions = {
    padding: [0, 0],
    maxZoom: 16,
  };

  grabbingCursor: boolean;

  private unsubscribe$ = new Subject();

  constructor(
    public mapService: MapService,
    public selectionManagementService: SelectionManagementService,
    public debugService: DebugService,
    public editManagementService: EditManagementService,
    private utilitiesService: UtilitiesService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.stopMarkers$ = this.mapService.createMarkers$('stops', {
      markerOptions: {
        origin: startMarkerOptions,
        stop: stopMarkerOptions,
        destination: destinationMarkerOptions,
      },
      popupOptions: this.stopMarkerPopupOptions,
    });

    this.routePath$ = this.mapService.createRoutePolylines$(this.routePolylineOptions).pipe(
      tap(() => {
        // This ensures that any time the route gets re-drawn, any previously created selection paths or instruction markers are removed
        this.selectionManagementService.setSelectedStopIndex(undefined);
        this.selectionManagementService.setMarkerPopupIndex(undefined);
      })
    );

    this.waypointMarkers$ = this.editManagementService.getWaypointEdits$().pipe(
      switchMap(waypointEdits =>
        iif(
          () => waypointEdits.length > 0,
          // We want this pipe to allow undefined values to pass through, since this will effectively remove the markers from the map view
          this.mapService
            .createMarkers$('addedWaypoint', {
              markerOptions: { waypoint: this.waypointMarkerOptions },
              matchedWaypoints: { waypointEdits },
            })
            // Layergroups are effective ways to improve performance when dealing with large numbers of layers
            .pipe(map((waypointMarkers: WaypointMarker[]) => new LayerGroup(waypointMarkers))),

          of(undefined)
        )
      ),
      tap(x => {
        if (x instanceof LayerGroup) {
          x.eachLayer((l: WaypointMarker) => {
            l.on('dragend', e => {
              this.zone.run(() => {
                this.markerDragEndEventHandler(e, 'waypoint');
              });
            });
          });
        }
      })
    );

    // This adds an additional layer of coordinates for each source
    if (this.debugService.debugEnabled) {
      // Best is the usable source, alts are all the others
      this.altSourceNames$ = this.mapService.getAltSourceNames$();
      this.altSourceNames$
        .pipe(
          filter(x => x.hasOwnProperty('length')),
          tap(names => {
            this.debugService.debugOverlays = names.reduce((n, overlayname) => {
              n[overlayname] = false;
              return n;
            }, {});
          }),
          map(names => names.map((name, idx) => [idx, name])),
          concatMap(x => x),
          map((source: [number, string]) => {
            // this clears overlays between new route loads
            this.clearTraceDebugWaypoints(source[1]);
            const debugMarkerOptions: MarkerOptions = {
              icon: divIcon({ iconSize: [5, 5], className: `debug-waypoint-recorded-div-icons-${source[0]}` }),
            };

            this.mapService
              .createMarkers$('debug-waypoints', { markerOptions: { debug: debugMarkerOptions } }, source[1])
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(debugMarkers => {
                this.layersControl.overlays[source[1]] = new LayerGroup(debugMarkers);
              });
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }

    // We want to close certain things when the escape button is pressed
    this.utilitiesService.escapePressedEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.escapePressEventHandler());
  }

  ngAfterViewInit(): void {
    if (this.debugService.debugEnabled) {
      // just in case we want to eventually expand some of the debug waypoint functionality:

      // this.mapService.mapReference.addEventListener('overlayadd', (e: LayersControlEvent) => {
      //   this.debugService.debugOverlays[e.name] = true;
      // });
      // this.mapService.mapReference.addEventListener('overlayremove', (e: LayersControlEvent) => {
      //   this.debugService.debugOverlays[e.name] = false;
      // });
      this.selectionManagementService.selectedStopIndex$
        .pipe(
          map((stopIndex: number | undefined) => {
            // copy of object to restore any debug waypoint overlays that had been enabled prior to selected-stop change
            // const debugOverlaysCopy = { ...this.debugService.debugOverlays };
            if (stopIndex !== undefined) {
              // clear any previously drawn debug waypoint overlays, prior to adding the selected-stop specific ones
              Object.entries(this.layersControl.overlays).forEach(([sourceName, ol]) =>
                this.clearTraceDebugWaypoints(sourceName)
              );

              Object.entries(this.debugService.debugReferences).forEach(([sourceName, debugMarkers]) => {
                // replace overlays object with filtered waypoints specific to the selected stop index
                this.layersControl.overlays[sourceName] = layerGroup(
                  debugMarkers.filter((m: DebugMarker) => m.stopIndex === stopIndex)
                );
                // if the previous selected stop had an overlay active, restore that overlay for the new select-stop waypoints
                // if (debugOverlaysCopy[sourceName]) {
                //   this.layersControl.overlays[sourceName].invoke('addTo', this.mapService.mapReference);
                // }
              });
            } else {
              // if there isn't a selected stop, then we want the entire debug waypoints overlays to be available
              Object.entries(this.debugService.debugReferences).forEach(([sourceName, debugMarkers]) => {
                this.clearTraceDebugWaypoints(sourceName);
                // replace overlays object with filtered waypoints specific to the selected stop index
                this.layersControl.overlays[sourceName] = layerGroup(debugMarkers);
                // if the previous selected stop had an overlay active, restore that overlay for the new select-stop waypoints
                // if (debugOverlaysCopy[sourceName]) {
                //   this.layersControl.overlays[sourceName].invoke('addTo', this.mapService.mapReference);
                // }
              });
            }
          })
        )
        .subscribe();
    }

    // This is all related to visual highlighting of selected stops/routes
    this.selectionManagementService.selectedStopIndex$
      .pipe(
        map((stopIndex: number) => {
          if (stopIndex === undefined) {
            // Restore full opacity whenever a stop segment is deselected
            this.mapService.stopMarkerReferences.forEach(marker => marker.setOpacity(1));
            this.mapService.stopSegmentPolylineReferences.forEach(segment => segment.setStyle({ opacity: 1 }));
          } else {
            // Reduce opacity of all map markers & path polylines
            this.mapService.stopMarkerReferences.forEach(marker => marker.setOpacity(0.3));
            this.mapService.stopSegmentPolylineReferences.forEach(segment => segment.setStyle({ opacity: 0.3 }));
            // if the stop index refers to the starting position, we'll want to shift the value to one,
            // so that clicking on a starting position highlights start -> first stop
            if (stopIndex === 0) {
              stopIndex = 1;
            }
            // Set full opacity on the connected stops and their segment
            this.mapService.stopMarkerReferences[stopIndex].setOpacity(1);
            this.mapService.stopMarkerReferences[stopIndex - 1].setOpacity(1);
            this.mapService.stopSegmentPolylineReferences[stopIndex - 1].setStyle({ opacity: 1 });
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    // Map Markers should only be draggable if within Edit Mode
    this.editManagementService.editMode$
      .pipe(
        map((editMode: boolean) => {
          setTimeout(() => {
            this.mapService.mapReference.invalidateSize();
          }, 100);
          this.mapService.stopMarkerReferences.forEach((marker: Marker) =>
            editMode ? marker.dragging?.enable() : marker.dragging?.disable()
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.editManagementService.addFeatureMode$
      // We want to remove any previous selections or map markers whenever a user enters Add Route Feature Mode
      .pipe(
        map((addFeatureMode: boolean) => {
          if (addFeatureMode === true) {
            this.mapService.mapReference.closePopup();
            this.selectionManagementService.clearSelectedNavInstructionIndex();
            this.selectionManagementService.setSelectedStopIndex(undefined);
            this.selectionManagementService.removeInstructionMarker();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onMarkerAdd(markerAddEvent: LayerEvent) {
    // This sets the initial state of the Markers dragging property. This is necessary if changes to the markers occur while in an Edit Session
    this.editManagementService.editMode$.value === true
      ? markerAddEvent.target.dragging.enable()
      : markerAddEvent.target.dragging.disable();
    const marker: StopMarker = markerAddEvent.target;
    const segmentIndex = marker['stopIndex'] !== 0 ? marker['stopIndex'] - 1 : 0;

    // When in Add Feature Mode, we want map markers to trigger the same highlight/selection of path segments as the segments themselves (see onSegmentPathAdd)
    marker.addEventListener('click', () => {
      if (this.editManagementService.addFeatureMode$.value === false) {
        this.selectionManagementService.setMarkerPopupIndex(marker.stopIndex);
        this.selectionManagementService.setSelectedStopIndex(marker.stopIndex);
        this.selectionManagementService.removeInstructionMarker();
        marker.getPopup().update();
      } else {
        marker.closePopup();
        if (this.editManagementService.features.isSegmentSelected === false) {
          this.editManagementService.features.selectedSegmentIndex$.next(segmentIndex);
          this.zone.run(() => {
            setTimeout(() => {
              this.editManagementService.features.isSegmentSelected = true;
            }, 50);
          });
        }
      }
    });

    marker.addEventListener('mouseover', () => {
      if (
        this.editManagementService.addFeatureMode$.value === true &&
        this.editManagementService.features.isSegmentSelected === false
      ) {
        this.mapService.stopSegmentPolylineReferences[segmentIndex].setStyle({ weight: 7 });
        this.mapService.stopSegmentPolylineReferences[segmentIndex]['_path'].setAttribute(
          'class',
          'route-polyline leaflet-interactive highlight-route-polyline'
        );
      }
    });

    marker.addEventListener('mouseout', () => {
      // The segment highlight should be removed on mouseout if the segment has not been selected
      if (this.editManagementService.features.selectedSegmentIndex$.value !== segmentIndex) {
        this.mapService.stopSegmentPolylineReferences[segmentIndex].setStyle({ weight: 5 });
        this.mapService.stopSegmentPolylineReferences[segmentIndex]['_path'].setAttribute(
          'class',
          'route-polyline leaflet-interactive'
        );
      }
    });

    marker.addEventListener('dragend', (e: DragEndEvent) => {
      // Dragging the marker should trigger an edit to that marker stop/segment position
      const markerType: string = marker.options.icon.options.className;
      if (this.editManagementService.editMode$.value === true) {
        this.zone.run(() => {
          this.markerDragEndEventHandler(e, this.markerMap[markerType]);
        });
      }
    });
  }

  onSegmentPathAdd(pathAddEvent: LayerEvent) {
    const stopSegment: Polyline = pathAddEvent.target;
    stopSegment.addEventListener('mouseover', () => {
      // We want mouseover to highlight the segment only if addFeatureMode is enabled and there isn't currently a segment selected
      if (
        this.editManagementService.addFeatureMode$.value === true &&
        this.editManagementService.features.isSegmentSelected === false
      ) {
        stopSegment.setStyle({ weight: 7 });
        stopSegment['_path'].setAttribute('class', 'route-polyline leaflet-interactive highlight-route-polyline');
      }
    });
    stopSegment.addEventListener('mouseout', () => {
      // The segment highlight should be removed on mouseout if the segment has not been selected
      if (this.editManagementService.features.selectedSegmentIndex$.value !== stopSegment['stopSegmentIndex']) {
        stopSegment.setStyle({ weight: 5 });
        stopSegment['_path'].setAttribute('class', 'route-polyline leaflet-interactive');
      }
    });
    stopSegment.addEventListener('click', () => {
      // if the segment is clicked, we should set the selectedSegmentIndex value, which will prevent additional segment mouseovers
      // from highlighting, as well as prevent the mouseout event from removing the segment highlight
      if (
        this.editManagementService.addFeatureMode$.value === true &&
        this.editManagementService.features.isSegmentSelected === false
      ) {
        this.editManagementService.features.selectedSegmentIndex$.next(stopSegment['stopSegmentIndex']);
        this.zone.run(() => {
          setTimeout(() => {
            this.editManagementService.features.isSegmentSelected = true;
          }, 50);
        });
      }
    });
  }

  public onMapReady(leafletMap: Map): void {
    // Assign a reference to the map so that other components may easily use it
    this.mapService.mapReference = leafletMap;
    leafletMap.getContainer().setAttribute('alt', 'Map');
    // Remove the default zoom control from map's upper left so we can add a new one to map's bottom right
    leafletMap.zoomControl.remove();
    leafletMap.addControl(control.zoom({ position: 'bottomright' }));
    leafletMap.addEventListener('mousedown', (e: LeafletEvent) => {
      this.zone.run(() => {
        this.grabbingCursor = true;
      });
    });
    leafletMap.addEventListener('mouseup', (e: LeafletEvent) => {
      this.grabbingCursor = false;
    });
  }

  public onMapClick(event: LeafletMouseEvent) {
    // Clicking the map should remove any highlighted sections and markers of the route path
    this.mapService.stopMarkerReferences.forEach(marker => marker.setOpacity(1));
    this.mapService.stopSegmentPolylineReferences.forEach(segment => segment.setStyle({ opacity: 1 }));
    // As well as removing the nav instruction marker, if present
    this.selectionManagementService.removeInstructionMarker();
    // And also the indices responsible for the nav instruction list item highlighting
    this.selectionManagementService.clearSelectedNavInstructionIndex();
    this.selectionManagementService.setSelectedStopIndex(undefined);

    // And conditionally add a new route feature
    if (this.editManagementService.features.isSegmentSelected) {
      this.addNewRouteFeature(latLng(event.latlng));
    }
  }

  private clearTraceDebugWaypoints(traceName: string) {
    if (this.layersControl['overlays'].hasOwnProperty(traceName)) {
      const ol: LayerGroup = this.layersControl['overlays'][traceName];
      ol.remove();
    }
  }

  private escapePressEventHandler(): void {
    if (this.editManagementService.features.isFeatureMenuVisible || this.editManagementService.features.isGuidanceMenuVisible) {
      this.editManagementService.closeAddFeatureMode();
    }
  }

  private markerDragEndEventHandler(event: DragEndEvent, markerType: 'start' | 'stop' | 'waypoint' | 'destination'): void {
    // Currently, feature markers are only able to be dragged when making edits (editMode), so this handler is used to
    // initiate those edits
    const routeEditOptions: RouteEditOptions = {
      newPosition: event.target.getLatLng(),
      positionIndex: event.target.stopIndex,
      markerType,
    };
    if (markerType === 'waypoint') {
      routeEditOptions.waypointId = event.target.uniqueId;
    }
    this.editManagementService.initiateEditFlow('updateMarkerPosition', routeEditOptions).pipe(take(1)).subscribe();
  }

  private addNewRouteFeature(featurePosition: LatLng): void {
    const featureType = this.editManagementService.features.featureType;
    const newFeatureIndex = this.editManagementService.features.selectedSegmentIndex$.value + 1;
    const addFeatObs =
      featureType === 'waypoint'
        ? this.editManagementService.initiateEditFlow('addWaypoint', {
            wpPosition: featurePosition,
            associatedSegmentIndex: newFeatureIndex,
          })
        : this.editManagementService.initiateEditFlow('addStop', {
            newStopPosition: featurePosition,
            positionIndex: newFeatureIndex,
          });

    addFeatObs
      .pipe(
        first(),
        tap(() => this.editManagementService.closeAddFeatureMode()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}

export const stopMarkerOptions: MarkerOptions = {
  icon: new Icon({
    iconSize: [25, 41],
    iconAnchor: [12.5, 33],
    iconUrl: 'assets/icons/map/stop_map_icon_blue.svg',
    className: 'stop-marker',
  }),
};

export const startMarkerOptions: MarkerOptions = {
  icon: new Icon({
    iconSize: [25, 41],
    iconUrl: 'assets/icons/map/origin_map_icon.svg',
    className: 'start-marker',
  }),
};

export const destinationMarkerOptions: MarkerOptions = {
  icon: new Icon({
    iconSize: [25, 41],
    iconUrl: 'assets/icons/map/destination_map_icon.svg',
    className: 'destination-marker',
  }),
};
