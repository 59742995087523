import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appBackgroundFromStatus]',
})
export class BackgroundFromStatusDirective implements OnChanges {
  @Input('appBackgroundFromStatus') status: string;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    switch (this.status) {
      case 'approved':
        this.el.nativeElement.style.backgroundColor = 'rgb(193, 215, 189)';
        break;
      case 'rejected':
        this.el.nativeElement.style.backgroundColor = 'rgb(227, 192, 188)';
        break;
      default:
        this.el.nativeElement.style.backgroundColor = 'rgb(208, 226, 245)';
        break;
    }
  }
}
