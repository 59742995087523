import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ImportRoutesProgressDialogComponent } from './components/import-routes-progress-dialog/import-routes-progress-dialog.component';
import { ImportRoutesSnackbarComponent } from './components/import-routes-snackbar/import-routes-snackbar.component';
import { ImportRoutesUploadDialogComponent } from './components/import-routes-upload-dialog/import-routes-upload-dialog.component';
import { ImportRoutesErrorsDialogComponent } from './components/import-routes-errors-dialog/import-routes-errors-dialog.component';

@NgModule({
  declarations: [
    ImportRoutesProgressDialogComponent,
    ImportRoutesSnackbarComponent,
    ImportRoutesUploadDialogComponent,
    ImportRoutesErrorsDialogComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    ImportRoutesProgressDialogComponent,
    ImportRoutesSnackbarComponent,
    ImportRoutesUploadDialogComponent,
    ImportRoutesErrorsDialogComponent,
  ],
})
export class ImportRoutesModule {}
