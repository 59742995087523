<div class="stop-marker-popup-container">
  <div
    *ngIf="(editManagementService.editMode$ | async) === true && this.stopIndex !== 0"
    class="stop-marker-popup-edit-mode-container">
    <p>
      <span>EDIT STOP</span>
      <button mat-icon-button (click)="closeSelf()">
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <mat-form-field
      appearance="outline"
      *ngIf="editManagementService.editableRoute['stopData'][stopIndex]"
      data-dd-action-name="click on Edit Stop Name from Marker Popup">
      <mat-label>Stop Name</mat-label>
      <input
        matInput
        title="Stop name"
        type="text"
        (keydown)="editManagementService.storeState()"
        [(ngModel)]="editManagementService.editableRoute['stopData'][stopIndex].name"
        (keydown.Space)="$event.stopPropagation()" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Address</mat-label>
      <input matInput title="Stop Address" type="text" [(ngModel)]="stopAddress" />
    </mat-form-field>
    <div class="stop-marker-popup-edit-mode-button-container">
      <button
        mat-button
        id="stop-marker-popup-delete-stop-button"
        data-dd-action-name="click on Delete Stop Button"
        (click)="openDialog('delete')">
        Delete
      </button>
      <button
        mat-flat-button
        class="stop-marker-popup-update-stop-button"
        data-dd-action-name="click on Update Stop Name Button"
        (click)="saveUpdatedPosition()">
        Update
      </button>
    </div>
  </div>

  <div
    *ngIf="(editManagementService.editMode$ | async) === true && this.stopIndex == 0"
    class="stop-marker-popup-edit-mode-container">
    <p>
      <span class="stop-marker-popup-bold">{{ stopName | async | trimStartingPositionName }} </span
      ><button mat-icon-button (click)="closeSelf()">
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <mat-form-field appearance="outline">
      <mat-label>Address</mat-label>
      <input matInput title="Stop Address" type="text" [(ngModel)]="stopAddress" />
    </mat-form-field>
    <div class="stop-marker-popup-edit-mode-button-container first-stop">
      <button
        mat-flat-button
        class="stop-marker-popup-update-stop-button"
        data-dd-action-name="click on Update Stop Name Button"
        (click)="saveUpdatedPosition()">
        Update
      </button>
    </div>
  </div>

  <div *ngIf="(editManagementService.editMode$ | async) === false" id="stop-marker-popup-non-edit-mode-container">
    <p>
      <span class="stop-marker-popup-bold">{{ stopName | async | trimStartingPositionName }} </span>
      <button mat-icon-button (click)="closeSelf()">
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <p>
      <span *ngIf="!this.startingPosition">Distance to this stop:</span>
      <span *ngIf="this.startingPosition">Distance to first stop:</span>
      <span class="stop-marker-popup-bold">{{ metersToNextStop | async | metersToMiles | number : '0.0-2' }} miles</span>
    </p>
    <p>
      <span *ngIf="!this.startingPosition">Approx time to this stop:</span>
      <span *ngIf="this.startingPosition">Approx time to first stop:</span>
      <span class="stop-marker-popup-bold"> {{ secondsToNextStop | async | secondsToHumanFriendly }}</span>
    </p>
    <p *ngIf="this.finalStop">
      <span>Total distance traveled:</span>
      <span class="stop-marker-popup-bold">{{ totalRouteMeters | async | metersToMiles | number : '0.0-2' }} miles</span>
    </p>
    <p *ngIf="this.finalStop">
      <span>Approx time traveled:</span>
      <span class="stop-marker-popup-bold">{{ totalRouteSeconds | async | secondsToHumanFriendly }}</span>
    </p>
  </div>
</div>
