import { Injectable } from '@angular/core';
import {
  EnrichedRouteDetail,
  RouteStatusUpdate,
  RoutePathUpdate,
  AddStopOptions,
  AddWaypointOptions,
  HereOptions,
  UpdateMarkerPositionOptions,
  DeleteStopOptions,
  BestStop,
} from '@app/shared/models/app_interfaces';
import { Observable, of, combineLatest } from 'rxjs';
import { HereService } from './here.service';
import { RoutesService } from './routes.service';
import { map, mergeMap } from 'rxjs/operators';
import { NavInstruction, Stop, Waypoint } from '@app/shared/models/api_interfaces';
import getRhumbLineBearing from 'geolib/es/getRhumbLineBearing';
import { waypoint2LatLng } from '@app/shared/utils/helpers';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EditService {
  constructor(private routesService: RoutesService, private hereService: HereService) {}

  public addNewStop(route: EnrichedRouteDetail, addStopOptions: AddStopOptions): Observable<EnrichedRouteDetail> {
    const precedingStop = route.stopData[addStopOptions.positionIndex - 1];
    const finalStop = _.get(addStopOptions, 'finalStop');
    const hereOptions = { avoidUturns: addStopOptions.avoidUturns };

    let newStopToFollowingStop$: Observable<{ nav_instructions: NavInstruction[]; waypoints: Waypoint[] } | undefined>;
    let followingStop: Stop | undefined;
    let followingStopBest: BestStop | undefined;
    let followingStopData: Stop | undefined;
    // We need some conditional logic to change the flow if the stop is the "last stop" aka destination
    if (!finalStop) {
      followingStop = route.stopData[addStopOptions.positionIndex];
      newStopToFollowingStop$ = this.hereService.getHereRoute(
        `${addStopOptions.newStopPosition.lat},${addStopOptions.newStopPosition.lng}`,
        `${followingStop.lat},${followingStop.lon}`,
        hereOptions
      );
    } else {
      newStopToFollowingStop$ = of(undefined);
    }

    const precedingStopToNewStop$: Observable<{ nav_instructions: NavInstruction[]; waypoints: Waypoint[] }> =
      this.hereService.getHereRoute(
        `${precedingStop.lat},${precedingStop.lon}`,
        `${addStopOptions.newStopPosition.lat},${addStopOptions.newStopPosition.lng}`,
        hereOptions
      );

    return precedingStopToNewStop$.pipe(
      // This is a bit tricky because we need to use the waypoints from the preceding Stop the new Stop to calculate the heading
      // from the new Stop to the following Stop
      mergeMap(precedingStopToNewStopResp => {
        const coords: Waypoint[] = precedingStopToNewStopResp.waypoints.slice(-2);

        hereOptions['heading'] = Math.trunc(
          getRhumbLineBearing({ lon: coords[0].lon, lat: coords[0].lat }, { lon: coords[1].lon, lat: coords[1].lat })
        );

        if (!addStopOptions.avoidUturns) {
          const iwp = precedingStopToNewStopResp.waypoints.slice(-3, -1);
          const iwpDeep = _.cloneDeep(iwp).map(waypoint2LatLng);
          iwpDeep.reverse();
          hereOptions['intermediateWaypoints'] = iwpDeep;
        } else {
          delete hereOptions['intermediateWaypoints'];
        }

        return combineLatest([of(precedingStopToNewStopResp), newStopToFollowingStop$]);
      }),
      map(r => {
        // The 'best' objects are formatted into the currently accepted frontend post-processing structure
        // and are used to draw the polylines on the map

        const precedingStopBest = r[0] as BestStop;

        precedingStopBest['name'] = addStopOptions.avoidUturns ? 'Added Stop' : 'Added uTurn';
        if (addStopOptions['name']) {
          precedingStopBest['name'] = addStopOptions['name'];
        }
        precedingStopBest['travel_dist'] = r[0].nav_instructions
          .map((ni: NavInstruction) => ni.length)
          .reduce((a, b) => a + b, 0);
        precedingStopBest['travel_time'] = r[0].nav_instructions
          .map((ni: NavInstruction) => ni.travelTime)
          .reduce((a, b) => a + b, 0);

        precedingStopBest.lat = addStopOptions.newStopPosition.lat;
        precedingStopBest.lon = addStopOptions.newStopPosition.lng;

        const precedingStopData: Stop = {
          lat: addStopOptions.newStopPosition.lat,
          lon: addStopOptions.newStopPosition.lng,
          name: precedingStopBest['name'],
          notes: {
            best_source_name: addStopOptions.avoidUturns ? 'Manual Add Stop' : 'Manual uTurn',
            best_source_type: addStopOptions.avoidUturns ? 'Manual Add Stop' : 'Manual uTurn',
          },
          traces: [
            {
              name: precedingStopBest['name'],
              nav_instructions: precedingStopBest.nav_instructions,
              source_name: addStopOptions.avoidUturns ? 'Manual Add Stop' : 'Manual uTurn',
              source_type: addStopOptions.avoidUturns ? 'Manual Add Stop' : 'Manual uTurn',
              waypoints: precedingStopBest.waypoints,
              travel_dist: precedingStopBest['travel_dist'],
              travel_time: precedingStopBest['travel_time'],
            },
          ],
        };

        if (!finalStop) {
          followingStopBest = r[1] as BestStop;
          followingStopBest['name'] = route.stopData[addStopOptions.positionIndex].name;
          followingStopBest['travel_dist'] = r[1].nav_instructions
            .map((ni: NavInstruction) => ni.length)
            .reduce((a, b) => a + b, 0);
          followingStopBest['travel_time'] = r[1].nav_instructions
            .map((ni: NavInstruction) => ni.travelTime)
            .reduce((a, b) => a + b, 0);
          followingStopBest.lat = _.get(followingStop, 'lat');
          followingStopBest.lon = _.get(followingStop, 'lon');
          followingStopData = {
            lat: followingStop.lat,
            lon: followingStop.lon,
            name: followingStopBest['name'],
            notes: {
              best_source_name: 'Manually Edited Stop',
              best_source_type: 'Manually Edited Stop',
            },
            traces: [
              {
                name: followingStopBest['name'],
                nav_instructions: followingStopBest.nav_instructions,
                source_name: 'Manually Edited Stop',
                source_type: 'Manually Edited Stop',
                waypoints: followingStopBest.waypoints,
                travel_dist: followingStopBest['travel_dist'],
                travel_time: followingStopBest['travel_time'],
              },
            ],
          };
        }

        // the 'best' is created during the front-end route processing, and is ephemeral
        route.best.splice(addStopOptions.positionIndex, 0, precedingStopBest);
        if (!finalStop) {
          route.best.splice(addStopOptions.positionIndex + 1, 1, followingStopBest);
        }
        // whereas the stopData property is returned for each route from the API. It is used in front-end route
        // processing to create the 'best' property
        route.stopData.splice(addStopOptions.positionIndex, 0, precedingStopData);
        if (!finalStop) {
          route.stopData.splice(addStopOptions.positionIndex + 1, 1, followingStopData);
        }
        // Finally we have to update our number of stops
        route.nStops++;
        // Update totalDist and totalTime for frontend visibility
        updateTotalDist(route);
        updateTotalTime(route);
        return route;
      })
    );
  }

  public addNewWaypoint(route: EnrichedRouteDetail, addWpOptions: AddWaypointOptions) {
    const origin: Stop = route.stopData[addWpOptions.associatedSegmentIndex - 1];
    const destination: Stop = route.stopData[addWpOptions.associatedSegmentIndex];
    const hereOptions: HereOptions = {
      avoidUturns: addWpOptions.avoidUturns,
      intermediateWaypoints: addWpOptions.intermediateWaypoints,
    };

    let updatedStopData: Stop;

    return this.hereService
      .getHereRoute(`${origin.lat},${origin.lon}`, `${destination.lat},${destination.lon}`, hereOptions)
      .pipe(
        map(r => {
          updatedStopData = route.stopData[addWpOptions.associatedSegmentIndex];
          updatedStopData.notes = {
            best_source_name: 'Manually Edited Stop',
            best_source_type: 'Manually Edited Stop',
          };
          updatedStopData.traces = [
            {
              name: updatedStopData.name,
              nav_instructions: r.nav_instructions,
              source_name: 'Manually Edited Stop',
              source_type: 'Manually Edited Stop',
              waypoints: r.waypoints,
              travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
              travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
            },
          ];
          route.best[addWpOptions.associatedSegmentIndex] = {
            name: updatedStopData.name,
            lat: updatedStopData.lat,
            lon: updatedStopData.lon,
            waypoints: r.waypoints,
            nav_instructions: r.nav_instructions,
            travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
            travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
            source_name: 'Manually Edited Stop',
            source_type: 'Manually Edited Stop',
          };
          updateTotalDist(route);
          updateTotalTime(route);
          return route;
        })
      );
  }

  public updateMarkerPosition(
    route: EnrichedRouteDetail,
    markerPositionOptions: UpdateMarkerPositionOptions
  ): Observable<EnrichedRouteDetail> {
    // There's a fair amount of duplicated code, but each use case is just different enough to necessiate specific tweaking
    let precedingStop: Stop;
    let followingStop: Stop;
    let precedingStopToNewPosition$: Observable<{ nav_instructions: NavInstruction[]; waypoints: Waypoint[] }>;
    let coords: Waypoint[];
    let heading: number;
    let newPositionToNextStop$: Observable<{ nav_instructions: NavInstruction[]; waypoints: Waypoint[] }>;
    let precedingStopBest: BestStop;
    let followingStopBest: BestStop;
    let precedingStopData: Stop;
    let followingStopData: Stop;

    const hereOptions = {} as HereOptions;
    hereOptions['avoidUturns'] = markerPositionOptions.avoidUturns;

    switch (markerPositionOptions.markerType) {
      case 'start':
        followingStop = route.stopData[markerPositionOptions.positionIndex + 1];

        hereOptions['intermediateWaypoints'] = markerPositionOptions.followingIntermediateWaypoints;

        return (newPositionToNextStop$ = this.hereService.getHereRoute(
          `${markerPositionOptions.newPosition.lat},${markerPositionOptions.newPosition.lng}`,
          `${followingStop.lat},${followingStop.lon}`,
          hereOptions
        )).pipe(
          map(r => {
            route.stopData[0].lat = markerPositionOptions.newPosition.lat;
            route.stopData[0].lon = markerPositionOptions.newPosition.lng;
            route.stopData[0].traces = [
              {
                source_name: 'Manually Edited Stop',
                source_type: 'Manually Edited Stop',
                waypoints: [r.waypoints[0]],
              },
            ];

            followingStopData = route.stopData[1];
            followingStopData.notes = {
              best_source_name: 'Manually Edited Stop',
              best_source_type: 'Manually Edited Stop',
            };
            followingStopData.traces = [
              {
                name: followingStopData.name,
                nav_instructions: r.nav_instructions,
                source_name: 'Manually Edited Stop',
                source_type: 'Manually Edited Stop',
                waypoints: r.waypoints,
                travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
              },
            ];
            route.best[0].lat = markerPositionOptions.newPosition.lat;
            route.best[0].lon = markerPositionOptions.newPosition.lng;
            route.best[1] = {
              name: followingStopData.name,
              lat: route.stopData[1].lat,
              lon: route.stopData[1].lon,
              waypoints: r.waypoints,
              nav_instructions: r.nav_instructions,
              travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
              travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
              source_name: 'Manually Edited Stop',
              source_type: 'Manually Edited Stop',
            };
            updateTotalDist(route);
            updateTotalTime(route);
            return route;
          })
        );
      case 'stop':
        precedingStop = route.stopData[markerPositionOptions.positionIndex - 1];
        followingStop = route.stopData[markerPositionOptions.positionIndex + 1];

        precedingStopToNewPosition$ = this.hereService.getHereRoute(
          `${precedingStop.lat},${precedingStop.lon}`,
          `${markerPositionOptions.newPosition.lat},${markerPositionOptions.newPosition.lng}`,
          {
            avoidUturns: markerPositionOptions.avoidUturns,
            intermediateWaypoints: markerPositionOptions.precedingIntermediateWaypoints,
          }
        );

        return precedingStopToNewPosition$.pipe(
          // This is a bit tricky because we need to use the waypoints from the preceding Stop the new Stop to calculate the heading
          // from the new Stop to the following Stop
          mergeMap(precedingStopToNewStopResp => {
            coords = precedingStopToNewStopResp.waypoints.slice(-2);
            heading = Math.trunc(
              getRhumbLineBearing({ lon: coords[0].lon, lat: coords[0].lat }, { lon: coords[1].lon, lat: coords[1].lat })
            );
            newPositionToNextStop$ = this.hereService.getHereRoute(
              `${markerPositionOptions.newPosition.lat},${markerPositionOptions.newPosition.lng}`,
              `${followingStop.lat},${followingStop.lon}`,
              {
                heading,
                avoidUturns: markerPositionOptions.avoidUturns,
                intermediateWaypoints: markerPositionOptions.followingIntermediateWaypoints,
              }
            );
            return combineLatest([of(precedingStopToNewStopResp), newPositionToNextStop$]);
          }),
          map(r => {
            // The 'best' objects are formatted into the currently accepted frontend post-processing structure
            // and are used to draw the polylines on the map
            precedingStopBest = r[0] as BestStop;
            followingStopBest = r[1] as BestStop;
            precedingStopBest['name'] = route.stopData[markerPositionOptions.positionIndex].name;
            followingStopBest['name'] = route.stopData[markerPositionOptions.positionIndex + 1].name;
            precedingStopBest['lat'] = markerPositionOptions.newPosition.lat;
            precedingStopBest['lon'] = markerPositionOptions.newPosition.lng;
            followingStopBest['lat'] = followingStop.lat;
            followingStopBest['lon'] = followingStop.lon;

            precedingStopData = {
              lat: markerPositionOptions.newPosition.lat,
              lon: markerPositionOptions.newPosition.lng,
              name: precedingStopBest['name'],
              notes: {
                best_source_name: 'Manually Edited Stop',
                best_source_type: 'Manually Edited Stop',
              },
              traces: [
                {
                  name: precedingStopBest['name'],
                  nav_instructions: r[0].nav_instructions,
                  source_name: 'Manually Edited Stop',
                  source_type: 'Manually Edited Stop',
                  waypoints: r[0].waypoints,
                  travel_dist: r[0].nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                  travel_time: r[0].nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
                },
              ],
            };
            followingStopData = {
              lat: followingStop.lat,
              lon: followingStop.lon,
              name: followingStopBest['name'],
              notes: {
                best_source_name: 'Manually Edited Stop',
                best_source_type: 'Manually Edited Stop',
              },
              traces: [
                {
                  name: followingStopBest['name'],
                  nav_instructions: r[1].nav_instructions,
                  source_name: 'Manually Edited Stop',
                  source_type: 'Manually Edited Stop',
                  waypoints: r[1].waypoints,
                  travel_dist: r[1].nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                  travel_time: r[1].nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
                },
              ],
            };
            // the 'best' is created during the front-end route processing, and is ephemeral
            route.best.splice(markerPositionOptions.positionIndex, 1, precedingStopBest);
            route.best.splice(markerPositionOptions.positionIndex + 1, 1, followingStopBest);
            // whereas the stopData property is returned for each route from the API. It is used in front-end route
            // processing to create the 'best' property
            route.stopData.splice(markerPositionOptions.positionIndex, 1, precedingStopData);
            route.stopData.splice(markerPositionOptions.positionIndex + 1, 1, followingStopData);
            updateTotalDist(route);
            updateTotalTime(route);
            return route;
          })
        );
      case 'waypoint':
        // We need some error handling for moving waypoints, because HERE will occasionally throw an error if the requested path is unreachable
        precedingStop = route.stopData[markerPositionOptions.positionIndex - 1];
        followingStop = route.stopData[markerPositionOptions.positionIndex];

        return this.hereService
          .getHereRoute(`${precedingStop.lat},${precedingStop.lon}`, `${followingStop.lat},${followingStop.lon}`, {
            intermediateWaypoints: markerPositionOptions.precedingIntermediateWaypoints,
            avoidUturns: markerPositionOptions.avoidUturns,
          })
          .pipe(
            map(r => {
              precedingStopData = route.stopData[markerPositionOptions.positionIndex];
              precedingStopData.notes = {
                best_source_name: 'Manually Edited Stop',
                best_source_type: 'Manually Edited Stop',
              };
              precedingStopData.traces = [
                {
                  name: precedingStopData.name,
                  nav_instructions: r.nav_instructions,
                  source_name: 'Manually Edited Stop',
                  source_type: 'Manually Edited Stop',
                  waypoints: r.waypoints,
                  travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                  travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
                },
              ];
              route.best[markerPositionOptions.positionIndex] = {
                name: precedingStopData.name,
                lat: precedingStopData.lat,
                lon: precedingStopData.lon,
                waypoints: r.waypoints,
                nav_instructions: r.nav_instructions,
                travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
                source_name: 'Manually Edited Stop',
                source_type: 'Manually Edited Stop',
              };
              updateTotalDist(route);
              updateTotalTime(route);
              return route;
            })
          );
      case 'destination':
        precedingStop = route.stopData[markerPositionOptions.positionIndex - 1];

        return (precedingStopToNewPosition$ = this.hereService.getHereRoute(
          `${precedingStop.lat},${precedingStop.lon}`,
          `${markerPositionOptions.newPosition.lat},${markerPositionOptions.newPosition.lng}`,
          { intermediateWaypoints: markerPositionOptions.precedingIntermediateWaypoints }
        )).pipe(
          map(r => {
            route.stopData[markerPositionOptions.positionIndex].lat = markerPositionOptions.newPosition.lat;
            route.stopData[markerPositionOptions.positionIndex].lon = markerPositionOptions.newPosition.lng;

            precedingStopData = route.stopData[markerPositionOptions.positionIndex];
            precedingStopData.notes = {
              best_source_name: 'Manually Edited Stop',
              best_source_type: 'Manually Edited Stop',
            };
            precedingStopData.traces = [
              {
                name: precedingStopData.name,
                nav_instructions: r.nav_instructions,
                source_name: 'Manually Edited Stop',
                source_type: 'Manually Edited Stop',
                waypoints: r.waypoints,
                travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
                travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
              },
            ];

            route.best[markerPositionOptions.positionIndex] = {
              name: precedingStopData.name,
              lat: precedingStopData.lat,
              lon: precedingStopData.lon,
              waypoints: r.waypoints,
              nav_instructions: r.nav_instructions,
              travel_dist: r.nav_instructions.map((ni: NavInstruction) => ni.length).reduce((a, b) => a + b, 0),
              travel_time: r.nav_instructions.map((ni: NavInstruction) => ni.travelTime).reduce((a, b) => a + b, 0),
              source_name: 'Manually Edited Stop',
              source_type: 'Manually Edited Stop',
            };
            updateTotalDist(route);
            updateTotalTime(route);
            return route;
          })
        );
    }
  }

  public deleteStop(route: EnrichedRouteDetail, deleteStopOptions: DeleteStopOptions): Observable<EnrichedRouteDetail> {
    // Remove the deleted stop from both applicable arrays
    route.best.splice(deleteStopOptions.positionIndex, 1);
    route.stopData.splice(deleteStopOptions.positionIndex, 1);
    // If the user is deleting the final stop, we don't need to do any route calculation updates, just remove the last index
    if (deleteStopOptions.positionIndex === route.stopData.length) {
      return of(route);
    }
    const precedingStop = route.stopData[deleteStopOptions.positionIndex - 1];
    const followingStop = route.stopData[deleteStopOptions.positionIndex];
    const precedingStopToNextStop$: Observable<{ nav_instructions; waypoints }> = this.hereService.getHereRoute(
      `${precedingStop.lat},${precedingStop.lon}`,
      `${followingStop.lat},${followingStop.lon}`,
      { intermediateWaypoints: deleteStopOptions.intermediateWaypoints }
    );

    return precedingStopToNextStop$.pipe(
      map(updatedStopData => {
        // Add the updated values for saving
        route.stopData[deleteStopOptions.positionIndex]['notes'] = {
          best_source_name: 'Manually Edited Stop',
          best_source_type: 'Manually Edited Stop',
          traces: { 'Manually Edited Stop': Date.now().toString() },
        };
        route.stopData[deleteStopOptions.positionIndex]['traces'] = [
          {
            nav_instructions: updatedStopData.nav_instructions,
            source_name: 'Manually Edited Stop',
            source_type: 'Manually Edited Stop',
            waypoints: updatedStopData.waypoints,
            travel_dist: updatedStopData.nav_instructions
              .map((ni: NavInstruction) => ni.length)
              .reduce((a: number, b: number) => a + b, 0),
            travel_time: updatedStopData.nav_instructions
              .map((ni: NavInstruction) => ni.travelTime)
              .reduce((a: number, b: number) => a + b, 0),
          },
        ];
        // Update the editable route best values for frontend visibility
        route.best[deleteStopOptions.positionIndex].nav_instructions = updatedStopData.nav_instructions;
        route.best[deleteStopOptions.positionIndex].waypoints = updatedStopData.waypoints;
        // Finally we have to update our number of stops
        route.nStops--;
        updateTotalDist(route);
        updateTotalTime(route);
        return route;
      })
    );
  }

  public saveRouteEdits(route: EnrichedRouteDetail): Observable<any> {
    // Remove frontend-calculated properties to reduce payload size
    const r: RoutePathUpdate = (({ id, name, stopData, nStops, totalDist, totalTime }) => ({
      id,
      name,
      stopData,
      nStops,
      totalDist,
      totalTime,
    }))(route);
    return this.routesService.updateRoute(r);
  }

  public updateRouteStatus(status: 'approved' | 'rejected', route: EnrichedRouteDetail): Observable<any> {
    // This dramatically reduces the size of request payload
    const r: RouteStatusUpdate = {
      id: route.id,
      status,
    };
    if (status === 'rejected') {
      r['rejectReason'] = route.rejectReason;
    }
    return this.routesService.updateRoute(r);
  }

  public deleteRoute(routeId: number) {
    return this.routesService.deleteRoute(routeId);
  }
}
export function updateTotalDist(route: EnrichedRouteDetail) {
  route.totalDist = route.best
    .map(x => x.travel_dist)
    .filter(Boolean)
    .reduce((a, b) => a + b, 0);
}

export function updateTotalTime(route: EnrichedRouteDetail) {
  route.totalTime = route.best
    .map(x => x.travel_time)
    .filter(Boolean)
    .reduce((a, b) => a + b, 0);
}
