import { BehaviorSubject, throwError } from 'rxjs';
import { LatLng } from 'leaflet';

import { EditedWaypoint } from '@app/shared/models/app_interfaces';
import { Data } from '@angular/router';

/**
 * Waypoint manager.
 *
 * This class abstracts away all the logic for adding, updating and removing segment waypoints. */
export class WaypointManager {
  /**
   * Holds the index of the last modified segment. */
  public lastSegmentModified: number = undefined;

  /**
   * Holds a list of waypoints separated
   * by the Stop index they belong to. */
  private segments: dict = {};

  private onChange$: BehaviorSubject<EditedWaypoint[]>;

  private map: Data;

  /**
   * Waypoint Manager
   *
   * @param onChange$ A BehaviorSubject to publish changes to */

  constructor(onChange$: BehaviorSubject<EditedWaypoint[]>, mapService: Data) {
    this.onChange$ = onChange$;
    this.map = mapService;
  }

  /**
   * Waypoints collection */
  public get waypoints() {
    return this.generateWaypoints();
  }

  /**
   * Store waypoint
   *
   * @param waypoint The waypoint to store */
  public storeWaypoint = (waypoint: EditedWaypoint): EditedWaypoint[] => {
    const { associatedSegmentIndex: segmentIndex } = waypoint;

    if (!this.segments[segmentIndex]) {
      this.segments[segmentIndex] = new Map<string, Data>();
    }

    this.segments[segmentIndex].set(waypoint.uniqueId, {
      order: 0,
      waypoint,
    });
    this.lastSegmentModified = segmentIndex;

    //console.log(`Added waypoint ${waypoint.uniqueId}`, waypoint);

    // sort
    this.sortWaypoints(segmentIndex);

    // publish
    const waypoints = this.generateWaypoints();
    this.onChange$.next(waypoints);

    return this.getSegmentWaypoints(segmentIndex) as EditedWaypoint[];
  };

  /**
   * Update waypoint at specified segment.
   *
   * @param segmentIndex index of the segment owning the waypoint
   * @param uniqueId uuidv4-like unique id
   * @param newPosition  latitude + longitude pair of the new position */
  public updateWaypoint = (
    segmentIndex: number = this.lastSegmentModified,
    uniqueId: string,
    newPosition: LatLng
  ): EditedWaypoint[] => {
    const { order, waypoint } = this.segments[segmentIndex || this.lastSegmentModified].get(uniqueId);

    if (!waypoint) {
      throwError(`The specified uniqueID: ${uniqueId} is not associated to segment(${segmentIndex}); `);
    }

    // update
    waypoint.wpPosition = newPosition;

    if (!this.segments[segmentIndex]) {
      this.segments[segmentIndex] = new Map<string, Data>();
    }

    this.segments[segmentIndex].set(uniqueId, { order, waypoint });
    this.lastSegmentModified = segmentIndex;

    // sort
    this.sortWaypoints(segmentIndex);

    // publish
    const waypoints = this.generateWaypoints();
    this.onChange$.next(waypoints);

    return this.getSegmentWaypoints(segmentIndex) as EditedWaypoint[];
  };

  /**
   * Remove waypoint from the specified segment.
   *
   * @param segmentIndex index of the segment owning the waypoint
   * @param uniqueId waypoint's uuidv4-like unique id */
  public removeWaypoint = (segmentIndex: number = this.lastSegmentModified, uniqueId: string): EditedWaypoint[] => {
    // remove
    this.segments[segmentIndex].delete(uniqueId);
    this.lastSegmentModified = segmentIndex;

    // publish
    const waypoints = this.generateWaypoints();
    this.onChange$.next(waypoints);

    return this.getSegmentWaypoints(segmentIndex) as EditedWaypoint[];
  };

  /**
   * Remove all waypoints from segment.
   *
   * @param segmentIndex index of the segment whose waypoints will be removed */
  public removeAllFromSegment = (segmentIndex: number = this.lastSegmentModified): EditedWaypoint[] => {
    if (!this.segments[segmentIndex]) {
      return [];
    }

    const removedWaypoints = Array.from(this.segments[segmentIndex].values());

    // create an empty Map
    this.segments[segmentIndex] = new Map<string, Data>();
    this.lastSegmentModified = segmentIndex;

    // publish
    const waypoints = this.generateWaypoints();
    this.onChange$.next(waypoints);

    return removedWaypoints.map(waypointWrapper => waypointWrapper.waypoint) as EditedWaypoint[];
  };

  public moveWaypointsToSegment = (srcSegmentIndex: number, dstSegmentIndex): void => {
    if (!this.segments[srcSegmentIndex]) {
      return;
    }

    const originSegmentWaypoints = Array.from(this.segments[srcSegmentIndex].values());
    const destSegmentWaypoints: [string, Data][] = Array.from(this.segments[dstSegmentIndex].values()).map(
      (waypointWrapper: Data): [string, Data] => [waypointWrapper.uniqueId, waypointWrapper]
    );

    // concat both arrays in a Map-like form so they can be converted back to a Map
    const combinedSegmentWaypoints: [string, Data][] = originSegmentWaypoints.map(waypointWrapper => {
      waypointWrapper.waypoint.associatedSegmentIndex = dstSegmentIndex;

      return [waypointWrapper.uniqueId, waypointWrapper];
    });

    // update
    this.segments[dstSegmentIndex] = new Map<string, Data>([...destSegmentWaypoints, ...combinedSegmentWaypoints]);
    this.segments[srcSegmentIndex] = new Map<string, Data>();
    this.lastSegmentModified = dstSegmentIndex;

    // sort
    this.sortWaypoints(dstSegmentIndex);

    // publish
    const waypoints = this.generateWaypoints();
    this.onChange$.next(waypoints);
  };

  /**
   * Get segment waypoints
   *
   * @param sgmentIndex The index of the segment from which waypoints will be retrieved
   * @returns A list of waypoints associated with the specified segment */
  public getSegmentWaypoints = (sgmentIndex: number = this.lastSegmentModified): Data[] =>
    this.segments[sgmentIndex]
      ? Array.from(this.segments[sgmentIndex].values()).map(waypointWrapper => waypointWrapper.waypoint)
      : [];

  /**
   * Reset to initial values */
  public reset = (): void => {
    this.lastSegmentModified = undefined;
    this.segments = {};
    this.onChange$.next([]);
  };

  /**
   * Generate waypoints
   *
   * @returns A a list that includes all (edited) waypoints for all segments */
  private generateWaypoints = (): EditedWaypoint[] =>
    Object.entries(this.segments).reduce((allWaypoints, [associatedSegmentIndex, segmentWaypointsMap]) => {
      const segmentWaypoints = Array.from(segmentWaypointsMap.values()).map((waypointWrapper: Data) => ({
        ...waypointWrapper.waypoint,
        associatedSegmentIndex,
      }));

      return [...allWaypoints, ...segmentWaypoints] as EditedWaypoint[];
    }, []);

  private sortWaypoints = (segmentIndex: number): void => {
    /**
     * do not die if this is last stop.
     *
     * Our indexes are one-off because we are skipping Stop #0,
     * in some parts of the codebase we need to account for that one-off.
     *
     * Here's one of such parts. This guards the App from crashing while
     * trying to access polyline references for the last stop, since last stop
     * does not have a segment (polyline). */
    const safeSegmentIndex = segmentIndex === this.map.stopSegmentPolylineReferences.length ? segmentIndex - 1 : segmentIndex;

    // Leafleet lib  equality threshold
    const LF_EQ_THRESHOLD = 0.5;

    // get segment polyline and its lat-lng pairs
    const polyLine = this.map.stopSegmentPolylineReferences[safeSegmentIndex];
    const points = polyLine.getLatLngs();

    // we'll also need the segment waypoints
    const unsortedWaypoints = this.removeAllFromSegment(segmentIndex);

    // traverse points and assign waypoints based on equality
    points
      .reduce((sorted, point) => {
        const nearestWaypointIndex = unsortedWaypoints.findIndex(waypoint =>
          // console.log(
          //     `comparing ${waypoint.wpPosition.lat} - ${waypoint.wpPosition.lng} >>> ${point.lat} - ${point.lng}`
          // );

          point.equals(waypoint.wpPosition, LF_EQ_THRESHOLD)
        );
        if (nearestWaypointIndex === -1) {
          return sorted;
        }

        // remove waypoint from list
        const [nearestWaypoint] = unsortedWaypoints.splice(nearestWaypointIndex, 1);
        // console.log(
        //     `waypoint ${nearestWaypoint.wpPosition.lat} - ${nearestWaypoint.wpPosition.lng} equals ${point.lat} - ${point.lng}`
        // );

        // add it to sorted list
        sorted.push({
          order: sorted.length - 1,
          waypoint: nearestWaypoint,
        });

        return sorted;
      }, [])
      .forEach(({ waypoint, order }) => {
        this.segments[segmentIndex].set(waypoint.uniqueId, { order, waypoint });
      });

    // mark segment as last edited
    this.lastSegmentModified = segmentIndex;
  };
}

export interface dict {
  [key: number]: Map<string, Data>;
}
