<div class="snackbar-content snackbar" *ngIf="status === 'partial'">
  <div class="icon-container">
    <mat-icon class="alert-icon">warning</mat-icon>
  </div>
  <div class="message-container">
    <span>{{ message[status] }}</span>
  </div>
  <a class="snack-link snack-link-partial" (click)="viewRoutes()">View routes</a>
  <a class="snack-link" (click)="viewErrors()">View errors</a>
  <button mat-icon-button (click)="dismissSnackbar()"><mat-icon>close</mat-icon></button>
</div>

<div class="snackbar-content-error snackbar" *ngIf="status === 'failure'">
  <div class="icon-container-error">
    <mat-icon class="alert-icon">error</mat-icon>
  </div>
  <div class="message-container">
    <span>{{ message[status] }}</span>
  </div>
  <a class="snack-link" (click)="viewErrors()">View errors</a>
  <button mat-icon-button (click)="dismissSnackbar()"><mat-icon>close</mat-icon></button>
</div>

<div class="snackbar-content-success snackbar" *ngIf="status === 'success'">
  <div class="icon-container-success">
    <mat-icon class="alert-icon">error</mat-icon>
  </div>
  <div class="message-container">
    <span>{{ message[status] }}</span>
  </div>
  <a class="snack-link" (click)="viewRoutes()">View results</a>
  <button mat-icon-button (click)="dismissSnackbar()"><mat-icon>close</mat-icon></button>
</div>
