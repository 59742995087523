import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SocketService } from '@app/services/socket.service';
import { environment as env } from '@environments/environment';
import { BehaviorSubject, Subject, catchError, retry } from 'rxjs';
import { CSVImportError, CSVImportBatches } from '@app/shared/models/app_interfaces';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ImportRoutesService {
  constructor(private apiService: ApiService, private socketService: SocketService, private userService: UserService) {}

  private socket$ = null;
  public routeProcessingStatusUpdates$ = new Subject<unknown>();
  public routeIDsWaitingForProcessingResult: number[] = [];
  public importErrors: CSVImportError[] = [];
  public importedBatches: CSVImportBatches = {};
  public importStatus: 'success' | 'partial' | 'failure' | 'pending';
  public importCount$ = new BehaviorSubject<number>(0);
  public uploadingComplete: boolean;
  public processingComplete: boolean;
  public lastImportJobCount: number;

  importRoute(route) {
    const importRouteUrl = `${env.onRouteApi.baseUrl}/v1/routes/import?account_code=${this.userService.user.accountCode}`;
    return this.apiService.post(importRouteUrl, route, undefined, true).pipe(
      retry({ delay: 1000, count: 3 }),
      catchError(() => {
        throw new Error(route.name);
      })
    );
  }

  openWebsocket() {
    if (this.socket$ != null) {
      return;
    }
    this.socket$ = this.socketService.createSocket('/v1/import/ws');
    this.socketService.authenticate(this.socket$);
    this.socket$.subscribe({
      next: msg => {
        if (msg.response_type === 'auth') {
          // console.log(`Auth success = ${msg.success}`);
        } else if (msg.response_type === 'monitor') {
          this.routeProcessingStatusUpdates$.next(msg.data);
        } else if (msg.response_type === 'unknown_type') {
          // console.log(`Unknown message`, msg);
        } else if (msg.response_type === 'final') {
          // console.log(`Websocket closed remotely`, msg);
        } else {
          // console.log(`Error ?`, msg);
        }
      }, // Called whenever there is a message from the server.
      error: err => {
        // console.log(err), // Called if at any point WebSocket API signals some kind of error.
      },
      complete: () => {
        // console.log('complete'); // Called when connection is closed (for whatever reason).
        this.socket$ = null;
      },
    });
  }

  closeWebsocket() {
    if (this.socket$ != null) {
      this.socket$.unsubscribe();
      this.socket$ = null;
    }
  }

  monitorRoutes(routeIds: Array<number>): boolean {
    if (this.socket$ == null) {
      return false;
    }
    this.socket$.next({
      request_type: 'monitor',
      data: {
        ids: routeIds,
      },
    });
    return true;
  }
}
