import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Route, RouteDetail } from '@app/shared/models/api_interfaces';
import { EnrichedRouteDetail, RoutePathUpdate, RouteStatusUpdate } from '@app/shared/models/app_interfaces';
import { map, switchMap } from 'rxjs/operators';
import { environment as env } from '@environments/environment';
import { UtilitiesService } from './utilities.service';
import { RecordedRoute } from '@app/features/route-creation/rc_interfaces';
import { NewRoute } from '@app/features/route-creation/route-creation.service';
import { HttpParams } from '@angular/common/http';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  public accountId$ = new BehaviorSubject(undefined);
  public recordedRoutes$ = new BehaviorSubject<Route[]>([]);
  public displayedRoute$ = new BehaviorSubject<EnrichedRouteDetail | NewRoute | RouteDetail>(undefined);
  public statusFilter$ = new Subject<string>();
  public totalRouteDistance = new BehaviorSubject(0);
  public totalRouteTime = new BehaviorSubject(0);
  public stashedRoute: EnrichedRouteDetail;
  public routeFilterByIds$ = new BehaviorSubject(undefined);

  constructor(private apiService: ApiService, public utilitiesService: UtilitiesService) {}

  public setStatusFilter(status: string): void {
    this.statusFilter$.next(status);
  }

  public getRoutes(): void {
    const routesUrl = `${env.onRouteApi.baseUrl}/v1/routes/`;
    const statusParams = new HttpParams().set('status', 'pending,approved,rejected');
    this.apiService.get(routesUrl, statusParams).subscribe((routes: Route[]) => {
      this.recordedRoutes$.next(routes['routes']);
      this.accountId$.next(routes['routes'][0]?.accountId);
      this.utilitiesService.routesLoading$.next(false);
    });
  }

  public unsetDisplayedRoute() {
    this.stashedRoute = this.displayedRoute$.getValue() as EnrichedRouteDetail;
    this.displayedRoute$.next(undefined);
  }

  public resetDisplayedRoute() {
    this.displayedRoute$.next(this.stashedRoute);
    this.stashedRoute = undefined;
  }

  public setDisplayedRouteById(routeId: number): void {
    // This updates the displayed route.
    this.getRouteDetails(routeId).subscribe((routeDetail: RouteDetail) => {
      this.displayedRoute$.next(assembleRoute(routeDetail));
      this.utilitiesService.routeLoading$.next(false);
    });
  }

  public setDisplayedRouteByRoute(route: EnrichedRouteDetail) {
    this.displayedRoute$.next(route);
  }

  public getPendingRoutesCount(): Observable<number> {
    return this.recordedRoutes$.pipe(map((routes: Route[]) => routes.filter(route => route.status === 'pending').length));
  }

  public updateRoute(route: RoutePathUpdate | RouteStatusUpdate): Observable<any> {
    const routesUrl = `${env.onRouteApi.baseUrl}/v1/routes`;
    return this.apiService.put(routesUrl, route).pipe(switchMap(() => this.refreshRoutes(route.id)));
  }

  public async refreshRoutes(updatedRouteId: number | undefined = undefined): Promise<void> {
    this.getRoutes();
    if (updatedRouteId !== undefined) {
      this.setDisplayedRouteById(updatedRouteId);
    }
  }

  public removeRoutesFromRouteList(routes: Array<Route>) {
    const idsToRemove = routes.map(r => _.get(r, 'id'));
    const routesList = this.recordedRoutes$.getValue();
    _.remove(routesList, route => idsToRemove.includes(route.id));
    this.recordedRoutes$.next(routesList);
  }

  public postRoute(route: RecordedRoute): Observable<RouteDetail> {
    const routesUrl = `${env.onRouteApi.baseUrl}/v1/routes/`;

    return this.apiService.post(routesUrl, route);
  }

  public cloneRoute(routeId: number): Observable<number> {
    const routesUrl = `${env.onRouteApi.baseUrl}/v1/routes/${routeId}/clone`;
    return this.apiService.post(routesUrl);
  }

  public getRouteDetails(routeId: number): Observable<RouteDetail> {
    const routeDetailsUrl = `${env.onRouteApi.baseUrl}/v1/routes/${routeId}`;
    return this.apiService.get(routeDetailsUrl);
  }

  public deleteRoute(routeId: number): Observable<any> {
    const routeDeleteUrl = `${env.onRouteApi.baseUrl}/v1/routes/${routeId}`;
    return this.apiService.delete(routeDeleteUrl);
  }

  public filterRouteListByIds(routeIds: number[] | undefined) {
    this.routeFilterByIds$.next(routeIds);
  }
}

export function assembleRoute(route: RouteDetail | NewRoute): EnrichedRouteDetail | RouteDetail | NewRoute {
  // console.log(`ASSEMBLE ROUTE --${route.name}--`);
  // console.log('-route', route);
  // console.log('-stops', route['stopData']);

  const stops = route['stopData'];

  if (!stops) {
    // console.log(`-no stops ${route.name}`);
    return route;
  }
  // console.log('-stop count', stops.length);

  let index = 0;
  let traceindex = 0;
  let traces;
  let bsn = '';
  let bst = '';
  const sortedTraces = [];
  const bestTraceOnly = [];
  const stopsOnly = {};
  let stopZero = {};

  while (index < stops.length) {
    // Stops
    // console.log(`-stop ${index}`, stops[index]);

    stopsOnly[index] = {};
    Object.assign(stopsOnly[index], { lat: stops[index].lat, long: stops[index].lon });

    if (index > 0 && stops[index].notes && stops[index].notes.hasOwnProperty('best_source_name')) {
      // console.log(`-stop ${index} notes`, stops[index].notes);
      // console.log(`-stop ${index} BSN`, stops[index]['notes']['best_source_name']);
      bsn = stops[index]['notes']['best_source_name'];
      // console.log(`-stop ${index} BST`, stops[index].notes['best_source_type']);
      bst = stops[index]['notes']['best_source_type'];
    } else {
      // console.log(`-stop ${index} Best Trace is`, stops[index]);
      stopZero = stops[index];
      bestTraceOnly[0] = {};
    }
    // If an expected 'notes' property is missing on a stop, use whatever source available which has the most waypoints
    if (!stops[index].notes && index > 0) {
      const estimatedBestSource = stops[index].traces.reduce((a, b) => (a.waypoints.length > b.waypoints.length ? a : b));
      bsn = estimatedBestSource.source_name;
      bst = estimatedBestSource.source_type;
    }

    traces = stops[index].traces;

    if (traces) {
      traceindex = 0;
      // console.log(`-stop ${index} traces`);

      while (traceindex < traces.length) {
        // console.log(`-trace ${traceindex} of stop ${index} is`, traces[traceindex]);

        if (traces[traceindex]['source_name'] === bsn && traces[traceindex]['source_type'] === bst) {
          bestTraceOnly[index] = traces[traceindex];
          bestTraceOnly[index]['name'] = stops[index].name ? stops[index].name : '';
          bestTraceOnly[index]['lat'] = stops[index].lat;
          bestTraceOnly[index]['lon'] = stops[index].lon;

          // console.log(`-of the traces, trace # ${traceindex} is deemed to be the best`);
        }
        if (index !== 0) {
          // console.log(`-assigning traces to sortedTraces[${traces[traceindex]['source_name']}][${index}]`);
          if (!sortedTraces[traces[traceindex]['source_name']]) {
            sortedTraces[traces[traceindex]['source_name']] = [];
          }
          traces[traceindex].associatedStopIndex = index;
          sortedTraces[traces[traceindex]['source_name']].push(traces[traceindex]);
        } else {
          sortedTraces[traces[traceindex]['source_name']] = [];
        }
        traceindex++;
      }
    }
    // Traces

    index++;
  }
  // Go back and assign 0 traces
  index = 0;
  while (index < sortedTraces.length) {
    Object.assign(sortedTraces[index][0], stopZero);
  }
  // console.log(bestTraceOnly, stopZero);
  // console.log(stopZero);
  Object.assign(bestTraceOnly[0], stopZero);

  // console.log(`-stopsonly`, stopsOnly);
  // console.log(`-sorted traces`, sortedTraces);

  const finalRoute = _.assign(
    new EnrichedRouteDetail(),
    route,
    { best: bestTraceOnly },
    { sorted: Object.entries(sortedTraces) },
    { best_source_type: bst },
    { best_source_name: bsn },
    { nStops: bestTraceOnly.length }
  );

  // console.log(`-final product`, finalRoute);
  // console.log(`END ASSEMBLE ROUTE --${route.name}--`);
  return finalRoute;
}
