import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { environment as env } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HereCalcRouteResponse,
  HereGeoCodePosition,
  HereGeocodeResponse,
  HereRevGeodeResponse,
  HereWaypoint,
  Leg,
  Maneuver,
} from '@app/shared/models/here_interfaces';
import { HereV8RouteResponse } from '@app/typings/here/interfaces/v8-route.response.interface';
import { HereOptions, InstructionManeuver } from '@app/shared/models/app_interfaces';
import getCenter from 'geolib/es/getCenter';
import { decode } from '@app/shared/utils/flexpolyline';
import { DecodedFlexPolyline } from '@app/typings/here/interfaces/decodedFlexPolyline.interface';
import { HttpParams } from '@angular/common/http';
import { FeatureFlagService } from './feature-flag.service';
import _ from 'lodash-es';
import { TargetPosition, USBounds } from '@app/features/route-creation/rc_interfaces';
import { latLng } from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class HereService {
  constructor(private apiService: ApiService, private featureFlagService: FeatureFlagService) {}

  getHereRoute(origin: string, destination: string, hereOptions?: HereOptions): Observable<{ nav_instructions; waypoints }> {
    if (this.featureFlagService.hereV8Enabled) {
      return this.getHereV8Route(origin, destination, hereOptions);
    } else {
      return this.getHereCalculatedRoute(origin, destination, hereOptions);
    }
  }

  getHereV8Route(origin: string, destination: string, hereOptions?: HereOptions): Observable<{ nav_instructions; waypoints }> {
    const url = env.hereApi.v8RoutesUrl;

    // this should probably be done in EditService, when the options are defined. However, because of differences between the two
    // HERE route endpoints, it needs to be managed within the v8-specific route request method.
    // Once we remove the Fleet/V8 Feature Flag, we can refactor this a bit.
    if (hereOptions?.avoidUturns == false && hereOptions?.heading) {
      hereOptions['heading'] = (hereOptions['heading'] + 180) % 360;
    }

    let params = new HttpParams({
      fromObject: {
        apiKey: env.hereApi.apiKey,
        transportMode: 'car', // look into other transport modes available
        origin: hereOptions?.heading ? `${origin};course=${hereOptions.heading}` : origin,
        destination,
        routingMode: 'fast',
        return: 'polyline,actions,instructions,summary',
      },
    });

    if (hereOptions?.intermediateWaypoints) {
      for (const [index, element] of hereOptions.intermediateWaypoints.entries()) {
        let viaValue = `${element.lat},${element.lng}!passThrough=true`;
        params = params.append('via', viaValue);
      }
    }

    return this.apiService.get(url, params).pipe(
      map((resp: HereV8RouteResponse) => {
        const waypoints = this.extractWaypointsFromV8(resp);
        const nav_instructions = this.extractNavInstructionsFromV8(resp);
        return {
          nav_instructions,
          waypoints,
        };
      })
    );
  }

  // Replaced by getHereV8Route. Code left in until v8 migration is complete
  getHereCalculatedRoute(
    origin: string,
    destination: string,
    hereOptions?: HereOptions
  ): Observable<{ nav_instructions; waypoints }> {
    // Comments are all derived from testing the HERE fleet telematics API locally. Their documentation for this
    // specific API is broken in the web (https://developer.here.com/documentation/fleet-telematics/api-reference.html),
    // but is available for Postman import via a Swagger definition here:
    // https://developer.here.com/documentation/fleet-telematics/dev_guide/topics/swagger_openapi.html
    //
    const url = env.hereApi.calcRouteUrl;

    let params = new HttpParams({
      fromObject: {
        apiKey: env.hereApi.apiKey,
        mode: 'fastest;car;traffic:enabled',
        instructionFormat: 'text',
        legAttributes: 'maneuvers',
        representation: 'navigation',
        maneuverAttributes: 'position,travelTime,action,roadName,nextRoadName,nextManeuver',
        // Comma separated list of elements like left[;minAngleDegree;penaltySec] or right... or uTurn[;penaltySec]
        // if a left/right turn is sharper than the specified angle (0...180) then the given time penalty is applied.
        // uTurnAtWaypoint avoids u-turning on the link directly where the waypoint was reached.
        // origin and destination must be comma separated latitude, longitude in WGS-84 degree eg '47.623,-117.390'
        // The waypointN coordinates may be directly followed by ;transitRadius;label;heading[;optional specifications].
        // Set a heading (degree clockwise from North) to improve map matching (Example: latitude,longitude;;;140).
        waypoint0: hereOptions?.heading ? `${origin};;;${hereOptions.heading}` : origin,
        // ...(!hereOptions?.intermediateWaypoints && { waypoint1: destination }),
      },
    });

    if (hereOptions?.intermediateWaypoints?.length > 0) {
      for (const [index, element] of hereOptions.intermediateWaypoints.entries()) {
        params = params.set(`waypoint${index + 1}`, `${element.lat},${element.lng}`);
      }
      params = params.set(`waypoint${hereOptions.intermediateWaypoints.length + 1}`, destination);
    } else {
      params = params.set('waypoint1', destination);
    }
    if (hereOptions?.avoidUturns) {
      params = params.set('avoidTurns', 'uTurn;180');
    }
    // console.log('_________________________');
    // console.log('HERE request sent with params:');
    // console.table(params);
    return this.apiService.get(url, params).pipe(
      map((r: HereCalcRouteResponse) => {
        const waypoints = this.extractWaypointsFrom(r);
        const nav_instructions = this.extractNavInstructionsFrom(r);
        return {
          nav_instructions,
          waypoints,
        };
      })
    );
  }

  getHereGeocode(address: string): Observable<HereGeoCodePosition> {
    // Return geo-coordinatees from an address input
    // https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics-api/code-geocode-address.html
    const url = env.hereApi.geocodeUrl;
    const params = new HttpParams({
      fromObject: {
        q: address,
        apiKey: env.hereApi.apiKey,
        limit: '1',
        // in the future we can use the 'at' param to center the search around an approximate geo area
      },
    });
    return this.apiService.get(url, params).pipe(map(this.parseHereGeocodeResp));
  }

  getHereRevGeocode(position: HereGeoCodePosition) {
    // This endpoint returns the nearest address to geo coordinates specified in the request.
    // https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html

    const url = env.hereApi.revGeocodeUrl;
    const params = new HttpParams({
      fromObject: {
        at: `${position.lat},${position.lng}`,
        apiKey: env.hereApi.apiKey,
      },
    });

    return this.apiService.get(url, params).pipe(map(this.parseHereRevGeocodeResp));
  }

  validateAddress(partialAddress: string) {
    // Determine if input string is acceptable to pass to HERE Reverse Geocoder API
    // Currently kind of an arbitrary length check right now, but can be built out with additional validation
    return partialAddress.trim().length > 5;
  }

  validatePosition(position: HereGeoCodePosition): boolean {
    // Determine if an input TargetPosition is acceptable to pass into HERE Geocoder API
    return new USBounds().contains(latLng(position.lat, position.lng));
  }

  private parseHereGeocodeResp(resp: HereGeocodeResponse): HereGeoCodePosition {
    return resp.items.length === 0 ? { lat: 0.0, lng: 0.0 } : resp.items[0].position;
  }
  private parseHereRevGeocodeResp(resp: HereRevGeodeResponse): string {
    return _.get(resp, 'items[0].address.label', 'unknown');
  }

  private extractWaypointsFromV8(resp: HereV8RouteResponse): { lat: number; lon: number }[] {
    const pline: DecodedFlexPolyline = decode(resp.routes[0].sections[0].polyline);
    const positionLookupTable = pline.polyline;
    return positionLookupTable.map(wp => {
      return {
        lat: wp[0],
        lon: wp[1],
      };
    });
  }

  // Replaced by extractWaypointsFromV8. Code left in until v8 migration is complete
  private extractWaypointsFrom(resp: HereCalcRouteResponse): { lat: number; lon: number }[] {
    const waypointSummaries: HereWaypoint[] = resp.response.route[0].waypoint;

    return resp.response.route[0].leg
      .map((e: Leg, legIndex: number) => {
        const startingPos = Object.assign({}, waypointSummaries[legIndex].mappedPosition);
        const endingPos = Object.assign({}, waypointSummaries[legIndex + 1].mappedPosition);
        const offset_legLatLons = [];
        const unprocessed_legLatLons = e.link
          .map(ll => ll)
          .flat()
          .map(s => s['shape'])
          .flat();

        // This allows us to create additional waypoints between each leg's intersection lat/lons
        // This benefits the mobile sdk with nav and routing
        for (let ii = 0; ii < unprocessed_legLatLons.length - 3; ii = ii + 2) {
          offset_legLatLons.push(
            getCenter([
              { latitude: unprocessed_legLatLons[ii], longitude: unprocessed_legLatLons[ii + 1] },
              { latitude: unprocessed_legLatLons[ii + 2], longitude: unprocessed_legLatLons[ii + 3] },
            ])
          );
        }
        offset_legLatLons.splice(0, 0, getCenter([startingPos, offset_legLatLons[0]]));
        offset_legLatLons.splice(0, 0, startingPos);
        offset_legLatLons.push(getCenter([endingPos, offset_legLatLons[offset_legLatLons.length - 1]]));
        offset_legLatLons.push(endingPos);
        offset_legLatLons.forEach(el => {
          delete Object.assign(el, { lat: el['latitude'] })['latitude'];
          delete Object.assign(el, { lon: el['longitude'] })['longitude'];
        });

        return offset_legLatLons;
      })
      .flat();
  }

  private extractNavInstructionsFromV8(resp: HereV8RouteResponse): InstructionManeuver[] {
    // we need the decoded polyline for offset lookup
    const pline: DecodedFlexPolyline = decode(resp.routes[0].sections[0].polyline);
    const positionLookupTable = pline.polyline;
    const navActions = resp.routes[0].sections[0].actions;
    const navInstructions = navActions.map((action, i, arr) => {
      const position = positionLookupTable[action.offset];
      const instruction = action.instruction;

      return {
        position: {
          lat: position[0],
          lon: position[1],
        },
        instruction: instruction,
        travelTime: action.duration,
        length: action.length,
        // id is required by API, but probably not used (holdover from calcRoute)
        id: `M${i}`,
      };
    });
    return navInstructions;
  }

  // Replaced by extractNavInstructionsFromV8. Code left in until v8 migration is complete
  private extractNavInstructionsFrom(resp: HereCalcRouteResponse): InstructionManeuver[] {
    const nav_instructions = resp['response']['route'][0]['leg'].map(l => l['maneuver']).flat();
    nav_instructions.forEach((el: Maneuver) => {
      // rename lat/lon
      delete Object.assign(el.position, { lat: el['position']['latitude'] })['latitude'];
      delete Object.assign(el.position, { lon: el['position']['longitude'] })['longitude'];
    });
    // With the addition of multiple waypoint editing, the API response will have "Arrive at..." instructions
    // for each waypoint. We only care about the last one (the next stop), so we'll remove the others
    nav_instructions.forEach((e: Maneuver, i: number, a: Maneuver[]) => {
      if (e.instruction.includes('Arrive at') && i < a.length - 1) {
        a.splice(i, 1);
      }
    });
    return nav_instructions;
  }
}
