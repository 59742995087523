import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { EditManagementService } from '@app/services/edit-management.service';
import { MapService } from '@app/services/map.service';

@Directive({
  selector: '[appHighlightSegment]',
})
export class HighlightSegmentDirective {
  @Input('appHighlightSegment') stopIndex: number;

  constructor(private el: ElementRef, private editManagementService: EditManagementService, private mapService: MapService) {}

  @HostListener('mouseover') onMouseOver() {
    if (
      this.editManagementService.addFeatureMode$.value === true &&
      this.editManagementService.features.isSegmentSelected === false
    ) {
      const segmentIndex = this.stopIndex !== 0 ? this.stopIndex - 1 : 0;
      this.mapService.stopSegmentPolylineReferences[segmentIndex].setStyle({ weight: 7 });
      this.mapService.stopSegmentPolylineReferences[segmentIndex]['_path'].setAttribute(
        'class',
        'route-polyline leaflet-interactive highlight-route-polyline'
      );
    }
  }
  @HostListener('mouseout') OnMouseOut() {
    const segmentIndex = this.stopIndex !== 0 ? this.stopIndex - 1 : 0;
    if (
      this.editManagementService.addFeatureMode$.value === true &&
      this.editManagementService.features.selectedSegmentIndex$.value !== segmentIndex
    ) {
      this.mapService.stopSegmentPolylineReferences[segmentIndex].setStyle({ weight: 5 });
      this.mapService.stopSegmentPolylineReferences[segmentIndex]['_path'].setAttribute(
        'class',
        'route-polyline leaflet-interactive'
      );
    }
  }

  @HostListener('click') onClick() {
    if (
      this.editManagementService.addFeatureMode$.value === true &&
      this.editManagementService.features.isSegmentSelected === false
    ) {
      const segmentIndex = this.stopIndex !== 0 ? this.stopIndex - 1 : 0;
      this.editManagementService.features.selectedSegmentIndex$.next(segmentIndex);
      setTimeout(() => {
        this.editManagementService.features.isSegmentSelected = true;
      }, 50);
    }
  }
}
