<div
  id="route-details-container"
  *ngIf="!routeLoading; else loading"
  [ngStyle]="debugService.debugEnabled && { 'grid-template-columns': '1fr 0fr 2.1fr' }">
  <div id="route-name-container">
    <h2 *ngIf="!editMode" data-cy="route-details-route-name">{{ route.name }}</h2>
    <mat-form-field *ngIf="editMode" [ngStyle]="{ 'width.ch': inputText.value.length, 'min-width.%': 25 }">
      <mat-label>Edit Route Name</mat-label>
      <input
        matInput
        type="text"
        title="Name"
        #inputText
        (keydown)="editManagementService.storeState()"
        [(ngModel)]="editManagementService.editableRoute.name" />
      <button
        mat-button
        *ngIf="editManagementService.editableRoute.name"
        matSuffix
        mat-icon-button
        (click)="editManagementService.editableRoute.name = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div id="route-details-content">
    <p data-cy="route-details-route-driver">
      <span>DRIVER</span><span>{{ route.driverFirstName }} {{ route.driverLastName }}</span>
    </p>
    <p>
      <span>DATE RECORDED</span><span>{{ route.recordedTs | date : 'shortDate' }}</span>
    </p>

    <p>
      <span>TOTAL DISTANCE</span><span>{{ route.totalDist | metersToMiles | number : '0.0-2' }} miles</span>
    </p>

    <p data-cy="route-details-route-stops">
      <span>TOTAL STOPS</span><span>{{ route.nStops - 1 }}</span>
    </p>

    <div *ngIf="debugService.debugEnabled" class="debug-mode-container">
      <span style="font-weight: bold; padding-right: 1rem">DEBUG MODE ENABLED</span>
      <span style="padding-left: 1rem">{{ debugService.pathExportInSeconds | secondsToHumanFriendly }} </span>
      <span *ngIf="debugService?.skippedStopCount" style="padding-left: 0.25rem"
        >| {{ debugService.skippedStopCount }} Skipped Stop Events</span
      >
      <span *ngIf="debugService?.reroutedEventCount" style="padding-left: 0.25rem"
        >| {{ debugService.reroutedEventCount }} Rerouted Events</span
      >
      <span *ngIf="debugService?.arrivedStopCount" style="padding-left: 0.25rem"
        >| {{ debugService.arrivedStopCount }} Arrived at Stop Events</span
      >
      <p>
        <label for="routepathUpload" style="padding-right: 0.5rem"> Upload Path CSV and (optionally) Datadog Logs</label>
        <input
          type="file"
          title="CSV"
          name="routePathUpload"
          #routepathUpload
          accept=".csv"
          multiple
          (change)="debugService.processDebugUploads($event.target.files)" />
        <button *ngIf="debugService.debugPath$ | async" (click)="debugService.clearPath(); routepathUpload.value = null">
          Clear Path
        </button>
      </p>

      <p></p>
    </div>
  </div>
  <div
    class="route-details-status-container"
    *ngIf="!editMode"
    [appBackgroundFromStatus]="route.status"
    [ngSwitch]="route?.status">
    <div *ngSwitchCase="'approved'" class="route-details-status-content" data-cy="route-details-route-status">
      <div class="route-details-status-icon-container">
        <mat-icon [ngStyle]="{ color: 'rgb(63,126,47)' }">check_circle</mat-icon>
      </div>
      <div class="route-details-status-text-container">
        <p>APPROVED - {{ route.lastupdatedTs | date : 'short' }}</p>
      </div>
    </div>

    <div *ngSwitchCase="'rejected'" class="route-details-status-content">
      <div class="route-details-status-icon-container">
        <mat-icon [ngStyle]="{ color: 'rgb(174,55,41)' }">cancel</mat-icon>
      </div>
      <div class="route-details-status-text-container">
        <p>REJECTED - {{ route.lastupdatedTs | date : 'short' }}</p>
        <p>{{ route?.rejectReason }}</p>
      </div>
    </div>

    <div *ngSwitchCase="'pending'" class="route-details-status-content">
      <div class="route-details-status-icon-container">
        <mat-icon [ngStyle]="{ color: 'rgba(36, 100, 168, 1)' }">pending</mat-icon>
      </div>
      <div class="route-details-status-text-container">
        <p>PENDING - Last Update: {{ route.lastupdatedTs | date : 'short' }}</p>
      </div>
    </div>
  </div>

  <div class="route-details-status-container" *ngIf="editMode" [ngStyle]="{ background: 'rgb(208, 226, 245)' }">
    <div class="route-details-status-content">
      <div class="route-details-status-icon-container">
        <mat-icon [ngStyle]="{ color: 'rgba(36, 100, 168, 1)' }">edit</mat-icon>
      </div>
      <div class="route-details-status-text-container">
        <p>EDIT MODE</p>
        <p>In Edit Mode, you can change your route by adding, moving, or deleting stops or waypoint markers.</p>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
