import { Component, OnInit, HostListener, AfterContentChecked, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { tap, switchMap, map, take, filter, distinctUntilChanged } from 'rxjs/operators';
import { CustomIconService } from '@app/services/custom-icon.service';
import { DataDogService } from '@app/services/datadog.service';
import { DebugService } from '@app/services/debug.service';
import { MarkerPopupService } from '@app/services/marker-popup.service';
import { OnRouteAuthService } from '@app/services/onroute-auth.service';
import { FeatureFlagService } from '@app/services/feature-flag.service';
import { UtilitiesService } from '@app/services/utilities.service';
import { ZonarUserDialogComponent } from '@app/components/zonar-user-dialog/zonar-user-dialog.component';
import { StopMarkerPopupComponent } from '@app/features/route-map/components/stop-marker-popup/stop-marker-popup.component';
import { WaypointMarkerPopupComponent } from '@app/features/route-map/components/waypoint-marker-popup/waypoint-marker-popup.component';
import { NavInstructionMarkerComponent } from '@app/features/route-map/components/nav-instruction-marker/nav-instruction-marker.component';
import { EditManagementService } from './services/edit-management.service';
import { QuadService } from './shared/layout/quad/services/quad.service';
import { QuadDrawerState } from './shared/layout/quad/quad-layout.types';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { User } from '@auth0/auth0-angular';
import { safeCustomElementDecorator } from './shared/utils/helpers';
import { RouteCreationService } from './features/route-creation/route-creation.service';
import { NewRouteSourceType } from './features/route-creation/rc_interfaces';
import { UserService } from './services/user.service';
import { NgxPendoService } from 'ngx-pendo';
import { environment } from '@environments/environment';
import _ from 'lodash-es';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  viewHeader: boolean;
  viewContent: boolean;
  currentUser: Observable<User>;
  zonarUserDialogRef: MatDialogRef<ZonarUserDialogComponent>;
  routeSplittingMode: boolean;

  drawerStateClass: string = '';

  constructor(
    private auth: AuthService,
    private onRouteAuth: OnRouteAuthService,
    private userService: UserService,
    private debugService: DebugService,
    private datadogService: DataDogService,
    private utilitiesService: UtilitiesService,
    private quadService: QuadService,
    public dialog: MatDialog,
    private injector: Injector,
    public markerPopupService: MarkerPopupService,
    private customIconsService: CustomIconService,
    public editManagementService: EditManagementService,
    private featureFlagService: FeatureFlagService,
    public routeCreationService: RouteCreationService,
    private pendoService: NgxPendoService,
    private zuiAnalytics: ZonarUiAnalyticsService
  ) {
    // Convert certain components to custom elements.
    const StopMarkerPopupElement = createCustomElement(StopMarkerPopupComponent, { injector });
    const WaypointMarkerPopupElement = createCustomElement(WaypointMarkerPopupComponent, { injector });
    const NavInstructionMarkerElement = createCustomElement(NavInstructionMarkerComponent, { injector });
    // Register the custom elements with the browser.
    customElements.define = safeCustomElementDecorator(customElements.define);
    customElements.define('stop-marker-popup-element', StopMarkerPopupElement);
    customElements.define('waypoint-marker-popup-element', WaypointMarkerPopupElement);
    customElements.define('nav-instruction-marker-element', NavInstructionMarkerElement);
    // Register all of our svg icons to be able to allow material icon to reference them in the template
    this.customIconsService.registerIcons();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.utilitiesService.documentClickedTarget.next(event.target);
  }

  @HostListener('document:keydown.escape', ['$event'])
  escapePress(event: KeyboardEvent): void {
    this.utilitiesService.escapePressedEvent.next(event);
  }

  isZonarUser(user): boolean {
    return user['email'].split('@')[1] === 'zonarsystems.com' ? true : false;
  }

  handleZonarUser(): Observable<any> {
    // If an account code has already been set, use that
    if (sessionStorage.getItem('account_code')) {
      const acct_code = sessionStorage.getItem('account_code');
      const lowerCaseAcctCode = acct_code.toLowerCase();
      this.debugService.debugEnabled = sessionStorage.getItem('debugMode') === 'true';
      return this.onRouteAuth.authenticate(lowerCaseAcctCode);
    } else {
      // Otherwise, lets open the zonar user dialog and get the desired account info
      this.zonarUserDialogRef = this.dialog.open(ZonarUserDialogComponent, {
        data: { accountCode: undefined, debugMode: undefined },
      });

      return this.zonarUserDialogRef.afterClosed().pipe(
        tap((d: { accountCode; debugMode }) => {
          // Debug service provides Zonar users with additional route map overlays
          this.debugService.debugEnabled = d.debugMode;
          sessionStorage.setItem('debugMode', d.debugMode);
        }),
        switchMap((d: { accountCode; debugMode }) => {
          const lowerCaseAcctCode = d.accountCode.toLowerCase();
          return this.onRouteAuth.authenticate(lowerCaseAcctCode);
        })
      );
    }
  }

  ngOnInit() {
    this.viewHeader = false;
    this.viewContent = false;
    this.currentUser = this.auth.user$.pipe(filter(Boolean), distinctUntilChanged(), take(1));
    // Authentication pass off (between Zonar tenant and OnRoute API)
    const authInit = this.auth.isAuthenticated$.pipe(
      filter(Boolean),
      switchMap(() => this.currentUser),
      switchMap((user: User) => {
        this.userService.user = user;
        this.viewHeader = true;
        this.featureFlagService.init(user);
        return this.isZonarUser(user) ? this.handleZonarUser() : this.onRouteAuth.authenticate();
      }),
      map(accessGranted => (this.viewContent = accessGranted)),
      switchMap(() => analyticsInit)
    );

    authInit.subscribe();

    // Analytics initialization
    const analyticsInit = this.analyticsInit();

    // Track whether or not the user has entered RouteSplitting, so that certain
    // components may be conditionally shown
    this.routeCreationService.newRouteSource$.subscribe(sourceType => {
      Promise.resolve().then(() => (this.routeSplittingMode = sourceType == NewRouteSourceType.Split));
    });
  }

  public analyticsInit() {
    return this.currentUser.pipe(
      map(user => {
        if (environment.name === 'dev') {
          return;
        }
        this.datadogService.setContextProperty('zonarUser', this.isZonarUser(user));
        this.zuiAnalytics.addGtmToDom();
        this.pendoService.initialize(
          {
            id: _.get(this.userService.user, 'email', 'unknown'),
            name: _.get(this.userService.user, 'name', 'unknown'),
            email: _.get(this.userService.user, 'email', 'unknown'),
          },
          {
            id: _.get(this.userService.user, 'accountCode', 'unknown'),
            name: _.get(this.userService.user, 'accountCode', 'unknown'),
          }
        );
      })
    );
  }

  ngAfterContentChecked(): void {
    if (!this.quadService.drawerState$.getValue) {
      return;
    }

    const drawerState = this.quadService.drawerState$.getValue();
    const isEditModeActive = this.editManagementService.editMode$.getValue();
    const isDrawerClose = drawerState === QuadDrawerState.Close;

    this.drawerStateClass = isEditModeActive || isDrawerClose ? 'close' : '';
  }

  handleRouterEvent($event: RouterEvent) {
    if ($event instanceof NavigationEnd) {
      const tag = { event: 'page', pageName: $event.url };
      this.zuiAnalytics.pushTag(tag);
    }
  }
}
