import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouteNavigationComponent } from '@app/features/route-navigation/route-navigation.component';

@NgModule({
  declarations: [RouteNavigationComponent],
  imports: [CommonModule, SharedModule],
  exports: [RouteNavigationComponent],
})
export class RouteNavigationModule {}
