<div
  [ngClass]="{ 'drop-list': items.length === 0, 'drop-list-full': items.length > 0 }"
  cdkDropList
  (cdkDropListDropped)="droppedIntoList($event)"
  [cdkDropListSortingDisabled]="sortingDisabled">
  <div
    *ngFor="let item of items; let index = index"
    [class.selected]="item?.selected"
    [class.item-dragging]="dragging && item?.selected && firstSelectedIndex === index"
    cdkDrag
    [cdkDragDisabled]="!item?.selected"
    (cdkDragStarted)="dragStarted($event, index)"
    (cdkDragEnded)="dragEnded()"
    (cdkDragDropped)="dropped($event)">
    <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item, item: item, index: index }"> </ng-container>
    <div class="landing-placeholder" *cdkDragPlaceholder></div>
    <div *cdkDragPreview>
      <div class="select-item-drag-preview">
        {{ selections.length || 'x' }}
      </div>
      <div *ngFor="let sel of selections">
        <ng-container
          *ngTemplateOutlet="templateRef; context: { $implicit: items[sel], item: sel, index: index }"></ng-container>
      </div>
    </div>
  </div>
</div>
