import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  private icons = [
    'curve_left',
    'curve_right',
    'turn_right',
    'turn_left',
    'marker',
    'edit_outline',
    'exit',
    'uturn',
    'stop',
    'destination',
    'continue',
    'north',
    'west',
    'east',
    'south',
    'add_stop',
    'add_uturn',
    'split_route',
    'roundabout_1',
    'roundabout_2',
    'roundabout_3',
    'roundabout_4',
    'roundabout_5',
    'roundabout_6',
    'roundabout_7',
  ];
  constructor(public iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer) {}
  public registerIcons(): void {
    for (const i of this.icons) {
      this.iconRegistry.addSvgIcon(`${i}`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${i}.svg`));
    }
  }
}
