/**
 * These are the models used in Route Recorder -> OnRoute API interaction.
 * Our app will use these during route creation, where we are essentially simulating the
 * function of Route Recorder
 *
 * For models of data we receive from OnRoute API GET requests, look to api_interfaces.ts
 */

import { HereGeoCodePosition } from '@app/shared/models/here_interfaces';
import { LatLngBounds } from 'leaflet';

export interface RecordedRoute {
  name: string;
  description?: string;
  recorded_ts?: string;
  account_id: number;
  driver: Driver;
  stop_data: RecordedStop[];
}

interface Driver {
  gtc_id?: number;
  email?: string;
  driver_first_name?: string;
  driver_last_name?: string;
}

export interface RecordedStop {
  name: string;
  lat: number;
  lon: number;
  traces: Trace[];
}

interface Trace {
  source_name?: string;
  source_type?: string;
  waypoints: Waypoint[];
}

interface Waypoint {
  lat: number;
  lon: number;
}

export enum NewRouteSourceType {
  Empty = 'empty',
  Clone = 'clone',
  Split = 'split',
}

export interface TargetPosition {
  target: 'origin' | 'destination';
  position: HereGeoCodePosition;
}

export class USBounds extends LatLngBounds {
  constructor() {
    super([24.7433195, -124.7844079], [49.3457868, -66.9513812]);
  }
}
