import { Component, Input, OnChanges } from '@angular/core';
import { NavigationIconPipe } from '@app/shared/pipes/navigation-icon.pipe';

@Component({
  selector: 'app-nav-instruction-marker',
  templateUrl: './nav-instruction-marker.component.html',
  styleUrls: ['./nav-instruction-marker.component.scss'],
  providers: [NavigationIconPipe],
})
export class NavInstructionMarkerComponent implements OnChanges {
  @Input() instruction: string;

  public markerText: string;
  public markerIcon: string;
  public arrivedAtStop: boolean;

  constructor(private navIconPipe: NavigationIconPipe) {}
  ngOnChanges(): void {
    this.markerText = this.extractMarkerText(this.instruction);
    this.markerIcon = this.extractProperIcon(this.instruction);
  }

  public extractMarkerText(instruction: string): string {
    let t = instruction.replace(/. Go .*m./g, '');
    // leaving this in here in case any oddities arise from the v8 migration
    // t = t.replace(/.*\son\s|.*\sonto\s/g, '');
    t = t.replace(/Arrive at /, '');
    return t;
  }
  public extractProperIcon(instruction: string): string | undefined {
    const i = this.navIconPipe.transform(instruction);
    if (i === 'stop') {
      this.arrivedAtStop = true;
    }
    return i;
  }
}
