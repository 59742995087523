import { BehaviorSubject } from 'rxjs';

export class RouteFeatureManager {
  /**
   * Controls wether the Feature menu is visible */
  public isFeatureMenuVisible = false;

  /**
   * Controls wether the Guidance menu is visible */
  public isGuidanceMenuVisible = false;

  /**
   * Specifies the type of the current feature */
  public featureType: FeatureType | undefined = undefined;

  /**
   * True when adding a Stop */
  public isAddingStop = false;

  /**
   * True when U-turns are enforced */
  public isUturEnforced = false;

  /**
   * True when adding a Waypoint */
  public isAddingWaypoint = false;

  /**
   * True when a segment has been selected */
  public isSegmentSelected = false;

  public isAddingFeature$: BehaviorSubject<boolean>;

  public selectedSegmentIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor(isAddingFeature$: BehaviorSubject<boolean>) {
    this.isAddingFeature$ = isAddingFeature$;
  }

  /**
   * Enables the specified FeatureType
   *
   * @param featureType The name of the feature */
  public enable = (featureType: FeatureType): void => {
    this.featureType = featureType;

    switch (featureType) {
      case FeatureType.Stop:
        this.isAddingWaypoint = false;
        this.isUturEnforced = false;
        this.isAddingStop = true;
        break;
      case FeatureType.Uturn:
        this.isAddingWaypoint = false;
        this.isUturEnforced = true;
        this.isAddingStop = true;
        break;
      case FeatureType.Waypoint:
        this.isAddingWaypoint = false;
        this.isUturEnforced = false;
        this.isAddingStop = true;
        break;
    }

    this.isAddingFeature$.next(true);
  };

  /**
   * Revert to initial values */
  public reset = (): void => {
    // segment selection
    this.isSegmentSelected = false;

    // hide menus
    this.isFeatureMenuVisible = false;
    this.isGuidanceMenuVisible = false;

    // feature type
    this.featureType = undefined;

    // stop flags
    this.isAddingStop = false;
    this.isUturEnforced = false;

    // waypoint flags
    this.isAddingWaypoint = false;

    // not adding feat anymore
    this.isAddingFeature$.next(false);
    this.selectedSegmentIndex$.next(undefined);
  };

  public toggleFeatureMenu() {
    this.isFeatureMenuVisible = !this.isFeatureMenuVisible;
  }

  public closeFeatureMenu() {
    this.isFeatureMenuVisible = false;
  }

  public enableGuidanceMenu() {
    this.isGuidanceMenuVisible = true;
  }

  public disableGuidanceMenu() {
    this.isGuidanceMenuVisible = false;
  }
}

export enum FeatureType {
  Stop = 'stop',
  Waypoint = 'waypoint',
  Uturn = 'uturn',
}
