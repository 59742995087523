<mat-dialog-content>
  <div>
    <h4>Import Routes(s)</h4>
  </div>
  <p>
    Upload your route(s) by dragging and <br />
    dropping your CSV file into the space <br />
    below. <a class="color-blue" href="http://www.example.com"><strong>Learn more here</strong></a>
  </p>
  <div>
    <ngx-file-drop
      multiple="false"
      [class.dragover]="isFileHover"
      dropZoneLabel="Drop files here"
      (onFileDrop)="dropped($event)"
      (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" class="drop-zone">
        <div class="column-layout">
          <mat-icon (click)="openFileSelector()" class="upload-files">upload_file</mat-icon>
          <p>Drag and Drop file here, or <strong (click)="openFileSelector()">Choose file</strong>.</p>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button" class="cancel-button">Cancel</button>
</mat-dialog-actions>
