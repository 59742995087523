import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditManagementService } from '@app/services/edit-management.service';
import { EditType } from '@app/shared/models/app_interfaces';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import _ from 'lodash-es';

@Component({
  selector: 'app-route-edit-menu-dialog',
  templateUrl: './route-edit-dialog.html',
  styleUrls: ['./route-edit-dialog.scss'],
})
export class RouteEditDialogComponent implements OnDestroy {
  rejectRouteNotes: string;
  processingEdit: boolean;
  unableToDelete: boolean;
  private unsubscribe$ = new Subject();

  constructor(
    public editManagementService: EditManagementService,
    public router: Router,
    public dialogRef: MatDialogRef<RouteEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.processingEdit = false;
  }

  public initiateRouteEdit(editType: EditType) {
    this.processingEdit = true;
    if (editType === 'reject') {
      this.setRejectReason();
    }
    let options = editType == 'deleteRoutes' ? undefined : { positionIndex: this.data.positionIndex };
    this.editManagementService
      .initiateEditFlow(editType, options)
      .pipe(
        map((editActionResponse?) => {
          this.processingEdit = false;
          if (editActionResponse === 'You cannot delete the only stop.') {
            this.unableToDelete = true;
          } else {
            this.unableToDelete = false;
            this.closeDialogModal();
          }
          if (editType !== 'delete') {
            // this ensures we close any editing or route creation features that may be open/enabled
            this.router.navigate(['..']);
            this.editManagementService.closeAddFeatureMode();
            this.editManagementService.closeEditMode();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
  closeDialogModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  setRejectReason() {
    if (this.rejectRouteNotes === undefined) {
      this.rejectRouteNotes = 'No rejection reason given';
    }
    this.editManagementService.editableRoute['rejectReason'] = this.rejectRouteNotes;
  }
}
