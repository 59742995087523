import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { zonarAuth0, hostName2ServiceName, zonarApiException } from './api.service.constants';
import { retryBackoff } from 'backoff-rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private options = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  constructor(private httpClient: HttpClient, private auth: AuthService, private notifications: ZonarUiNotificationsService) {}

  public get(url: string, params?: HttpParams): Observable<any> {
    if (params) {
      return this.httpClient.get(url, { params }).pipe(
        retryBackoff({
          initialInterval: 100,
          maxRetries: 12,
          resetOnSuccess: true,
        }),
        catchError((e: HttpErrorResponse) => this.handleError(e))
      );
    }
    return this.httpClient.get(url).pipe(
      retryBackoff({
        initialInterval: 100,
        maxRetries: 12,
        resetOnSuccess: true,
      }),
      catchError((e: HttpErrorResponse) => this.handleError(e))
    );
  }

  public post(url: string, body = {}, params?: HttpParams, propagateError = false): Observable<any> {
    if (params !== undefined) {
      this.options['params'] = params;
    }

    return this.httpClient.post(url, JSON.stringify(body), this.options).pipe(
      catchError((e: HttpErrorResponse) => {
        return propagateError ? throwError(() => e) : this.handleError(e);
      })
    );
  }

  public put(url: string, body = {}): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(body)).pipe(catchError((e: HttpErrorResponse) => this.handleError(e)));
  }

  public delete(url: string): Observable<any> {
    return this.httpClient.delete(url).pipe(catchError((e: HttpErrorResponse) => this.handleError(e)));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    /**
     * We attempt to get the Service name from the endpoint's URL.
     *
     * URL parses the plain text value into an URL object, which breaks it
     * down into hostname, path, port, etc. */
    const url: URL = new URL(err.url);
    const serviceName: string = hostName2ServiceName[url.hostname] || 'UNKNOWN';

    // request info
    const statusCode = err.status;
    const serviceException = zonarApiException[statusCode] || err?.error?.responseCode;

    // extract request error(s)
    const issues: string =
      err?.error?.issues?.reduce((allIssues, issue) => {
        allIssues = `${allIssues} \r\n ${issue.message}`;

        return allIssues;
      }, '') || '';

    // display snack notification
    this.notifications.openError(`${serviceName} Error`, `Message: \r\n ${issues}`, 15);

    // logout if session expired
    if (serviceName === zonarAuth0 && serviceException === zonarApiException[401]) {
      this.auth.logout();
    }

    return of(err.status);
  }
}
