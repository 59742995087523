import { Injectable } from '@angular/core';
import { OnRouteUser } from '@app/shared/models/app_interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: OnRouteUser;

  constructor() {}

  public get user() {
    return this._user;
  }

  public set user(user) {
    this._user = user;
  }
}
