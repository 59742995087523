import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { QuadDrawerState } from '../quad-layout.types';

@Injectable({
  providedIn: 'root',
})
export class QuadService {
  public drawerState$: BehaviorSubject<QuadDrawerState> = new BehaviorSubject<QuadDrawerState>(QuadDrawerState.Open);

  constructor() {
    this.toggleDrawerState = this.toggleDrawerState.bind(this);
  }

  toggleDrawerState() {
    const oldDrawerState: QuadDrawerState = this.drawerState$.getValue();
    let newDrawerState: QuadDrawerState = oldDrawerState;

    if (oldDrawerState === QuadDrawerState.Open) {
      newDrawerState = QuadDrawerState.Close;
    } else {
      newDrawerState = QuadDrawerState.Open;
    }

    this.drawerState$.next(newDrawerState);
  }
}
