import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import { appName, appVersion, datadog } from '@environments/shared';

@Injectable({
  providedIn: 'root',
})
export class DataDogService {
  constructor() {
    if (environment.name === 'prod') {
      datadogRum.init({
        applicationId: datadog.applicationId,
        clientToken: datadog.clientToken,
        site: datadog.site,
        env: environment.name,
        service: appName,
        version: appVersion,
        trackFrustrations: true,
        sessionSampleRate: 100,
        premiumSampleRate: 20,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
      });
      datadogRum.startSessionReplayRecording();
    }
  }

  addAction(name: string, context: any) {
    datadogRum.addAction(name, context);
  }

  setContextProperty(name: string, value: any) {
    // This basically just lets us add any sort of additional info about a user session that we want to be made available in DD
    datadogRum.setGlobalContextProperty(name, value);
  }
}
