<mat-dialog-content>
  <div>
    <h4 class="title">Importing Routes</h4>
  </div>
  <div class="notification-status">
    <mat-icon class="upload-icon">upload_file</mat-icon>
    <p>{{ fileName }}</p>
    <p>
      {{ this.importRoutesService.importCount$ | async }}/{{ this.importRoutesService.lastImportJobCount }} {{ importStage }}...
    </p>
  </div>
</mat-dialog-content>
