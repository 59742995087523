// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Pattern Library
import { ZonarUIMaterialModule } from '@zonar-ui/material';

// Material Design
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// Third Party
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxFileDropModule } from 'ngx-file-drop';

// App Components
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

// App Directives
import { BackgroundFromStatusDirective } from '@app/shared/directives/background-from-status.directive';
import { HighlightSegmentDirective } from './directives/highlight-segment.directive';

// App Pipes
import { ExtractNavInstructionDistancePipe } from '@app/shared/pipes/extract-nav-instruction-distance.pipe';
import { MetersToMilesPipe } from '@app/shared/pipes/meters-to-miles.pipe';
import { NavigationIconPipe } from '@app/shared/pipes/navigation-icon.pipe';
import { SecondsToHumanFriendlyPipe } from '@app/shared/pipes/seconds-to-human-friendly.pipe';
import { TrimNavInstructionsPipe } from '@app/shared/pipes/trim-nav-instructions.pipe';
import { TrimStartingPositionNamePipe } from '@app/shared/pipes/trim-starting-position-name.pipe';

// App Layout
import { LayoutModule } from './layout/layout.module';
import { EllipsePipe } from './pipes/ellipse.pipe';
import { MultiDragDropComponent } from './components/multi-drag-drop/multi-drag-drop.component';

@NgModule({
  declarations: [
    LoadingComponent,
    BackgroundFromStatusDirective,
    HighlightSegmentDirective,
    ExtractNavInstructionDistancePipe,
    MetersToMilesPipe,
    NavigationIconPipe,
    SecondsToHumanFriendlyPipe,
    TrimNavInstructionsPipe,
    TrimStartingPositionNamePipe,
    EllipsePipe,
    MultiDragDropComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSidenavModule,
    MatExpansionModule,
    MatSnackBarModule,
    LeafletModule,
    ZonarUIMaterialModule,
    LayoutModule,
    RouterModule,
    NgxFileDropModule,
  ],
  exports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    BackgroundFromStatusDirective,
    HighlightSegmentDirective,
    ZonarUIMaterialModule,
    LoadingComponent,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSidenavModule,
    MatExpansionModule,
    MatSnackBarModule,
    LeafletModule,
    NavigationIconPipe,
    TrimStartingPositionNamePipe,
    TrimNavInstructionsPipe,
    ExtractNavInstructionDistancePipe,
    MetersToMilesPipe,
    SecondsToHumanFriendlyPipe,
    LayoutModule,
    RouterModule,
    EllipsePipe,
    MultiDragDropComponent,
    NgxFileDropModule,
  ],
})
export class SharedModule {}
