import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImportRoutesService } from '@app/services/import-routes.service';
import _ from 'lodash-es';

@Component({
  selector: 'app-import-routes-errors-dialog',
  templateUrl: './import-routes-errors-dialog.component.html',
  styleUrls: ['./import-routes-errors-dialog.component.scss'],
})
export class ImportRoutesErrorsDialogComponent implements OnInit {
  displayedColumns: string[] = ['routeName', 'rowNumber', 'columnName', 'errorMessage'];
  titleMessage: string;
  constructor(
    public dialogRef: MatDialogRef<ImportRoutesErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public importRoutesService: ImportRoutesService
  ) {}

  ngOnInit(): void {
    const errorCount = this.importRoutesService.importErrors.length;
    this.titleMessage = `File ${this.data} had the following ${errorCount} error${errorCount > 1 ? 's:' : ':'}`;
  }

  download() {
    const newBlob = new Blob([this.errorsToCsv()], { type: 'text/csv' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = _.replace(this.data, '.csv', '_errors.csv');
    link.click();
  }

  errorsToCsv() {
    let errorArr = [`ROUTE NAME,ROW,COLUMN NAME,ERROR MESSAGE`];
    for (const err in this.importRoutesService.importErrors) {
      errorArr.push(this.importRoutesService.importErrors[err].asCSVRow());
    }
    return errorArr.join();
  }
}
