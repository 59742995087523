import { Component, OnDestroy } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditManagementService } from '@app/services/edit-management.service';

@Component({
  selector: 'app-route-edit-toolbar',
  templateUrl: './route-edit-toolbar.component.html',
  styleUrls: ['./route-edit-toolbar.component.scss'],
})
export class RouteEditToolbarComponent implements OnDestroy {
  private unsubscribe$ = new Subject();

  constructor(public editManagementService: EditManagementService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public removeEditChanges() {
    this.editManagementService.discardRouteChanges().pipe(takeUntil(this.unsubscribe$), first()).subscribe();
  }

  public toggleFeatureMenu() {
    this.editManagementService.features.isFeatureMenuVisible = !this.editManagementService.features.isFeatureMenuVisible;
  }

  public closeFeatureMenu() {
    this.editManagementService.features.isFeatureMenuVisible = false;
  }

  public enableGuidanceMenu() {
    this.editManagementService.features.isGuidanceMenuVisible = true;
  }
}
