import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImportRoutesService } from '@app/services/import-routes.service';
import { RoutesService } from '@app/services/routes.service';
import _ from 'lodash-es';
import { ImportRoutesSnackbarComponent } from '../import-routes-snackbar/import-routes-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, distinct, takeUntil, tap } from 'rxjs';
import { CSVImportError } from '@app/shared/models/app_interfaces';

@Component({
  selector: 'app-import-routes-progress-dialog',
  templateUrl: './import-routes-progress-dialog.component.html',
  styleUrls: ['./import-routes-progress-dialog.component.scss'],
})
export class ImportRoutesProgressDialogComponent implements OnInit, OnDestroy {
  public fileName: string;
  public importStage: 'Uploading' | 'Processing' = 'Uploading';
  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ImportRoutesProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public importRoutesService: ImportRoutesService,
    private routesService: RoutesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.fileName = _.get(this.data, 'fileName');

    this.dialogRef.afterClosed().subscribe(data => this.openImportRoutesSnackbar(data));

    if (this.importRoutesService.importStatus == 'failure') {
      // Immediately close and open failure snackbar
      this.dialogRef.close(this.data);
    } else {
      // Don't bother subscribing if there are no routes being uploaded
      let rejectCount = 0;
      this.importRoutesService.routeProcessingStatusUpdates$
        .pipe(distinct(undefined, this.unsubscribe$), takeUntil(this.unsubscribe$))
        .subscribe(msg => {
          if (this.importRoutesService.uploadingComplete) {
            this.importStage = 'Processing';
          }
          if (['pending', 'rejected'].includes(msg['status'])) {
            if (_.get(msg, 'status') === 'rejected') {
              this.importRoutesService.importErrors.push(
                new CSVImportError(
                  `Route ID: ${_.get(msg, 'id')}`,
                  'warn',
                  `Rejected: ${_.get(msg, 'reject_reason')}`,
                  undefined,
                  undefined
                )
              );
              rejectCount++;
            }
            this.importRoutesService.routeIDsWaitingForProcessingResult =
              this.importRoutesService.routeIDsWaitingForProcessingResult.filter(it => it != msg['id']);
            if (this.importRoutesService.uploadingComplete == true) {
              this.importRoutesService.importCount$.next(
                this.importRoutesService.lastImportJobCount - this.importRoutesService.routeIDsWaitingForProcessingResult.length
              );
            }
            if (this.importRoutesService.routeIDsWaitingForProcessingResult.length == 0) {
              if (rejectCount > 0) {
                this.importRoutesService.importStatus = 'partial';
              }
              if (rejectCount == this.importRoutesService.lastImportJobCount) {
                this.importRoutesService.importStatus = 'failure';
              }
              this.routesService.refreshRoutes();
              this.dialogRef.close(this.data);
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.importRoutesService.closeWebsocket();
  }

  openImportRoutesSnackbar(data) {
    this.snackBar.openFromComponent(ImportRoutesSnackbarComponent, {
      panelClass: 'snackbar',
      data: data,
      duration: null,
    });
  }
}
