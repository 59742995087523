/*
  Interfaces of objects modified or manipulated within this app
*/
import { Marker, DivIcon, LatLng, Icon, MarkerOptions } from 'leaflet';
import { NavInstruction, Stop, Trace, Waypoint } from './api_interfaces';
import { User } from '@auth0/auth0-angular';

export interface RouteStatusUpdate {
  id: number;
  status: 'approved' | 'rejected';
  rejectReason?: string;
}

export interface RoutePathUpdate {
  id: number;
  stopData: Stop[];
  totalDist: number;
  totalTime: number;
}

export interface BestStop {
  associatedStopIndex?: number;
  name: string;
  nav_instructions?: NavInstruction[];
  source_name?: string;
  source_type?: string;
  travel_dist?: number;
  travel_time?: number;
  waypoints?: Waypoint[];
  lat?: number;
  lon?: number;
  selected?: boolean;
  traces?: any;
}

export interface SplitRouteSourceRouteDetail {
  accountId: number;
  id: number;
  name: string;
  best: BestStop[];
}

export class EnrichedRouteDetail {
  accountId: number;
  allSelected?: boolean;
  best: BestStop[];
  best_source_name: string;
  best_source_type: string;
  driverFirstName: string;
  driverGtcId: number;
  driverLastName: string;
  id: number;
  lastupdatedTs: string;
  nStops: number;
  name: string;
  recordedTs: string;
  rejectReason?: string;
  sorted: any;
  status: 'approved' | 'rejected' | 'pending';
  stopData: Stop[];
  totalDist: number;
  totalTime: number;
}

/* eslint-disable max-len */
export class NumberedStopMarkerIcon extends DivIcon {
  constructor(className: string, stopIndex: number, fill: string = '#0075B4', opacity: string = '1.0') {
    super({
      className,
      html: `
                <svg width="36" height="39" viewbox="0 0 36 39" transform="scale(0.8)" opacity="${opacity}">
                  <path d="M12,-0.378263158 C15.3467538,-0.378263158 18.3773487,1.01299196 20.5708456,3.26421237 C22.7597026,5.51067091 24.1146154,8.61381512 24.1146154,12.042 C24.1146154,20.7426479 13.8555943,34.2963804 12.5318497,36.0090342 L12.4549562,36.1081724 C12.4445794,36.121499 12.4354414,36.1332105 12.4275713,36.1432815 L12.3917625,36.1889432 C12.3917625,36.1889432 12.2551001,36.3620175 11.9996685,36.6729789 C11.9713437,36.6384059 11.9444808,36.60554 11.9191047,36.5744288 L11.7848872,36.4091962 C11.6689077,36.2657782 11.6082375,36.1889432 11.6082375,36.1889432 L11.6082375,36.1889432 L11.5724287,36.1432815 C11.5645586,36.1332105 11.5554206,36.121499 11.5450438,36.1081724 L11.4681503,36.0090342 C10.1444057,34.2963804 -0.114615385,20.7426479 -0.114615385,12.042 C-0.114615385,8.61381512 1.2402974,5.51067091 3.42915444,3.26421237 C5.62265125,1.01299196 8.65324623,-0.378263158 12,-0.378263158 Z" id="Shape" stroke="#FFFFFF" fill="${fill}" fill-rule="nonzero"></path>
                  <g>
                    <circle fill="#fff" cx="12" cy="12" r="10"></circle>
                    <text x="12" y="12" text-anchor="middle" stroke-width="2px" dy=".3em" alt="Stop Marker ${stopIndex}">${stopIndex}</text>
                  </g>
                </svg>
              `,
      iconAnchor: [12, 34],
      iconSize: null,
    });
  }
}
/* eslint-disable max-len */
export class ReroutedStopEventIcon extends DivIcon {
  constructor(className: string) {
    super({
      className,
      html: `
                <svg width="36" height="39" viewbox="0 0 36 39" transform="scale(0.8)">
                  <path d="M12,-0.378263158 C15.3467538,-0.378263158 18.3773487,1.01299196 20.5708456,3.26421237 C22.7597026,5.51067091 24.1146154,8.61381512 24.1146154,12.042 C24.1146154,20.7426479 13.8555943,34.2963804 12.5318497,36.0090342 L12.4549562,36.1081724 C12.4445794,36.121499 12.4354414,36.1332105 12.4275713,36.1432815 L12.3917625,36.1889432 C12.3917625,36.1889432 12.2551001,36.3620175 11.9996685,36.6729789 C11.9713437,36.6384059 11.9444808,36.60554 11.9191047,36.5744288 L11.7848872,36.4091962 C11.6689077,36.2657782 11.6082375,36.1889432 11.6082375,36.1889432 L11.6082375,36.1889432 L11.5724287,36.1432815 C11.5645586,36.1332105 11.5554206,36.121499 11.5450438,36.1081724 L11.4681503,36.0090342 C10.1444057,34.2963804 -0.114615385,20.7426479 -0.114615385,12.042 C-0.114615385,8.61381512 1.2402974,5.51067091 3.42915444,3.26421237 C5.62265125,1.01299196 8.65324623,-0.378263158 12,-0.378263158 Z" id="Shape" stroke="#FFFFFF" fill="#b40027" fill-rule="nonzero"></path>
                  <g>
                    <circle fill="#fff" cx="12" cy="12" r="10"></circle>
                    <text x="12" y="12" text-anchor="middle" stroke-width="2px" dy=".3em" alt="Rerouted Event">RR</text>
                  </g>
                </svg>
              `,
      iconAnchor: [12, 34],
      iconSize: null,
    });
  }
}
/* eslint-disable max-len */
export class SkippedStopEventIcon extends DivIcon {
  constructor(className: string) {
    super({
      className,
      html: `
                <svg width="36" height="39" viewbox="0 0 36 39" transform="scale(0.8)">
                  <path d="M12,-0.378263158 C15.3467538,-0.378263158 18.3773487,1.01299196 20.5708456,3.26421237 C22.7597026,5.51067091 24.1146154,8.61381512 24.1146154,12.042 C24.1146154,20.7426479 13.8555943,34.2963804 12.5318497,36.0090342 L12.4549562,36.1081724 C12.4445794,36.121499 12.4354414,36.1332105 12.4275713,36.1432815 L12.3917625,36.1889432 C12.3917625,36.1889432 12.2551001,36.3620175 11.9996685,36.6729789 C11.9713437,36.6384059 11.9444808,36.60554 11.9191047,36.5744288 L11.7848872,36.4091962 C11.6689077,36.2657782 11.6082375,36.1889432 11.6082375,36.1889432 L11.6082375,36.1889432 L11.5724287,36.1432815 C11.5645586,36.1332105 11.5554206,36.121499 11.5450438,36.1081724 L11.4681503,36.0090342 C10.1444057,34.2963804 -0.114615385,20.7426479 -0.114615385,12.042 C-0.114615385,8.61381512 1.2402974,5.51067091 3.42915444,3.26421237 C5.62265125,1.01299196 8.65324623,-0.378263158 12,-0.378263158 Z" id="Shape" stroke="#FFFFFF" fill="#b44800" fill-rule="nonzero"></path>
                  <g>
                    <circle fill="#fff" cx="12" cy="12" r="10"></circle>
                    <text x="12" y="12" text-anchor="middle" stroke-width="2px" dy=".3em" alt="Skipped Stop Event">SS</text>
                  </g>
                </svg>
              `,
      iconAnchor: [12, 34],
      iconSize: null,
    });
  }
}
/* eslint-disable max-len */
export class ArrivedStopEventIcon extends DivIcon {
  constructor(className: string) {
    super({
      className,
      html: `
                <svg width="36" height="39" viewbox="0 0 36 39" transform="scale(0.8)">
                  <path d="M12,-0.378263158 C15.3467538,-0.378263158 18.3773487,1.01299196 20.5708456,3.26421237 C22.7597026,5.51067091 24.1146154,8.61381512 24.1146154,12.042 C24.1146154,20.7426479 13.8555943,34.2963804 12.5318497,36.0090342 L12.4549562,36.1081724 C12.4445794,36.121499 12.4354414,36.1332105 12.4275713,36.1432815 L12.3917625,36.1889432 C12.3917625,36.1889432 12.2551001,36.3620175 11.9996685,36.6729789 C11.9713437,36.6384059 11.9444808,36.60554 11.9191047,36.5744288 L11.7848872,36.4091962 C11.6689077,36.2657782 11.6082375,36.1889432 11.6082375,36.1889432 L11.6082375,36.1889432 L11.5724287,36.1432815 C11.5645586,36.1332105 11.5554206,36.121499 11.5450438,36.1081724 L11.4681503,36.0090342 C10.1444057,34.2963804 -0.114615385,20.7426479 -0.114615385,12.042 C-0.114615385,8.61381512 1.2402974,5.51067091 3.42915444,3.26421237 C5.62265125,1.01299196 8.65324623,-0.378263158 12,-0.378263158 Z" id="Shape" stroke="#FFFFFF" fill="#99b400" fill-rule="nonzero"></path>
                  <g>
                    <circle fill="#fff" cx="12" cy="12" r="10"></circle>
                    <text x="12" y="12" text-anchor="middle" stroke-width="2px" dy=".3em" alt="Arrived at Stop Event">AT</text>
                  </g>
                </svg>
              `,
      iconAnchor: [12, 34],
      iconSize: null,
    });
  }
}

export class OriginMarker extends Marker {
  constructor(position: LatLng, options?: MarkerOptions) {
    super(position, {
      icon: new Icon({
        iconSize: [25, 41],
        iconUrl: 'assets/icons/map/origin_map_icon.svg',
        className: 'start-marker',
      }),
      ...options,
    });
  }
}
export class DestinationMarker extends Marker {
  constructor(position: LatLng, options?: MarkerOptions) {
    super(position, {
      icon: new Icon({
        iconSize: [25, 41],
        iconUrl: 'assets/icons/map/destination_map_icon.svg',
        className: 'destination-marker',
      }),
      ...options,
    });
  }
}

export interface InstructionManeuver {
  position: {
    lat: number;
    lon: number;
  };
  instruction: string;
  travelTime: number;
  length: number;
  id: string;
}

export interface StopMarker extends Marker {
  stopIndex?: number;
}
export interface WaypointMarker extends StopMarker {
  originalWaypointIndex?: number;
  uniqueId?: string;
}
export interface DebugTrace {
  traceName: string;
  traces: Trace[];
}

export interface DebugMarker extends Marker {
  stopIndex?: number;
}

export interface DebugReferences {
  traceName: DebugMarker[];
}

export class EditedWaypoint {
  constructor(public associatedSegmentIndex: number, public wpPosition: LatLng, public uniqueId: string) {}
}

export interface AddStopOptions {
  newStopPosition: LatLng;
  positionIndex: number;
  intermediateWaypoints?;
  avoidUturns?: boolean;
  name?: string;
  finalStop?: boolean;
}

export interface AddWaypointOptions {
  wpPosition?: LatLng;
  associatedSegmentIndex: number;
  intermediateWaypoints?;
  avoidUturns?: boolean;
}

export interface UpdateMarkerPositionOptions {
  newPosition: LatLng;
  positionIndex: number;
  markerType: MarkerType;
  waypointId?: string;
  avoidUturns?: boolean;
  precedingIntermediateWaypoints?: any[];
  followingIntermediateWaypoints?: any[];
}
export type MarkerType = 'start' | 'stop' | 'waypoint' | 'destination';

export interface DeleteStopOptions {
  positionIndex: number;
  intermediateWaypoints?;
}
export interface MoveStopInStopsOptions {
  fromIndex: number;
  toIndex: number;
}

export type EditType =
  | 'approve'
  | 'save'
  | 'reject'
  | 'discard'
  | 'delete'
  | 'addWaypoint'
  | 'addStop'
  | 'updateMarkerPosition'
  | 'moveStopInStops'
  | 'deleteRoutes';

export interface RouteEditOptions
  extends Partial<AddStopOptions>,
    Partial<AddWaypointOptions>,
    Partial<UpdateMarkerPositionOptions>,
    Partial<DeleteStopOptions>,
    Partial<MoveStopInStopsOptions> {}

export interface HereOptions {
  heading?: number;
  intermediateWaypoints?: any[];
  avoidUturns?: boolean;
}

export interface OnRouteUser extends User {
  accountCode?: string;
}

export interface CapturedEventValues {
  ctrlKey: boolean;
  shiftKey: boolean;
  metaKey: boolean;
}

export interface SelectStopEvent {
  stopIndex: number;
  routeIndex: number;
  checked: boolean;
  eventState: CapturedEventValues;
}

export class CSVImportError {
  routeName: string | undefined;
  type: 'critical' | 'warn';
  errorMessage: string;
  rowNumber: number | undefined;
  columnName: string | undefined;
  constructor(
    routeName: string | undefined,
    type: 'critical' | 'warn',
    message: string,
    rowNumber: number | undefined,
    columnName: string | undefined
  ) {
    this.routeName = routeName;
    this.type = type;
    this.errorMessage = message;
    this.rowNumber = rowNumber;
    this.columnName = columnName;
  }
  asCSVRow() {
    return `\n${this.routeName},${this.rowNumber},${this.columnName},${this.errorMessage}`;
  }
}

export interface CSVImportBatches {
  [fileName: string]: number[];
}
