<div *ngIf="!editManagementService.features.isSegmentSelected; else selected">
  <span class="route-feature-toolbar-container" data-cy="route-feature-toolbar">
    Select the segment to add a
    {{ editManagementService.features.featureType }} to by hovering and clicking.
    <mat-icon (click)="editManagementService.closeAddFeatureMode()">close</mat-icon>
  </span>
</div>

<ng-template #selected>
  <span class="route-feature-toolbar-container" data-cy="route-feature-toolbar">
    Click on the map to place a new {{ editManagementService.features.featureType }} between {{ segment.origin }} and
    {{ segment.destination }}.
    <mat-icon (click)="editManagementService.closeAddFeatureMode()">close</mat-icon>
  </span>
</ng-template>
