<div class="zonar-user-mat-dialog-container">
    <h2 mat-dialog-title>Zonar User</h2>
    <mat-dialog-content>Please enter the GTC account code of the customer whose route's you want to retrieve.
    </mat-dialog-content>
    <mat-dialog-actions>
        <mat-form-field appearance="outline">
            <mat-label>Account Code</mat-label>
            <input matInput
                   ngDefaultControl
                   type='text'
                   placeholder="Ex. tes0703"
                   [(ngModel)]="data.accountCode"
                   (keydown.enter)="closeDialog()" />
        </mat-form-field>
        <mat-radio-button value="true"
                          ngDefaultControl
                          [(ngModel)]="data.debugMode">Debug Mode</mat-radio-button>
        <button mat-raised-button
                matSuffix
                type="submit"
                color="accent"
                [mat-dialog-close]="data">Submit</button>
    </mat-dialog-actions>
</div>