import { Component, HostBinding, Input, OnInit } from '@angular/core';

// Component's root css class
const defaultCssClass = 'basic-header';

/**
 * Header */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `[${defaultCssClass}]`,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class BasicHeaderComponent implements OnInit {
  /**
   * CSS classes to attach to the host component.
   * It accepts multiple classes separated by space. */
  @Input() cssClass = '';

  /**
   * Binds the default and passed css classes */
  @HostBinding('class') classNames: string = defaultCssClass;

  constructor() {}

  ngOnInit(): void {
    // bind css classes to host component
    this.classNames = `${defaultCssClass}${this.cssClass ? ` ${this.cssClass}` : ''}`;
  }
}
