<div class="header-information">
  <h5 mat-dialog-title>
    <strong>{{ titleMessage }}</strong>
  </h5>
  <button mat-button class="cliptoboard" (click)="download()">Download</button>
</div>
<mat-dialog-content class="my-mat-dialog-content">
  <div class="hr-container">
    <hr class="hr-shadow" />
  </div>
  <!-- <div id="import-error-table-container"> -->
  <table mat-table [dataSource]="importRoutesService.importErrors" class="mat-elevation-z8 error-dialog-table">
    <!-- Route Name Column -->
    <ng-container matColumnDef="routeName">
      <th mat-header-cell *matHeaderCellDef>ROUTE NAME</th>
      <td mat-cell *matCellDef="let error">{{ error.routeName || 'N/A' }}</td>
    </ng-container>

    <!-- Row Number Column -->
    <ng-container matColumnDef="rowNumber">
      <th mat-header-cell *matHeaderCellDef>ROW</th>
      <td mat-cell *matCellDef="let error">{{ error.rowNumber || 'N/A' }}</td>
    </ng-container>

    <!-- Column Name Column -->
    <ng-container matColumnDef="columnName">
      <th mat-header-cell *matHeaderCellDef>COLUMN</th>
      <td mat-cell *matCellDef="let error">{{ error.columnName || 'N/A' }}</td>
    </ng-container>

    <!-- Error Message-->
    <ng-container matColumnDef="errorMessage">
      <th mat-header-cell *matHeaderCellDef>ERROR MESSAGE</th>
      <td mat-cell *matCellDef="let error">{{ error.errorMessage || 'N/A' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- </div> -->
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions" align="end">
  <button mat-raised-button mat-dialog-close type="button" class="blue">Close</button>
</mat-dialog-actions>
