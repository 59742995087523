import { Data } from '@angular/router';

// all the services consumed by the OnRoute app
export const onrouteApi = 'OnRoute API';
export const zonarApi = 'Zonar API';
export const zonarAuth0 = 'Zonar Auth0';
export const hereAPI = 'Here API';

// this translates ugly hostnames to User-friendly service names
export const hostName2ServiceName: Data = {
  'fleet.api.here.com': hereAPI,
  'zonar-dev.auth0.com': zonarAuth0,
  'zonar-login.auth0.com': zonarAuth0,
  'qa-login.zonarsystems.net': zonarAuth0,
  'api-dev.zonarsystems.net': zonarApi,
  'api-staging.zonarsystems.net': zonarApi,
  'api-production.zonarsystems.net': zonarApi,
  'onroute-api.zonarsystems.net': onrouteApi,
  'onroute-api.dev.zonarsystems.net': onrouteApi,
  'onroute-api.staging.zonarsystems.net': onrouteApi,
  'onroute-api.production.zonarsystems.net': onrouteApi,
};

// Zonar service exceptions
export const zonarApiException: Data = {
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not found',
  409: 'Conflict',
  422: 'Unprocessable Entity',
};
