import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RouteEditMenuComponent } from './route-edit-menu/route-edit-menu.component';
import { RouteEditDialogComponent } from './route-edit-dialog/route-edit-dialog.component';
import { RouteEditToolbarComponent } from './route-edit-toolbar/route-edit-toolbar.component';

@NgModule({
  declarations: [RouteEditMenuComponent, RouteEditDialogComponent, RouteEditToolbarComponent],
  imports: [CommonModule, SharedModule],
  exports: [RouteEditMenuComponent, RouteEditToolbarComponent, RouteEditDialogComponent],
})
export class RouteEditingModule {}
