import { Component, HostBinding, HostListener, Input } from '@angular/core';

import { QuadService } from '../../services/quad.service';

// Component's root css class
const defaultCssClass = 'quad-drawer-toggle';

/**
 * Content
 *
 * Container component that holds page drawer-toggle.
 * Sets up a CSS Grid to layout components. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `[${defaultCssClass}]`,
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class DrawerToggleComponent {
  /**
   * CSS classes to attach to the host component.
   * It accepts multiple classes separated by space. */
  @Input() cssClass = '';

  /**
   * Binds the default and passed css classes */
  @HostBinding('class') classNames: string = defaultCssClass;

  constructor(private state: QuadService) {
    this.handleClick = this.handleClick.bind(this);
  }

  @HostListener('click')
  handleClick() {
    this.state.toggleDrawerState();
  }
}
