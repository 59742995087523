// Angular
import { NgModule } from '@angular/core';

// App Modules
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { RouteDisplayModule } from './features/route-display/route-display.module';

// App Components
import { SubheaderComponent } from '@app/components/subheader/subheader.component';
import { ZonarUserDialogComponent } from '@app/components/zonar-user-dialog/zonar-user-dialog.component';
import { AppComponent } from '@app/app.component';

@NgModule({
  declarations: [AppComponent, SubheaderComponent, ZonarUserDialogComponent],
  imports: [CoreModule, SharedModule, RouteDisplayModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
