<ng-container [ngSwitch]="data.display">
  <ng-container *ngSwitchCase="'creation-options'">
    <mat-dialog-content>
      <h3>Create New Route</h3>
      <p>Build a new route from scratch, clone an existing route, or combine stops from existing routes in split mode.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button
        mat-raised-button
        [routerLink]="['', {outlets: {'section-quad-container': null, 'section-quad-q2':null}}]"
        [skipLocationChange]="true"
        (click)="dialogRef.close()"
        class="red">
        Cancel
      </button>
      <button
        mat-raised-button
        [routerLink]="[{ outlets: { 'section-quad-q2': ['create-route', 'from-empty']} }]"
        [skipLocationChange]="true"
        (click)="dialogRef.close()"
        class="blue">
        Build New Route
      </button>
      <button
        mat-raised-button
        [routerLink]="[{ outlets: { 'section-quad-q2': ['create-route', 'from-existing']} }]"
        [skipLocationChange]="true"
        (click)="dialogRef.close()"
        class="blue">
        Clone Route
      </button>
      <button
        mat-raised-button
        [routerLink]="[{outlets: { 'section-quad-container': ['create-route', 'from-split', 'source-list'], 'section-quad-q2': null }}]"
        [skipLocationChange]="true"
        (click)="dialogRef.close()"
        class="blue">
        Split Route
      </button>
      <button
        *ngIf="(featureFlagService.importRoutesEnabled === true)"
        mat-raised-button
        mat-dialog-close
        (click)="openImportRoutesDialog()"
        class="blue">
        Import Routes
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngSwitchCase="'actions'">
    <ng-container [ngSwitch]="data.routeAction">
      <ng-container *ngSwitchCase="'save'">
        <mat-dialog-content>
          <h3>{{((routeCreationService.newRouteSource$ | async )!=='clone')?'Save Route?': 'Clone Route?'}}</h3>
          <p>This route will be saved and made available for approval.</p>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
          <button
            *ngIf="((routeCreationService.newRouteSource$ | async)!=='clone')"
            mat-raised-button
            (click)="dialogRef.close()"
            class="white">
            Continue Editing
          </button>
          <button
            *ngIf="((routeCreationService.newRouteSource$ | async)==='clone')"
            mat-raised-button
            [routerLink]="['', {outlets: {'section-quad-container': null, 'section-quad-q2':null}}]"
            [skipLocationChange]="true"
            (click)="dialogRef.close()"
            class="white">
            Discard
          </button>
          <button mat-raised-button (click)="saveRoute()" class="blue" cdkFocusInitial>Save</button>
        </mat-dialog-actions>
      </ng-container>
      <ng-container *ngSwitchCase="'exit'">
        <mat-dialog-content>
          <h3>Are you sure?</h3>
          <p>This will cancel your route-creation process. Unsaved routes cannot be recovered.</p>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
          <button mat-raised-button (click)="dialogRef.close()" class="white">Continue Editing</button>
          <button
            mat-raised-button
            [routerLink]="['', {outlets: {'section-quad-container': null, 'section-quad-q2':null}}]"
            [skipLocationChange]="true"
            (click)="dialogRef.close()"
            class="red">
            Exit
          </button>
        </mat-dialog-actions>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'processing'">
    <mat-dialog-content>
      <h3 *ngIf="requestInFlight">Processing...</h3>

      <h3 *ngIf="!requestInFlight">Your route has been saved!</h3>

      <p>Please allow a few minutes for processing before it becomes available in OnRoute Web.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button [routerLink]="['']" [disabled]="requestInFlight" (click)="reOpen()" class="blue">
        Create Another Route
      </button>
      <button mat-raised-button [routerLink]="['']" [disabled]="requestInFlight" (click)="dialogRef.close()" class="white">
        Okay
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
