import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditManagementService } from '@app/services/edit-management.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-route-feature-toolbar',
  templateUrl: './route-feature-toolbar.component.html',
  styleUrls: ['./route-feature-toolbar.component.scss'],
})
export class RouteFeatureToolbarComponent implements OnInit, OnDestroy {
  public segment: {
    index: number;
    origin?: string;
    destination?: string;
  };
  private unsubscribe$ = new Subject();

  constructor(public editManagementService: EditManagementService) {}

  ngOnInit(): void {
    this.editManagementService.features.selectedSegmentIndex$
      .pipe(
        filter(si => si !== undefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(si => {
        this.segment = {
          index: si,
          origin: this.editManagementService.editableRoute.best[si].name,
          destination: this.editManagementService.editableRoute.best[si + 1].name,
        };
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
