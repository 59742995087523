import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { EditManagementService } from '@app/services/edit-management.service';
import { MapService } from '@app/services/map.service';
import { SelectionManagementService } from '@app/services/selection-management.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-waypoint-marker-popup',
  templateUrl: './waypoint-marker-popup.component.html',
  styleUrls: ['./waypoint-marker-popup.component.scss'],
})
export class WaypointMarkerPopupComponent implements AfterViewInit, OnDestroy {
  public selectedWaypointId: string;
  selectedWaypointOrigin: string;
  selectedWaypointDestination: string;
  private unsubscribe$ = new Subject();

  constructor(
    private selectionManagementService: SelectionManagementService,
    private editManagementService: EditManagementService
  ) {}

  ngAfterViewInit(): void {
    this.selectionManagementService.selectedWaypointIdentifiers$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(i => i !== undefined)
      )
      .subscribe((wp: { uniqueId: string; associatedStopIndex: number }) => {
        this.selectedWaypointId = wp.uniqueId;
        this.selectedWaypointOrigin = this.editManagementService.editableRoute?.stopData[wp.associatedStopIndex - 1].name;
        this.selectedWaypointDestination = this.editManagementService.editableRoute?.stopData[wp.associatedStopIndex].name;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public deleteWaypoint() {
    this.editManagementService.deleteWaypoint(this.selectedWaypointId);
  }
}
