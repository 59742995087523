import { Injectable } from '@angular/core';
import {
  ArrivedStopEventIcon,
  DebugReferences,
  ReroutedStopEventIcon,
  SkippedStopEventIcon,
} from '@app/shared/models/app_interfaces';
import { Papa } from 'ngx-papaparse';
import { BehaviorSubject } from 'rxjs';
import { polyline, Marker, LatLngExpression, LayerGroup, divIcon } from 'leaflet';
import { RoutesService } from './routes.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  debugEnabled: boolean;
  debugReferences: DebugReferences = {} as DebugReferences;
  debugOverlays;
  debugPath$ = new BehaviorSubject(undefined);
  debugPathMarkers$ = new BehaviorSubject(undefined);
  debugReroutedMarkers$ = new BehaviorSubject(undefined);
  debugSkippedStopMarkers$ = new BehaviorSubject(undefined);
  debugArrivedStopMarkers$ = new BehaviorSubject(undefined);
  debugPathTs2PositionMap = {} as Record<string, LatLngExpression>;
  pathExportInSeconds;
  skippedStopCount;
  reroutedEventCount;
  arrivedStopCount;

  constructor(private papa: Papa, private routeService: RoutesService) {}

  processDebugUploads(fileList: FileList) {
    // We're going to accept multiple CSV's, with potential variations in header
    // For example, the Path export from GTC has 13 rows, but the LiveMaps export oonly has 11
    // Additionally, the Path export calls positions 'lat' 'lon', but LiveMaps calls them 'lat' 'long'
    // Kind of annoying, but let's deal with it.

    // Regardless of file contents, we first need to parse each uploaded CSV
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      this.papa.parse(fileList[i], {
        complete: results => {
          // Based on the parsed results, we can make a decision for processing that data into a map display
          // Kind of quick n dirty, but we're going to assume that path export csv's will always have > 10 rows,
          // whereas the Datadog rerouted event exports will...not
          if (results.data[0].length > 10) {
            // there are two different kinds of export csv's we may be dealing with, so we need to find the appropriate column index
            const latIdx = results.data[0].indexOf('Lat');
            const lonIdx =
              results.data[0].indexOf('Lon') > -1 ? results.data[0].indexOf('Lon') : results.data[0].indexOf('Long');
            // We need to create a mapping for timestamps to positions, since the rerouted event export does not contain position data
            this.mapPathTimestampToPosition(results.data, latIdx, lonIdx);
            this.pathExportEventsFromCsv(results.data, latIdx, lonIdx);
          } else {
            this.dataDogEventsFromCsv(results.data);
          }
        },
      });
    }
  }

  pathExportEventsFromCsv(data: any[], latIndex: number, lonIndex: number) {
    const pathLatLons: LatLngExpression[] = [] as LatLngExpression[];
    data
      .slice(1)
      .filter(row => row.length >= 11)
      .map(row => pathLatLons.push([parseFloat(row[latIndex]), parseFloat(row[lonIndex])]));
    this.debugPath$.next(polyline(pathLatLons, { color: 'red', opacity: 0.8 }));
    const pathMarkers: Marker[] = [] as Marker[];
    pathLatLons.map(l => pathMarkers.push(new Marker(l, { icon: divIcon({ iconSize: [5, 5] }) }).bindPopup(l.toString())));
    this.debugPathMarkers$.next(new LayerGroup(pathMarkers));
  }

  mapPathTimestampToPosition(data: any[], latIndex: number, lonIndex: number) {
    // If our Datadog logs don't have lat/lon fields, then we'll need to use the positions from the nearest path event
    const dateIdx = data[0].indexOf('Date');
    const timeIdx = data[0].findIndex(row => row.includes('Time'));
    const tz = data[0][timeIdx].match(/[A-Z][A-Z][A-Z]/)[0];
    let startTime;
    let endTime;
    data
      .slice(1)
      .filter(row => row.length >= 11)
      .map((row, i, a) => {
        this.debugPathTs2PositionMap[Date.parse(`${row[dateIdx]} ${row[timeIdx]} ${tz}`)] = [
          parseFloat(row[latIndex]),
          parseFloat(row[lonIndex]),
        ];
        if (i === 0) {
          startTime = new Date(`${row[dateIdx]} ${row[timeIdx]} ${tz}`);
        } else if (i === a.length - 1) {
          endTime = new Date(`${row[dateIdx]} ${row[timeIdx]} ${tz}`);
        }
      });
    const metrics = { pathExportInSeconds: (endTime - startTime) / 1000 };
    this.setUsefulMetricValues('path', metrics);
  }

  setUsefulMetricValues(type: 'path' | 'datadog', metrics: Record<string, unknown>) {
    switch (type) {
      case 'path':
        this.pathExportInSeconds = metrics.pathExportInSeconds;
        break;
      case 'datadog':
        this.reroutedEventCount = metrics.reroutedEventCount;
        this.skippedStopCount = metrics.skippedStopCount;
        this.arrivedStopCount = metrics.arrivedStopCount;
        break;
    }
  }

  dataDogEventsFromCsv(data: any[]) {
    // If we want to eventually filter datadog events by their routeId, we can uncomment and expand on this code
    // if (environment.production && data[0].findIndex((row) => row.includes('routeId')) > -1){
    //   const idToMatch = this.routeService.displayedRoute$.value.id;
    //   const routeIdIdx = data[0].findIndex((row) => row.includes('routeId'));
    //   const matchedRouteEvents = data.filter((row) => parseFloat(row[routeIdIdx].replace(/"/g, '')) === idToMatch);
    // }

    const dateIdx = data[0].indexOf('Date');
    const msgIdx = data[0].indexOf('Message');
    const reroutedMarkers = [];
    const skippedStopMarkers = [];
    const arrivedStopMarkers = [];

    if (data[0].some(row => row.includes('latitude' && 'longitude'))) {
      // if our log exports have lat/lon fields, then we can use those for our marker positions
      const latIdx = data[0].findIndex(row => row.includes('latitude'));
      const lonIdx = data[0].findIndex(row => row.includes('longitude'));
      // create all our re-rerouted event markers
      data
        .slice(1)
        .filter(row => row[msgIdx].includes('rerouted'))
        .map(row => {
          // for some reason we occasionally get extra apostrophes from DD logs. They need to be removed for parseFloat to work
          const position = [
            parseFloat(row[latIdx].replace(/'/g, '')),
            parseFloat(row[lonIdx].replace(/'/g, '')),
          ] as LatLngExpression;
          reroutedMarkers.push(
            new Marker(position, { icon: new ReroutedStopEventIcon('rerouted-event-icon') }).bindPopup(
              new Date(parseFloat(row[dateIdx])).toString()
            )
          );
        });
      // create all our skipped stop event markers
      data
        .slice(1)
        .filter(row => row[msgIdx].includes('skipped'))
        .map(row => {
          const position = [
            parseFloat(row[latIdx].replace(/'/g, '')),
            parseFloat(row[lonIdx].replace(/'/g, '')),
          ] as LatLngExpression;
          skippedStopMarkers.push(
            new Marker(position, { icon: new SkippedStopEventIcon('skipped-stop-event-icon') }).bindPopup(
              new Date(parseFloat(row[dateIdx])).toString()
            )
          );
        });
      // create all our arrived at stop event markers
      data
        .slice(1)
        .filter(row => row[msgIdx].includes('Arrived'))
        .map(row => {
          const position = [
            parseFloat(row[latIdx].replace(/'/g, '')),
            parseFloat(row[lonIdx].replace(/'/g, '')),
          ] as LatLngExpression;
          arrivedStopMarkers.push(
            new Marker(position, { icon: new ArrivedStopEventIcon('arrived-stop-event-icon') }).bindPopup(
              new Date(parseFloat(row[dateIdx])).toString()
            )
          );
        });
    } else {
      // ...otherwise we need to use the associative mapping to figure them out
      const reroutedEvents = data
        .slice(1)
        .filter(row => row[msgIdx].includes('rerouted'))
        .map(row => Date.parse(row[dateIdx]));

      const skippedStopEvents = data
        .slice(1)
        .filter(row => row[msgIdx].includes('skipped'))
        .map(row => Date.parse(row[dateIdx]));

      const arrivedStopEvents = data
        .slice(1)
        .filter(row => row[msgIdx].includes('Arrived'))
        .map(row => Date.parse(row[dateIdx]));

      // We need to look at our path export timestamps to see if any of the provided datadog events fall
      // within a given time threshold (5 seconds) of a path event.
      // If they do, then we'll create a marker
      for (const [k, v] of Object.entries(this.debugPathTs2PositionMap)) {
        for (const rr of reroutedEvents) {
          if (Math.abs(parseFloat(k) - rr) < 5000) {
            reroutedMarkers.push(
              new Marker(v, { icon: new ReroutedStopEventIcon('rerouted-event-icon') }).bindPopup(
                new Date(parseFloat(k)).toString()
              )
            );
          }
        }
        for (const rr of skippedStopEvents) {
          if (Math.abs(parseFloat(k) - rr) < 5000) {
            skippedStopMarkers.push(
              new Marker(v, { icon: new SkippedStopEventIcon('skipped-stop-event-icon') }).bindPopup(
                new Date(parseFloat(k)).toString()
              )
            );
          }
        }
        for (const aa of arrivedStopEvents) {
          if (Math.abs(parseFloat(k) - aa) < 5000) {
            arrivedStopMarkers.push(
              new Marker(v, { icon: new ArrivedStopEventIcon('arrived-stop-event-icon') }).bindPopup(
                new Date(parseFloat(k)).toString()
              )
            );
          }
        }
      }
    }

    const metrics = {
      reroutedEventCount: reroutedMarkers.length,
      skippedStopCount: skippedStopMarkers.length,
      arrivedStopCount: arrivedStopMarkers.length,
    };
    this.setUsefulMetricValues('datadog', metrics);

    this.debugReroutedMarkers$.next(reroutedMarkers);
    this.debugSkippedStopMarkers$.next(skippedStopMarkers);
    this.debugArrivedStopMarkers$.next(arrivedStopMarkers);
  }

  clearPath() {
    this.debugPath$.next(undefined);
    this.debugPathMarkers$.next(undefined);
    this.debugSkippedStopMarkers$.next(undefined);
    this.debugReroutedMarkers$.next(undefined);
    this.debugArrivedStopMarkers$.next(undefined);
    this.pathExportInSeconds = undefined;
    this.skippedStopCount = undefined;
    this.reroutedEventCount = undefined;
    this.arrivedStopCount = undefined;
  }
}
