<div [ngClass]="editMenuClasses" [ngStyle]="editMenuStyles">
  <div *ngIf="routeEditingFeaturesVisible">
    <button
      (click)="requestAction('undo')"
      [disabled]="!editManagementService?.undosAvailable"
      class="icon"
      data-dd-action-name="click on Undo Button"
      data-cy="route-edit-undo-button">
      <mat-icon>undo</mat-icon>
    </button>
    <button
      (click)="requestAction('redo')"
      [disabled]="!editManagementService?.redosAvailable"
      class="icon"
      data-dd-action-name="click on Redo Button"
      id="redo-button"
      data-cy="route-edit-redo-button">
      <mat-icon>redo</mat-icon>
    </button>
    <span class="spacer">&nbsp;</span>
    <span class="spacer">&nbsp;</span>
    <button
      (click)="requestAction('stop')"
      data-dd-action-name="click on Add Stop"
      mat-raised-button
      data-cy="route-edit-add-stop-button">
      Add stop
    </button>
    <button
      (click)="requestAction('uturn')"
      data-dd-action-name="click on Add Uturn"
      mat-raised-button
      data-cy="route-edit-add-uturn-button">
      Add u-turn
    </button>
    <button
      (click)="requestAction('waypoint')"
      data-dd-action-name="click on Add Waypoint"
      mat-raised-button
      data-cy="route-edit-add-waypoint-button">
      Add waypoint
    </button>
  </div>
  <div *ngIf="editMode || routeCreationMode">
    <button
      [disabled]="!isRouteValid"
      (click)="requestAction('save')"
      mat-raised-button
      id="save-button"
      data-cy="route-edit-save-button"
      class="blue"
      data-dd-action-name="click on Save">
      {{ newRouteSource === 'clone' ? 'Clone' : 'Save' }}
    </button>
    <button
      (click)="requestAction('exit')"
      class="red"
      data-cy="route-edit-exit-button"
      data-dd-action-name="click on Exit"
      id="exit-button"
      mat-raised-button>
      Exit
    </button>
    <div *ngIf="editMode && !routeCreationMode" class="last-edited" id="last-edited-description">
      <p id="last-edited-label"><b>Last edited:</b><br />{{ route?.lastupdatedTs | date : 'short' }}</p>
    </div>
  </div>

  <button
    *ngIf="!editMode && !routeCreationMode"
    (click)="requestAction('edit')"
    mat-raised-button
    id="edit-button"
    data-cy="route-edit-edit-button"
    data-dd-action-name="click on Edit"
    [disabled]="bulkEditMode">
    Edit
  </button>

  <div *ngIf="!editMode && !routeCreationMode">
    <button
      (click)="requestAction('reject')"
      [disabled]="route?.status === 'rejected' || editMode || routeCreationMode || bulkEditMode"
      class="red"
      data-cy="route-edit-reject-button"
      data-dd-action-name="click on Reject"
      mat-raised-button
      id="reject-button">
      Reject
    </button>
    <button
      (click)="requestAction('approve')"
      [disabled]="route?.status === 'approved' || editMode || routeCreationMode || bulkEditMode"
      class="blue"
      data-cy="route-edit-approve-button"
      data-dd-action-name="click on Approve"
      id="approve-button"
      mat-raised-button>
      Approve
    </button>
  </div>
</div>
