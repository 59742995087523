<div *ngIf="editManagementService.features.isGuidanceMenuVisible">
  <app-route-feature-toolbar></app-route-feature-toolbar>
</div>

<app-loading *ngIf="routeLoading"></app-loading>

<div
  leaflet
  [leafletOptions]="mapOptions"
  [leafletLayersControl]="layersControl"
  [leafletFitBoundsOptions]="fitBoundsOptions"
  [leafletFitBounds]="this.mapService.mapFitBounds$ | async"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="onMapClick($event)"
  [ngClass]="{
    'add-stop-feature':
      editManagementService.features.featureType === 'stop' && editManagementService.features.isSegmentSelected,
    'add-waypoint-feature':
      editManagementService.features.featureType === 'waypoint' && editManagementService.features.isSegmentSelected,
    'add-uturn-feature':
      editManagementService.features.featureType === 'uturn' && editManagementService.features.isSegmentSelected,
    'grabbing-cursor': grabbingCursor
  }"
  style="height: 100%">
  <div *ngFor="let sm of stopMarkers$ | async" [leafletLayer]="sm" (leafletLayerAdd)="onMarkerAdd($event)"></div>
  <div *ngFor="let rp of routePath$ | async" [leafletLayer]="rp" (leafletLayerAdd)="onSegmentPathAdd($event)"></div>
  <div [leafletLayer]="waypointMarkers$ | async"></div>
  <div [leafletLayer]="selectionManagementService.navigationMarker$ | async"></div>

  <ng-container *ngIf="debugService.debugEnabled">
    <div [leafletLayer]="debugService.debugPath$ | async"></div>
    <div [leafletLayer]="debugService.debugPathMarkers$ | async"></div>
    <div *ngFor="let rr of debugService.debugReroutedMarkers$ | async" [leafletLayer]="rr"></div>
    <div *ngFor="let ss of debugService.debugSkippedStopMarkers$ | async" [leafletLayer]="ss"></div>
    <div *ngFor="let aa of debugService.debugArrivedStopMarkers$ | async" [leafletLayer]="aa"></div>
  </ng-container>
</div>
