import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import _ from 'lodash-es';

@Component({
  selector: 'app-multi-drag-drop',
  templateUrl: './multi-drag-drop.component.html',
  styleUrls: ['./multi-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiDragDropComponent implements OnChanges, OnDestroy {
  @Input() items: any[];
  @Input() allSelected: boolean;
  @Input() selectStopEvent: any;
  @Input() sortingDisabled: any;
  @Input() identifier: any;
  @Output() itemsRemoved = new EventEmitter<any[]>();
  @Output() itemsAdded = new EventEmitter<any[]>();
  @Output() itemsUpdated = new EventEmitter<any[]>();
  @Output() selectionChanged = new EventEmitter<any[]>();
  @ContentChild(TemplateRef, { static: false }) templateRef;

  public dragging: DragRef = null;
  public selections: number[] = [];
  private currentSelectionSpan: number[] = [];
  private lastSingleSelection: number;
  private lastSingleDeselection: number;
  firstSelectedIndex: number | null = null;

  // inspired heavily by https://github.com/angular/components/issues/13807
  constructor(private eRef: ElementRef, private cdRef: ChangeDetectorRef) {
    this.selections = [];
    this.items = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes - ', this.identifier);

    // Receive all actionable inputs from our host components

    // To prevent instances where actions upon an items array are being taken while a request is inflight
    // (which, upon completion, sets items array),
    // we will clear any selections or cdk activity state whenever a change to the items array is detected.
    // **note** this has become less of an issue since were are no longer setting the Stop items
    // in RouteSplittingNewRouteComponent

    if (_.get(changes, 'items') !== undefined) {
      if (this.dragging) {
        this.dragEnded();
        this.clearSelection();
      }
    }

    if (_.get(changes, 'selectStopEvent.currentValue') !== undefined) {
      const { eventState, stopIndex, routeId, checked } = changes.selectStopEvent.currentValue;
      if (routeId !== this.identifier['id']) {
        // this ensures that we only handle selection inputs from our desired component instance
        return;
      }
      checked ? this.select(eventState, stopIndex) : this.deselect(eventState, stopIndex);
      // if we have a single check box event, we want to return, so we don't deal with the "(de)select all" branch
      return;
    }
    if (_.get(changes, 'allSelected.currentValue') !== undefined) {
      changes.allSelected.currentValue ? this.selectAll() : this.clearSelection();
    }
  }

  ngOnDestroy(): void {
    // console.log(this.identifier, '- destroyed ');

    this.clearSelection();
  }

  dragStarted(ev: CdkDragStart, index: number): void {
    // console.log('dragStarted - ', this.identifier);

    this.dragging = ev.source._dragRef;
    this.firstSelectedIndex = this.selections.length ? this.selections[0] : null;

    const indices = this.selections.length ? this.selections : [index];

    ev.source.data = {
      indices,
      values: indices.map(i => this.items[i]),
      source: this,
    };

    this.cdRef.detectChanges();
  }

  dragEnded(): void {
    // console.log(this.identifier, '- dragEnded');

    this.dragging = null;
    this.cdRef.detectChanges();
  }

  dropped(ev: CdkDragDrop<any>): void {
    // console.log(this.identifier, '- dropped');

    // happens whenever an item itself is dropped
    // removes item(s) from component instance

    if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
      return;
    }
    const data = ev.item.data;

    if (data.source === this) {
      const removed = _.pullAt(this.items, data.indices);

      this.itemsRemoved.emit([removed, data.indices]);

      if (ev.container !== ev.previousContainer) {
        this.itemsUpdated.emit(this.items);
      }

      this.clearSelection();
    }
    this.dragging = null;
    this.cdRef.detectChanges();
  }

  droppedIntoList(ev: CdkDragDrop<any>): void {
    // console.log(this.identifier, '- droppedIntoList');

    // ignore any erroneous drop actions (probably wont happen)
    if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
      return;
    }

    const data = ev.item.data;
    let spliceIntoIndex = ev.currentIndex;

    // this.selections is cleared in the list within which the item is dropped

    this.items.splice(spliceIntoIndex, 0, ...data.values);
    this.items.forEach(i => (i.selected = false));
    this.clearSelection();

    if (ev.previousContainer !== ev.container) {
      this.itemsAdded.emit(data.values);
    }
    this.itemsUpdated.emit(this.items);
    this.cdRef.detectChanges();
  }

  deselect(event, index) {
    // console.log(this.identifier, '- deselect');

    // Deselect items for multi-drag-n-drop
    // Supports shift select

    const shiftSelect = event.shiftKey && this.lastSingleDeselection !== index;

    if (!shiftSelect) {
      if (_.includes(this.selections, index)) {
        _.remove(this.selections, s => s === index);
        this.lastSingleDeselection = index;
      }
    } else {
      // if holding shift, add group to selection and currentSelectionSpan
      const newSelectionBefore = index < this.lastSingleDeselection;
      const count = newSelectionBefore ? this.lastSingleDeselection - (index - 1) : index + 1 - this.lastSingleDeselection;

      // clear previous shift selection
      if (this.currentSelectionSpan && this.currentSelectionSpan.length > 0) {
        _.each(this.currentSelectionSpan, i => {
          _.remove(this.selections, s => s === i);
        });
        this.currentSelectionSpan = [];
      }
      // build new currentSelectionSpan
      _.times(count, c => {
        if (newSelectionBefore) {
          this.currentSelectionSpan.push(this.lastSingleDeselection - c);
        } else {
          this.currentSelectionSpan.push(this.lastSingleDeselection + c);
        }
      });
      // select currentSelectionSpan
      _.each(this.currentSelectionSpan, i => {
        if (_.includes(this.selections, i)) {
          _.remove(this.selections, s => s === i);
          this.items[i].selected = false;
        }
      });
    }
    this.cdRef.detectChanges();
  }

  select(event, index) {
    // console.log(this.identifier, '- select');

    // determine if the shiftKey was held down during our click
    const shiftSelect = event.shiftKey && this.lastSingleSelection !== index;

    if (!this.selections || this.selections.length < 1) {
      this.selections = [index];
      this.lastSingleSelection = index;
    } else if (shiftSelect) {
      // if holding shift, add group to selection and currentSelectionSpan
      const newSelectionBefore = index < this.lastSingleSelection;
      const count = newSelectionBefore ? this.lastSingleSelection - (index - 1) : index + 1 - this.lastSingleSelection;

      // clear previous shift selection
      if (this.currentSelectionSpan && this.currentSelectionSpan.length > 0) {
        _.each(this.currentSelectionSpan, i => {
          _.remove(this.selections, s => s === i);
        });
        this.currentSelectionSpan = [];
      }
      // build new currentSelectionSpan
      _.times(count, c => {
        if (newSelectionBefore) {
          this.currentSelectionSpan.push(this.lastSingleSelection - c);
        } else {
          this.currentSelectionSpan.push(this.lastSingleSelection + c);
        }
      });
      // select currentSelectionSpan
      _.each(this.currentSelectionSpan, i => {
        if (!_.includes(this.selections, i)) {
          this.selections.push(i);
          this.items[i].selected = true;
        }
      });
    } else {
      // handle any additional single check clicks
      this.selections.push(index);
      this.lastSingleSelection = index;
    }

    if (!event.shiftKey) {
      this.currentSelectionSpan = [];
    }

    // to deal with rare edge cases where duplicate selection indices can cause issues
    this.selections = _.uniq(this.selections);

    this.selectionChanged.emit(this.selections.map(i => this.items[i]));
    this.cdRef.detectChanges();
  }

  clearSelection() {
    // console.log(this.identifier, '- clearSelection');

    if (this.selections.length) {
      this.selections = [];
      this.currentSelectionSpan = [];
      this.lastSingleSelection = null;
      this.selectionChanged.emit(this.selections.map(i => this.items[i]));
      this.cdRef.detectChanges();
    }
  }

  selectAll() {
    // console.log(this.identifier, '- selectAll');

    this.selections = _.map(this.items, (item, i) => i);
    this.currentSelectionSpan = [];
    this.lastSingleSelection = null;
    this.selectionChanged.emit(this.items);
    this.cdRef.detectChanges();
  }

  // handles "ctrl/command + a" to select all
  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'a' &&
      (event.ctrlKey || event.metaKey) &&
      this.selections.length &&
      (document.activeElement.nodeName !== 'INPUT' || document.activeElement['type'] === 'checkbox')
    ) {
      event.preventDefault();
      this.selectAll();
      _.forEach(this.items, item => (item.selected = true));
      this.cdRef.detectChanges();
    }
  }
}
