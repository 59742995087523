import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navigationIcon',
})
export class NavigationIconPipe implements PipeTransform {
  // This pipe accepts a string, typically representing a multi-sentence text of driving directions.
  // Based on known directions received from HERE route API, certain substrings are searched for,
  // and the correspondant icon name (to display).

  // navIcons object property values are the names of the svg icons registered in the route-navigation component.
  navIcons = {
    turnLeft: 'turn_left',
    turnRight: 'turn_right',
    slightLeft: 'curve_left',
    slightRight: 'curve_right',
    arrive: 'stop',
    marker: 'marker',
    exit: 'exit',
    uturn: 'uturn',
    continue: 'continue',
    south: 'south',
    north: 'north',
    east: 'east',
    west: 'west',
    r1: 'roundabout_1',
    r2: 'roundabout_2',
    r3: 'roundabout_3',
    r4: 'roundabout_4',
    r5: 'roundabout_5',
    r6: 'roundabout_6',
    r7: 'roundabout_7',
  };

  transform(navInstruction: string): string {
    if (navInstruction.toLowerCase().indexOf('head north') > -1) {
      return this.navIcons.north;
    }
    if (navInstruction.toLowerCase().indexOf('head south') > -1) {
      return this.navIcons.south;
    }
    if (navInstruction.toLowerCase().indexOf('head east') > -1) {
      return this.navIcons.east;
    }
    if (navInstruction.toLowerCase().indexOf('head west') > -1) {
      return this.navIcons.west;
    }
    if (navInstruction.indexOf('Continue ') > -1) {
      return this.navIcons.continue;
    }
    if (navInstruction.indexOf('Head toward ') > -1) {
      return this.navIcons.continue;
    }
    if (navInstruction.indexOf('Turn left') > -1) {
      return this.navIcons.turnLeft;
    }
    if (navInstruction.indexOf('Turn right') > -1) {
      return this.navIcons.turnRight;
    }
    if (navInstruction.indexOf('Arrive at') > -1) {
      return this.navIcons.arrive;
    }
    if (navInstruction.indexOf('Turn slightly left') > -1) {
      return this.navIcons.slightLeft;
    }
    if (navInstruction.indexOf('Turn sharp left') > -1) {
      return this.navIcons.slightLeft;
    }
    if (navInstruction.indexOf('Keep left') > -1) {
      return this.navIcons.slightLeft;
    }
    if (navInstruction.indexOf('Turn slightly right') > -1) {
      return this.navIcons.slightRight;
    }
    if (navInstruction.indexOf('Turn sharp right') > -1) {
      return this.navIcons.slightRight;
    }
    if (navInstruction.indexOf('Keep right') > -1) {
      return this.navIcons.slightRight;
    }
    if (navInstruction.indexOf('Take ramp') > -1) {
      return this.navIcons.slightRight;
    }
    if (navInstruction.indexOf('Take exit') > -1) {
      return this.navIcons.exit;
    }
    if (navInstruction.indexOf('Take the exit') > -1) {
      return this.navIcons.exit;
    }
    if (navInstruction.toLowerCase().indexOf('make a u-turn') > -1) {
      return this.navIcons.uturn;
    }
    if (navInstruction.indexOf('1. exit from roundabout') > -1) {
      return this.navIcons.r1;
    }
    if (navInstruction.indexOf('1st exit from roundabout') > -1) {
      return this.navIcons.r1;
    }
    if (navInstruction.indexOf('Pass 1 roundabout') > -1) {
      return this.navIcons.r1;
    }
    if (navInstruction.indexOf('2. exit from roundabout') > -1) {
      return this.navIcons.r2;
    }
    if (navInstruction.indexOf('2nd exit from roundabout') > -1) {
      return this.navIcons.r2;
    }
    if (navInstruction.indexOf('Pass 2 roundabouts') > -1) {
      return this.navIcons.r2;
    }
    if (navInstruction.indexOf('3. exit from roundabout') > -1) {
      return this.navIcons.r3;
    }
    if (navInstruction.indexOf('3rd exit from roundabout') > -1) {
      return this.navIcons.r3;
    }
    if (navInstruction.indexOf('Pass 3 roundabouts') > -1) {
      return this.navIcons.r3;
    }
    if (navInstruction.indexOf('4. exit from roundabout') > -1) {
      return this.navIcons.r4;
    }
    if (navInstruction.indexOf('4th exit from roundabout') > -1) {
      return this.navIcons.r4;
    }
    if (navInstruction.indexOf('Pass 4 roundabouts') > -1) {
      return this.navIcons.r4;
    }
    if (navInstruction.indexOf('5. exit from roundabout') > -1) {
      return this.navIcons.r5;
    }
    if (navInstruction.indexOf('5th exit from roundabout') > -1) {
      return this.navIcons.r5;
    }
    if (navInstruction.indexOf('Pass 5 roundabouts') > -1) {
      return this.navIcons.r5;
    }
    if (navInstruction.indexOf('6. exit from roundabout') > -1) {
      return this.navIcons.r6;
    }
    if (navInstruction.indexOf('6th exit from roundabout') > -1) {
      return this.navIcons.r6;
    }
    if (navInstruction.indexOf('Pass 6 roundabouts') > -1) {
      return this.navIcons.r6;
    }
    if (navInstruction.indexOf('7. exit from roundabout') > -1) {
      return this.navIcons.r7;
    }
    if (navInstruction.indexOf('7th exit from roundabout') > -1) {
      return this.navIcons.r7;
    }
    if (navInstruction.indexOf('Pass 7 roundabouts') > -1) {
      return this.navIcons.r7;
    }
  }
}
