<div *ngIf="!routeLoading; else loading" id="route-preview-container" [ngClass]="rootClass">
  <h4 *ngIf="!editMode && !routeCreationMode">Route Preview</h4>
  <mat-drawer-container>
    <mat-drawer class="mat-elevation-z6" id="route-navigation-drawer" #navInstructionDrawer mode="side"
      disableClose="true" opened>
      <app-route-navigation (toggleDrawerEvent)="toggleDrawer($event)"></app-route-navigation>
    </mat-drawer>
    <mat-drawer-content [ngClass]="newRouteSource">
      <app-route-edit-menu>
      </app-route-edit-menu>
      <app-route-map></app-route-map>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<ng-template #loading>
  <div id="route-preview-container">
    <app-loading></app-loading>
  </div>
</ng-template>