import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimStartingPositionName',
})
export class TrimStartingPositionNamePipe implements PipeTransform {
  /*
  This is a super bespoke pipe that exists solely to clean up the name of any Starting Position stop (stopData[0])
  that has been combined with its first stop via backend pipeline and ended up with a super long name
  that's currently only even visible in the Starting Position's map marker popup.
  It could very quickly be made obsolete with some backend work, but I wanted to make it anyway
  */
  transform(stopName: string): unknown {
    return stopName.indexOf('Starting Point - ') > -1 ? stopName.replace('Starting Point - ', '') : stopName;
  }
}
