<mat-toolbar class="subheader-container">
  <h1>Route Recording Inbox</h1>
  <div>
    <small *ngIf="debugMode == 'true'" class="account_code"
      >Account: <strong>{{ accountCode }}</strong></small
    >
    <a href="https://support.zonarsystems.net" target="_blank" id="help-icon" data-dd-action-name="click on Help">
      <mat-icon>help</mat-icon>
    </a>
  </div>
</mat-toolbar>
