<div *ngIf="instructions" cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let stop of stops; index as stopIndex; first as isFirst">
    <div *ngIf="!isFirst">
      <mat-expansion-panel
        cdkDrag
        [cdkDragDisabled]="
          (editManagementService.editMode$ | async) === false || (editManagementService.addFeatureMode$ | async) === true
        "
        disabled
        hideToggle="true"
        data-cy="route-nav-stop"
        class="route-nav-stop"
        [expanded]="false"
        #singleStopPanel
        [appHighlightSegment]="stopIndex">
        <div class="placeholder" *cdkDragPlaceholder></div>
        <mat-expansion-panel-header
          [collapsedHeight]="'5rem'"
          [expandedHeight]="'6rem'"
          [ngClass]="{ 'highlighted-route-segment': (selectionManagementService.selectedStopIndex$ | async) === stopIndex }">
          <div
            class="stop-name-container"
            data-dd-action-name="click on Route Navigation Stop Name"
            (click)="handleNavItemClick(stopIndex)">
            <h4
              *ngIf="
                (editManagementService.editMode$ | async) === false || (editManagementService.addFeatureMode$ | async) === true
              "
              class="nav-stop-name">
              <div
                class="nav-stop-name-icon-container"
                *ngIf="
                  (editManagementService.editMode$ | async) === false &&
                  (editManagementService.addFeatureMode$ | async) === false
                ">
                <mat-icon svgIcon="stop"></mat-icon>
              </div>
              <mat-icon
                class="icon-color"
                *ngIf="
                  (editManagementService.editMode$ | async) === true && (editManagementService.addFeatureMode$ | async) === true
                ">
                menu
              </mat-icon>
              <div class="nav-stop-directions-container">
                {{ stop['name'] }}
              </div>
            </h4>
            <h4
              *ngIf="
                (editManagementService.editMode$ | async) === true && (editManagementService.addFeatureMode$ | async) === false
              "
              class="editable-stop-name">
              <mat-icon cdkDragHandle> menu </mat-icon>
              <div class="nav-stop-directions-container" *ngIf="editManagementService.editableRoute['stopData'][stopIndex]">
                <mat-form-field>
                  <input
                    title="Stop Name"
                    placeholder="Stop Name"
                    matInput
                    type="text"
                    (keydown)="editManagementService.storeState()"
                    [(ngModel)]="editManagementService.editableRoute['stopData'][stopIndex].name"
                    (keydown.Space)="$event.stopPropagation()" />
                  <button
                    mat-button
                    *ngIf="editManagementService.editableRoute['stopData'][stopIndex].name"
                    matSuffix
                    mat-icon-button
                    (click)="editManagementService.editableRoute['stopData'][stopIndex].name = ''">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </h4>
          </div>
          <div class="toggle_icon" data-dd-action-name="click on Toggle Route Navigation Directions Icon">
            <mat-icon *ngIf="!panelOpenState[stopIndex]" (click)="togglePanel(singleStopPanel, stopIndex)"
              >expand_more</mat-icon
            >
            <mat-icon *ngIf="panelOpenState[stopIndex]" (click)="togglePanel(singleStopPanel, stopIndex)">expand_less</mat-icon>
          </div>
        </mat-expansion-panel-header>
        <div
          class="nav-instruction-row"
          data-cy="route-nav-instruction-row"
          *ngFor="let instruction of instructions[stopIndex - 1]; index as instructionIndex; last as isLast"
          (click)="handleNavItemClick(stopIndex, instructionIndex)"
          [ngClass]="{
            'highlighted-route-segment': (selectionManagementService.selectedStopIndex$ | async) === stopIndex,
            'highlighted-individual-nav-instruction':
              (selectionManagementService.selectedStopIndex$ | async) === stopIndex &&
              selectionManagementService.selectedNavInstructionIndex === instructionIndex
          }">
          <mat-icon
            [ngClass]="{ 'last-nav-instruction-icon': isLast, 'regular-nav-instruction-icon': !isLast }"
            svgIcon="{{ instruction | navigationIcon }}"></mat-icon>
          <p class="nav-instruction-text">{{ instruction | trimNavInstructions }}</p>
          <p class="nav-instruction-distance">{{ instruction | extractNavInstructionDistance }}</p>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
