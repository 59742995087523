import { Injectable } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { StopMarkerPopupComponent } from '@app/features/route-map/components/stop-marker-popup/stop-marker-popup.component';
import { WaypointMarkerPopupComponent } from '@app/features/route-map/components/waypoint-marker-popup/waypoint-marker-popup.component';

@Injectable({
  providedIn: 'root',
})
export class MarkerPopupService {
  public getStopMarkerPopupElement() {
    const popupEl: NgElement & WithProperties<StopMarkerPopupComponent> = document.createElement(
      'stop-marker-popup-element'
    ) as any;
    return popupEl;
  }
  public getWaypointMarkerPopupElement() {
    const popupEl: NgElement & WithProperties<WaypointMarkerPopupComponent> = document.createElement(
      'waypoint-marker-popup-element'
    ) as any;
    return popupEl;
  }
}
